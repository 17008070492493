import styled from 'styled-components';

export const SobreStyle = styled.div`

    .porque-provas {

        padding: 50px 20%;

        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;

        img {
            width: 35%;
            height: 70%;
        }

        .txt {
            width: 60%;
        }

        p {
            text-align: justify;
            font-style: italic;
            font-size: 17px;
            line-height: 26px;
            color: #6F8190;
            margin-bottom: 30px;
        }

        .titulo {
            font-size: 30px;
            color: #49575F;
        }

        .btn-1 {
            padding: 15px 30px;
            background-color: #FFF;
            color: #000;
            border: 1px solid #242C42;
            margin-right: 15px;

            &:after {
                background-color: #242C42;
            }

            &:hover {
                color: #FFF;
            }
        }

        .btn-2 {
            padding: 15px 30px;
            background-color: #27921A;
            color: #FFF;

            &:after {
                background-color: #242C42;
            }

        }

        @media (max-width: 1200px) {
            img {
                width: 70%;
                height: 140%;
                margin: auto;
            }

            .txt {
                width: 100%;
            }
        }

        @media (max-width: 900px) {
            padding: 50px 5%;

            .btn-1, .btn-2 {
                padding: 7px 15px;
                font-size: 12px;
            }
        }
    }

    .dados-provas {
        background-color: #EAEDF5;
        padding: 50px 20%;
        color: #49575F;
        text-align: center;

        .titulo {
            font-size: 30px;
            margin: 0%;
        }

        .circles-wrapper {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
        }

        .circle-info {
            width: 270px;
            margin-top: 30px;

            p {
                width: 90%;
                margin: auto;
                padding-top: 7px;
            }
        }

        .circle {
            width: 120px;
            height: 120px;
            background-color: #FFF;
            border-radius: 50%;
            margin: auto;
            color: #27921A;
            font-size: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all 0.5s;

            &:hover {
                background-color: #27921A;
                color: #FFF;
            }
        }

        @media (max-width: 900px) {
            padding: 50px 5%;
        }
    }

    .novidades {
        padding: 50px 20%;
        display: flex;
        flex-flow: row wrap;
        color: #49575F;
        justify-content: space-between;
        align-items: center;

        .info {
            width: 50%;
        }

        h3 {
            font-size: 30px;
            font-weight: 900;
        }

        p {
            text-align: justify;
        }

        input {
            position: absolute;
            opacity: 0;
        }

        .dropdown {
            width: 280px;
        }

        label {
            display: flex;
            align-items: center;

            p {
                padding-left: 10px;

                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }

        .txt {
            color: #6f8190;
            font-size: 14px;
            max-height: 0;
            overflow: hidden;
            margin: 0;
            transition: all 0.5s;
        }

        input:checked {
            +label+.txt {
                max-height: 100vh;
                padding: 5px 0 20px 20px;
            }
        }

        .rule {
            width: 100%;
            height: 0.1px;
            background-color: #DDD;
        }

        img {
            width: 50%;
        }

        @media (max-width: 1200px) {
            img {
                width: 100%;
            }

            .info {
                width: 100%;
            }
        }

        @media (max-width: 900px) {
            padding: 50px 5%;
        }
        
    }

`;