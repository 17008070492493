import { CardProfessorPerfilStyle } from './styles/cardProfessorPerfil.style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListAlt } from '@fortawesome/free-regular-svg-icons';

interface ProfessorProps {
    image: string,
    link: string
}

const CardProfessorPerfil = (props: ProfessorProps) => {

    return (
        <CardProfessorPerfilStyle>
            <a href={`/professores/${props.link}`}><div className='background'>
                <img src={`/assets/images/teachers/${props.image}`} alt='foto de perfil do professor, autor'></img>
                <button><FontAwesomeIcon icon={faListAlt} color="#FFF" className='icon' /></button>
            </div></a>
        </CardProfessorPerfilStyle>
    )
}

export default CardProfessorPerfil;