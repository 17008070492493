import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import ConcursosDeTi from './pages/ConcursosDeTi';
import ConcursosDeTiAbertos from './pages/ConcursosDeTiAbertos';
import ConcursosDeTiEmAndamento from './pages/ConcursosDeTiEmAndamento';
import ConcursosDeTiEncerrados from './pages/ConcursosDeTiEncerrados';
import ConcursosDeTiPrevistos from './pages/ConcursosDeTiPrevistos';
import EstudeSemEdital from './pages/EstudeSemEdital';
import PlanoDeEstudo from './pages/PlanoDeEstudo';
import Cursos from './pages/Cursos';
import CursosPorCategoria from './pages/CursosPorCategoria';
import CursosPorDisciplina from './pages/CursosPorDisciplina';
import CursosTodos from './pages/CursosTodos';
import CursoDetalhes from './pages/CursoDetalhes';
import Professores from './pages/Professores';
import ProfessorPerfil from './pages/ProfessorPerfil';
import Atualizacoes from './pages/Atualizacoes';
import PlanosDeAssinatura from './pages/PlanosDeAssinatura';
import Faq from './pages/Faq';
import Termos from './pages/Termos';
import Sobre from './pages/Sobre';
import Lwm from './pages/Lwm';
import Concursos2024 from './pages/Concursos2024';
import Busca from './pages/Busca';
import Mentorias from './pages/Mentorias';
import LandingMentoria from './pages/LandingMentorias';

function App() {

  useEffect(() => {
    window.scrollTo({top: 0, behavior: "auto"});
  });

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='concursosdeti' element={<ConcursosDeTi />}>
            <Route path='abertos' element={<ConcursosDeTiAbertos />} />
            <Route path='em-andamento' element={<ConcursosDeTiEmAndamento />} />
            <Route path='encerrados' element={<ConcursosDeTiEncerrados />} />
            <Route path='previstos' element={<ConcursosDeTiPrevistos/>} />
            <Route path='estude-sem-edital' element={<EstudeSemEdital/>} />
            <Route path=':id' element={<PlanoDeEstudo />} />
          </Route>
          <Route path='cursos' element={<Cursos />}>
            <Route path='categoria/:categoriaId' element={<CursosPorCategoria />} />
            <Route path='disciplina/:disciplinaId' element={<CursosPorDisciplina />} />
            <Route path='todos' element={<CursosTodos />} />
            <Route path=':cursoId' element={<CursoDetalhes />} />
          </Route>
          <Route path='professores' element={<Professores />}>
            <Route path=':professorId' element={<ProfessorPerfil />} />
          </Route>
          <Route path='atualizacoes' element={<Atualizacoes />} />
          <Route path='planosdeassinatura' element={<PlanosDeAssinatura />} />
          <Route path='faq' element={<Faq />} />
          <Route path='termos' element={<Termos />} />
          <Route path='sobre' element={<Sobre />} />
          <Route path='lwm' element={<Lwm />} />
          <Route path='concursosdeti-2024' element={<Concursos2024 />} />
          <Route path='busca' element={<Busca />} />
          <Route path='mentorias' element={<Mentorias />} />
          <Route path='mentorias/:mentoriaId' element={<LandingMentoria />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;