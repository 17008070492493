import CabecalhoInfo from "../componentes/cabecalhoInfo";
import CabecalhoMenu from "../componentes/cabecalhoMenu";
import CardMentoria from "../componentes/cardMentoria";
import { API_URL } from '../config';
import IrAoTopoWidget from "../componentes/irAoTopoWidget";
import Rodape from "../componentes/rodape";
import SeoHelmet from "../componentes/seoHelmet";
import TituloPagina from "../componentes/tituloPagina";
import { ConcursosDeTiStyle } from "./styles/ConcursosDeTi.style";
import { useState, useEffect } from 'react';
import axios from 'axios';
import CarregamentoWidget from '../componentes/carregamentoWidget';

const Mentorias = () => {

    interface Mentorias {
        id: number;
        imagem: string;
        alt: string;
        titulo: string;
        resumo: string;
    }

    const [mentoriasTodas, setMentoriasTodas] = useState<Mentorias[]>();

    const getMentoriasTodas = async () => {
        axios.get(`${API_URL}/api/mentorias/get/mentoriasTodas`)
            .then(response => setMentoriasTodas(response.data.results));
    }

    useEffect(() => {
        getMentoriasTodas();
    }, []);

    return (
        <>

            <SeoHelmet
                conector=' - '
                subtitulo='Mentorias'
                linkcanonical='concursosdeti'
            />

            <CabecalhoInfo />
            <CabecalhoMenu />

            {!mentoriasTodas ? <CarregamentoWidget /> :
                <ConcursosDeTiStyle>

                    <TituloPagina titulo={'MENTORIAS PARA CONCURSOS DE TI'}
                        info={'TI para Concursos e Tudo para você Passar!'}
                        link={'Página Geral de Mentorias'}
                        h1ClassName={'center'}
                        hiddenClassName={'hidden'}
                        getStyle={() => { }}
                    />

                    <div className="cards">

                        {mentoriasTodas.map((mentoria) => {
                            return (
                                <div key={mentoria.id}>
                                    <CardMentoria
                                        id={mentoria.id}
                                        imagem={mentoria.imagem}
                                        alt={mentoria.alt}
                                        titulo={mentoria.titulo}
                                        resumo={mentoria.resumo}
                                    />
                                </div>
                            )
                        })}

                    </div>

                </ConcursosDeTiStyle>}

            <Rodape />
            <IrAoTopoWidget />

        </>
    )
}

export default Mentorias;