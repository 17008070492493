export function startCountdown({
  diaId = 'dia',
  hrsId = 'hrs',
  minId = 'min',
  segId = 'seg',
  countDownDate // Não especifique o tipo aqui
}: {
  diaId?: string,
  hrsId?: string,
  minId?: string,
  segId?: string,
  countDownDate: number // Especifique o tipo aqui
}) {
  
  // Helper function to safely set innerHTML
  function setInnerHTML(id: string, value: string): void {
    const element = document.getElementById(id);
    if (element) {
      element.innerHTML = value;
    }
  }

  // Set the date we're counting down to as midnight of the next day
  //let currentDate = new Date();
  //currentDate.setHours(24, 0, 0, 0);
  //let countDownDate = currentDate.getTime();

  // Update the count down every 1 second
  let x = setInterval(function () {

    // Get today's date and time
    let now = new Date().getTime();

    // Find the distance between now and the count down date
    let distance = countDownDate - now;

 // Time calculations for days, hours, minutes and seconds
let days = Math.floor(distance / (1000 * 60 * 60 * 24)).toString();
let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString();
let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)).toString();
let seconds = Math.floor((distance % (1000 * 60)) / 1000).toString();

// Format zero left
days = days.length === 1 ? "0" + days : days; 
hours = hours.length === 1 ? "0" + hours : hours;
minutes = minutes.length === 1 ? "0" + minutes : minutes;
seconds = seconds.length === 1 ? "0" + seconds : seconds;


    // Output the result using the parameterized IDs
    setInnerHTML(diaId, days.toString());
    setInnerHTML(hrsId, hours.toString());
    setInnerHTML(minId, minutes.toString());
    setInnerHTML(segId, seconds.toString());
    
    // If the count down is over, reset the values to "00"
    if (distance < 0) {
      clearInterval(x);
      setInnerHTML(diaId, "00");
      setInnerHTML(hrsId, "00");
      setInnerHTML(minId, "00");
      setInnerHTML(segId, "00");
    }
  }, 1000);
}
