import { CardMentoriaStyle } from './styles/cardMentoria.style';

interface MentoriaInfo {
    id: number;
    imagem: string;
    alt: string;
    titulo: string;
    resumo: string;
}

const CardMentoria = (props: MentoriaInfo) => {

    return (
        <CardMentoriaStyle>
            <div className='background'>
                <img className='card-concurso-img' src={`/assets/images/mentorias/${props.imagem}`} alt={`${props.alt}`}></img>
                <div className='info'>
                    <a className='link' href={`/mentorias/${props.id}`}>{props.titulo}</a>
                    <p>{props.resumo}</p>
                </div>
                <a href={`/mentorias/${props.id}`}><button>MAIS DETALHES</button></a>
            </div>
        </CardMentoriaStyle>
    )
}

export default CardMentoria;