import { CardProfessorCarrosselStyle, Background, Image, Nome, Descricao, Button } from './styles/cardProfessorCarrossel.style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListAlt } from '@fortawesome/free-regular-svg-icons';

interface CardProfessorCarrosselProps {
    id: number,
    nome: string,
    imagem: string,
    descricao: string
}

const CardProfessorCarrossel = (props: CardProfessorCarrosselProps) => {

    return (
        <CardProfessorCarrosselStyle>
            <a href={`/professores/${props.id}`}><Background>
                <Image src={`assets/images/teachers/${props.imagem}`} alt='foto de perfil do professor, autor'></Image>
                <Nome>{props.nome}</Nome>
                <Descricao>{props.descricao}</Descricao>
                <Button><FontAwesomeIcon icon={faListAlt} color="#FFF" className='iconProfessor' /></Button>
            </Background></a>
        </CardProfessorCarrosselStyle>
    )
}

export default CardProfessorCarrossel;