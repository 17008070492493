import { SobreStyle } from './styles/Sobre.style';
import CabecalhoInfo from '../componentes/cabecalhoInfo';
import CabecalhoMenu from '../componentes/cabecalhoMenu';
import TituloPagina from '../componentes/tituloPagina';
import Rodape from '../componentes/rodape';
import IrAoTopoWidget from '../componentes/irAoTopoWidget';
import SeoHelmet from '../componentes/seoHelmet';

const ano = new Date().getFullYear().toString();

const Concursos2024 = () => {
    return (
        <>
            <SeoHelmet 
                conector=' em '
                subtitulo={ano}
                linkcanonical='concursosdeti-2024'
            />
            <CabecalhoInfo />
            <CabecalhoMenu />
            <TituloPagina titulo='CONCURSOS DE TI - 2024'
                          info='Vagas de TI em Concursos no Brasil'
                          link='Concursos De TI - 2024'
                          h1ClassName='center'
                          hiddenClassName='hidden'
                          getStyle={() => {}}
            />

            <SobreStyle>
                <div className='dados-provas'>
                    <p className='titulo'>PROCURANDO POR <b>VAGAS DE TI</b>?</p>
                    <p>Abaixo informações atualizadas sobre os principais concursos com vagas de TI abertas, em andamento, encerrados e previstos para 2024.</p>
                </div>

                <div className='novidades'>
                    <div className='info'>
                        <h3>CONCURSOS DE TI VS MERCADO</h3>

                        <p>
                            Segundo diversas consultorias (IDC, Gartner, IBM, Deloitte etc.), até o ano de 2025 as mudanças na área de TI continuarão acontecendo de forma acelerada e serão mais influenciadas por tecnologias como Inteligência Artificial (Ciência de Dados, Machine Learning etc.), 5G, BI (Business Inteligence), IoT (Internet of Things), Blockchain, entre outras.
                        </p>

                        <p>
                            Claro, isso sem falar da forte dependência que praticamente todos os negócios possui com relação à TI. Ora, você acha que isso não afetará os órgãos de Governo em todas as esferas? E óbvio e certo que os órgãos governamentais precisarão acompanhar tais tendências. Principalmente em tempos de necessidade de maior eficiência e eficácia no serviço público. Assim, se existe uma área em que os Concursos continuarão acontecendo, esta área é a de <b>Tecnologia da Informação</b>.
                        </p>
                    </div>

                    <img src='assets/images/concursosdeti-2020-01.jpg' alt='concursos de TI 2024'></img>

                    <img src='assets/images/concursosdeti-2020-02.jpg' alt='concursos de TI 2024'></img>

                    <div className='info'>
                        <h3>CONCURSOS DE TI VÃO ACABAR?</h3>

                        <p>
                            Não pare de estudar agora! Sempre haverá concurseiros novatos se perguntando se os "concursos de TI vão acabar". E a resposta é: não, não vão acabar! Não houve - até hoje - um ano sequer em que os <b>Concursos com Vagas de TI</b> deixaram de ser realizados nas diversas esferas (Federal, Estadual e Municipal). Quem nos acompanha sabe que sempre alertamos aos alunos para continuarem estudando. Concursos podem diminuir etc., mas sempre existirão.
                        </p>

                        <p>
                            Além disso, haverá uma forte tendência por substituir cargos "braçais" por cargos mais "tecnológicos" nos órgãos devido aos motivos/siglas listados acima (IA, BI, IoT etc.). Lembre-se de que o Estado brasileiro é IMENSO. Mesmo que se cortem 20% dos cargos públicos (o que já seria uma façanha que levaria anos para se concretizar...), a quantidade de órgãos nos poderes Judiciário, Executivo e Legislativo, nas três esferas, ainda seria enorme. Basta ver que, sai ano, entra ano, e os <b>Concursos de TI</b> continuam...
                        </p>
                    </div>
                </div>

                <div className='dados-provas'>
                    <p className='titulo'>CONCURSOS DE TI ABERTOS</p>
                    <p>Abaixo há informações específicas sobre o panorama que cerca a questão dos Concursos de TI abertos em 2024.</p>
                </div>

                <div className='novidades'>
                    <img src='assets/images/concursosdeti-2020-03.jpg' alt='concursos de TI 2024'></img>

                    <div className='info'>
                        <h3>CONCURSOS DE TI ABERTOS</h3>

                        <p>
                            Em 2024 estão em andamento muitos Concursos com vagas para TI. Além daqueles de maior repercussão, não perca de vista os tidos como "menores" (que, da nossa experiência, podem ser muito mais vantajosos dependendo do seu perfil e da qualidade de vida que deseja). Clique <a href='/concursosdeti/abertos'>AQUI</a> para ver a lista de Concursos de TI abertos em que você pode se inscrever já.
                        </p>
                    </div>
                </div>

                <div className='dados-provas'>
                    <p className='titulo'>CONCURSOS DE TI EM ANDAMENTO</p>
                    <p>Há em nosso site diversos planos de estudo para os principais <b>Concursos com Vagas de TI</b> que estão em andamento.</p>
                </div>

                <div className='novidades'>
                    <img src='assets/images/concursosdeti-2020-04.jpg' alt='concursos de TI 2024'></img>

                    <div className='info'>
                        <h3>CONCURSOS DE TI EM ANDAMENTO</h3>

                        <p>
                            Você pode acessar diretamente os concursos EM ANDAMENTO através deste <a href='/concursosdeti/em-andamento'>LINK</a>. Alguns destes podem servir como sua base de estudo para uma próxima oportunidade.
                        </p>

                        <p>
                            Há também materiais sobre concursos já encerrados, mas que aconteceram recentemente. São também uma boa base de estudos e informação adicional para você. Acesse estes concursos ENCERRADOS através deste <a href='/concursosdeti/encerrados'>LINK</a>.
                        </p>
                    </div>
                </div>
            </SobreStyle>

            <Rodape />

            <IrAoTopoWidget />
        </>
    )
}

export default Concursos2024;