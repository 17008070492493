import styled from 'styled-components';

export const FaqStyle = styled.div`

    padding: 50px 0 80px 0;
    text-align: center;
    width: 1144px;
    margin: auto;

    p {
        font-size: 17px;
        color: #49575F;
    }

    h2 {
        font-size: 30px;
        font-weight: 900;
        margin: 0 0 20px 0;
        color: #49575F;
    }

    span {
        &:before {
            content: '';
            display: inline-block;
            margin: 0 15px 8px 0;
            height: 2px;
            width: 20px;
            background-color: #27921A; 
        }

        &:after {
            content: '';
            display: inline-block;
            margin: 0 0 8px 15px;
            height: 2px;
            width: 20px;
            background-color: #27921A;
            
        }
    }

    @media (max-width: 1200px) {
        width: auto;
        padding: 50px 5% 80px 5%;
    }

    @media (max-width: 700px) {
        padding: 50px 3% 80px 3%;
    }

`;

export const Questoes = styled.div`

    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    .questao {
        width: 550px;
        text-align: left;
        margin: 11px;

        h3 {
            font-size: 30px;
            color: #49575F;
            margin: 50px 0 5px 0;
        }

        .color-rule {
            height: 5px;
            width: 50px;
            background-color: #27921A;
            margin-bottom: 30px;
        }

        input {
            position: absolute;
            opacity: 0;
        }

        label {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 2% 5%;
            margin: 4px 0;
            background-color: #FAFAFA;
            transition: all 0.5s;

            &:hover {
                cursor: pointer;

                h4 {
                    text-decoration: underline #6F8190;
                }
            }
        }

        h4 {
            margin: 1% 3% 1% 0;
            color: #6F8190;
            font-size: 16px;
            font-weight: 400;
            text-transform: uppercase;
        }

        p {
            margin: 0;
            font-size: 14px;
            color: #333;
        }

        iframe {
            margin-top: 15px;
            width: 350px;
            height: 180px;
        }

        .resposta {
            max-height: 0;
            overflow: hidden;
            transition: all 0.5s;
        }

        input:checked {

            +label+.resposta {
                max-height: 100vh;
                padding: 3% 5% 8% 5%;
                box-shadow: 0 0 20px rgb(0 0 0 / 10%);
            }

            +label {
                background-color: #FFF;
                box-shadow: 0 0 20px rgb(0 0 0 / 10%);
            }

        }

    }

`;