import axios from 'axios';
import { useEffect, useState } from 'react';
import { DepoimentosCarrosselStyle, Slide } from './styles/depoimentosCarrossel.style';
import { API_URL } from '../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

const DepoimentosCarrossel = () => {

    interface Depoimentos {
        imagem: string,
        nome: string,
        texto: string
    }

    const [depoimentos, setDepoimentos] = useState<Depoimentos[]>();

    const [slide, setSlide] = useState<HTMLElement | null>(document.getElementById('slide-1'));
    const [inputValue, setInputValue] = useState<string>('slide-1');
    const [clicked, setClicked] = useState<boolean>(false);
    let timer: any;

    useEffect(() => {

        const getDepoimentos = async () => {
            const response = await axios.get(`${API_URL}/api/depoimentos/get/depoimentos`);
            setDepoimentos(response.data.results);
        }

        getDepoimentos();

    }, []);

    useEffect(() => {

        setSlide(document.getElementById('slide-1'));

    }, [depoimentos]);

    useEffect(() => {

        let inputValueNumber = parseInt(inputValue.replace(/\D/g,''));

        if (inputValue && inputValueNumber && depoimentos) {

            // eslint-disable-next-line react-hooks/exhaustive-deps
            timer = setTimeout(function() {
                if (inputValue && inputValueNumber && clicked === false && inputValueNumber !== depoimentos.length) {
                    setInputValue(`slide-${inputValueNumber + 1}`);
                    if (slide) {
                        slide.style.opacity = '0';
                        slide.style.pointerEvents = 'none';
                    }
                    setSlide(document.getElementById(`slide-${inputValueNumber + 1}`));
                } else if (inputValue && inputValueNumber && clicked === false && inputValueNumber === depoimentos.length) {
                    setInputValue(`slide-1`);
                    if (slide) {
                        slide.style.opacity = '0';
                        slide.style.pointerEvents = 'none';
                    }
                    setSlide(document.getElementById(`slide-1`));
                } else {
                    setClicked(false);
                }
            }, 4000);

        }

    }, [slide, inputValue, clicked]);

    useEffect(() => {

        if (slide) {
            slide.style.opacity = '1';
            slide.style.pointerEvents = 'all';
        }

    }, [slide]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
        setClicked(true);
        clearTimeout(timer);
        if (slide) {
            slide.style.opacity = '0';
            slide.style.pointerEvents = 'none';
        }
        setSlide(document.getElementById(event.target.value));
    }

    const handleNextBtn = () => {

        let inputValueNumber = parseInt(inputValue.replace(/\D/g,''));

        if (inputValueNumber !== depoimentos!.length) {
            setInputValue(`slide-${inputValueNumber + 1}`);
            setClicked(true);
            clearTimeout(timer);
            if (slide) {
                slide.style.opacity = '0';
                slide.style.pointerEvents = 'none';
            }
            setSlide(document.getElementById(`slide-${inputValueNumber + 1}`));
        } else if (inputValueNumber === depoimentos!.length) {
            setInputValue(`slide-1`);
            setClicked(true);
            clearTimeout(timer);
            if (slide) {
                slide.style.opacity = '0';
                slide.style.pointerEvents = 'none';
            }
            setSlide(document.getElementById(`slide-1`));
        }
    }

    const handlePrevBtn = () => {

        let inputValueNumber = parseInt(inputValue.replace(/\D/g,''));

        if (inputValueNumber !== 1) {
            setInputValue(`slide-${inputValueNumber - 1}`);
            setClicked(true);
            clearTimeout(timer);
            if (slide) {
                slide.style.opacity = '0';
                slide.style.pointerEvents = 'none';
            }
            setSlide(document.getElementById(`slide-${inputValueNumber - 1}`));
        } else if (inputValueNumber === 1) {
            setInputValue(`slide-${depoimentos!.length}`);
            setClicked(true);
            clearTimeout(timer);
            if (slide) {
                slide.style.opacity = '0';
                slide.style.pointerEvents = 'none';
            }
            setSlide(document.getElementById(`slide-${depoimentos!.length}`));
        }
    }

    return (
        <DepoimentosCarrosselStyle>
            {depoimentos && <div className='wrapper'>
                {depoimentos.map((depoimento, index) => {
                    return (
                        <div className='div' key={index}>
                            <Slide id={`slide-${index + 1}`}>
                                <img src={`/assets/images/pessoas/${depoimento.imagem}`} alt='foto do aluno do provas'></img>
                                <p className='aluno'>{depoimento.nome}</p>
                                <p className='info'>Aluno Provas de TI</p>
                                <p className='txt'>{depoimento.texto}</p>
                            </Slide>
                            <input type='radio' name='radio' value={`slide-${index + 1}`} checked={inputValue === `slide-${index + 1}`} onChange={handleChange}></input>
                        </div>
                    )
                })}
                <div className='btn prev' onClick={handlePrevBtn}><FontAwesomeIcon className='icon-btn' icon={faAngleLeft} fontSize='35px' color='#27921A' /></div>
                <div className='btn next' onClick={handleNextBtn}><FontAwesomeIcon className='icon-btn' icon={faAngleRight} fontSize='35px' color='#27921A' /></div>
            </div>}
        </DepoimentosCarrosselStyle>
    )
}

export default DepoimentosCarrossel;