import { TermosStyle } from './styles/Termos.style';
import CabecalhoInfo from '../componentes/cabecalhoInfo';
import CabecalhoMenu from '../componentes/cabecalhoMenu';
import Rodape from '../componentes/rodape';
import TituloPagina from '../componentes/tituloPagina';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileLines } from '@fortawesome/free-solid-svg-icons';
import numeros from '../dados/numeros.json';
import IrAoTopoWidget from '../componentes/irAoTopoWidget';
import SeoHelmet from '../componentes/seoHelmet';

const Termos = () => {
    return (
        <>
            <SeoHelmet 
                conector=' - '
                subtitulo='Termos de Uso'
                linkcanonical='termos'
            />
            <CabecalhoInfo />
            <CabecalhoMenu />
            <TituloPagina titulo='TERMOS DE USO'
                          h1ClassName='center'
                          hiddenClassName='hidden'
                          link='Termos De Uso'
                          info=''
                          getStyle={() => {}}  
            />

            <TermosStyle>
                <h2>TERMOS DE USO</h2>
                <span><FontAwesomeIcon icon={faFileLines} color='#27921A' fontSize='24px' /></span>

                <div className='termo'>
                    <h3>Sobre os Materiais (Cursos, Módulos e Aulas)</h3>
                    <div className='color-rule'></div>

                    <p>
                        <b>1. Cursos -</b> Cada curso trata de um <b>tema</b> específico da área de Concursos de TI. Atualmente, temos mais de <b>{numeros.numero_cursos}</b> cursos na plataforma. Veja a lista atualizada <a href='/cursos/todos'>clicando aqui.</a> Um curso é dividido em módulos.
                    </p>

                    <p>
                        <b>2. Módulos -</b> Cada módulo trata de um <b>assunto</b> específico da área de Concursos de TI. Atualmente, temos mais de <b>{numeros.numero_modulos}</b> módulos na plataforma. Um módulo é dividido em aulas.
                    </p>

                    <p>
                        <b>3. Aulas -</b> Cada aula gravada trata de um ou mais <b>tópicos</b> da área de Concursos de TI. Atualmente, temos mais de <b>{numeros.numero_aulas}</b> aulas gravadas na plataforma.
                    </p>

                    <p>
                        Atenção: Os totais (de cursos, módulos e aulas gravadas) têm o objetivo de dar uma ordem de grandeza dos materiais existentes na plataforma no momento da aquisição. Esta quantidade pode variar com a evolução da plataforma, sempre preservando o interesse do aluno. Alguns exemplos de alterações que podem afetar as quantidades são:
                    </p>

                    <p>
                        Substituição de aulas antigas por aulas novas ou revisadas; <br></br>
                        Criação de novos cursos para cobrir mais conteúdo de Concursos de TI; <br></br>
                        Realocação de módulos de um curso para outro mais diretamente relacionado; <br></br>
                        Adição de mais módulos aumentando a quantidade de material disponível; <br></br>
                        Retirada de módulos da plataforma por solicitação do professor.
                    </p>

                    <p>
                        Obs.: no caso de retirada de um material por qualquer motivo, preservaremos o acesso do aluno a este material, através de um player (Nutror, Vimeo, Youtube etc.), pelo menos, durante a vigência de seu plano.
                    </p>

                    <p>
                        <b>4. Atualizações -</b> As aulas mais recentes são mostradas em nosso site com a palavra "novo" na cor verde a fim de facilitar a identificação. São consideradas "novas" aulas gravadas a partir de julho de 2019, mês em que a nova versão da plataforma entrou no ar.
                        Você pode ver uma lista de todas elas na página de atualizações da plataforma <a href='/atualizacoes'>clicando aqui.</a>
                    </p>

                    <p>
                        <b>5. Aulas antigas -</b> Os planos de assinatura dão acesso a toda a base. Assim, o aluno terá acesso tanto às aulas gravadas mais recentemente (neste mês, ano etc.), quanto às aulas gravadas há mais tempo. Há um esforço constante de atualização e de composição dos cursos para que sejam mantidos na plataforma os conteúdos mais relevantes, independente da idade, até que sejam trocados.
                    </p>

                    <p>
                        <b>6. Certificados -</b> Os certificados gerados pelo Provas de TI são disponibilizados automaticamente pela plataforma e podem ser gerados pelo aluno. Os dados do certificado são: NOME do aluno, CPF, DATA de CONCLUSÃO, NOME do curso e CARGA-HORÁRIA. Nosso certificado não possui o intuido de se adequar às necessidades de qualquer empresa, órgão etc. e seu formato não pode ser alterado. Não geramos certificados individualmente ou com informações diferentes das listadas acima.
                    </p>
                </div>
                <div className='rule'></div>

                <div className='termo'>
                    <h3>Sobre os Planos (Anual, Bi-Anual e Tri-Anual)</h3>
                    <div className='color-rule'></div>

                    <p>
                        <b>1. Conteúdo do Plano -</b> Todos os planos oferecem acesso às mesmas aulas gravadas listadas na plataforma no momento da aquisição pelo respectivo período contratado. Com relação às aulas da plataforma, aúnica diferença entre os planos é o período de acesso. A lista completa de cursos disponíveis pode ser vista <a href='/cursos/todos'>clicando aqui.</a>
                    </p>

                    <p>
                        Obs.: No caso de <b>promoções</b> temporárias e outros <b>bônus</b>, pode haver diferenças pontuais informados no ato do fechamento do pedido.
                    </p>

                    <p>
                        <b>2. Período de Acesso -</b> O período de acesso é de 365 (trezentos e sessenta e cinco) dias para o plano Anual, 730 (setecentos e trinta) dias no plano Bi-Anual e 1095 (mil e noventa e cinco) dias no plano Tri-Anual.
                    </p>

                    <p>
                        <b>3. Exclusões do Plano -</b> Produtos e serviços não listados na relação de cursos, módulos e aulas gravadas <b>NÃO</b> fazem parte dos planos. Assim, <b>NÃO</b> estão inclusos no plano:
                    </p>

                    <p>
                        <b>NÃO</b> há interação para tirar dúvidas dentro do Nutror; <br></br>
                        <b>NÃO</b> há monitoramento dos fóruns pelos professores; <br></br>
                        <b>NÃO</b> há horas de mentoria inclusas no planos Anual, Bi-Anual e Tri-Anual; <br></br>
                        <b>NÃO</b> há correção de dissertativas no plano Anual (somente no Bi-Anual e no Tri-Anual).
                    </p>

                    <p>
                        Obs.: Os <b>fóruns de discussão</b> existem para interação entre os alunos moderada pela administração da plataforma. Os professores gravam as aulas e as disponibilizam sem interação dentro da plataforma Nutror.
                    </p>

                    <p>
                        <b>4. Acesso Individual - NÃO</b> é permitido compartilhar acesso. Os planos são individuais e intransferíveis. O acesso será monitorado e, através de diversos meios (endereçamento IP, horários, origem do acesso etc.), caso sejam identificados compartilhamentos ou acessos indevidos, o plano será bloqueado para comprovação de identidade, esclarecimentos ou demais medidas previstas.
                    </p>

                    <p>
                        <b>5. Atualizações Futuras -</b> O aluno terá direito a acessar novos materiais adicionados à plataforma. A lista completa pode ser vista <a href='/atualizacoes'>clicando aqui.</a> Porém, a inclusão de novos materiais não altera o período de tempo do plano contratado originalmente.
                    </p>

                    <p>
                        <b>6. Download de Material - NÃO</b> é previsto nem permitido. Prestamos o serviço de acesso a aulas gravadas listadas no site no momento da aquisição. Não há possibilidade de download dos vídeos.
                    </p>

                    <p>
                        <b>7. Interação nas Aulas Gravadas - NÃO</b> é prevista. Trata-se de um conteúdo assíncrono (gravado anteriormente à disponibilização) para acesso online. Os professores que gravaram os conteúdos não monitoram os fóruns.
                    </p>
                </div>
                <div className='rule'></div>

                <div className='termo'>
                    <h3>Sobre o Pagamento</h3>
                    <div className='color-rule'></div>

                    <p>
                        <b>1. Dados do Cliente -</b> A identificação do cliente em nossa plataforma é feita através do <b>e-mail</b> cadastrado.
                        Assim, contatos para tratar de quaisquer assuntos (pagamento, ajuda, dúvidas ou sugestões) devem ser realizados através do e-mail cadastrado na plataforma.
                    </p>

                    <p>
                        Obs.: O suporte poderá acontecer via <b>whatsapp</b>, porém, caso seja necessária alguma ação mais restrita, será solicitado o e-mail de cadastro na plataforma para que se dê continuidade ao atendimento.
                    </p>

                    <p>
                        <b>2. Pagamento -</b> O pagamento é intermediado por gateways seguros integrados à nossa plataforma (Eduzz, PayPal ou PagSeguro). 
                        Questões relacionadas a boletos, cartões de crédito, confirmação de pagamento e demais assuntos financeiros podem precisar ser respondidas através da interação do aluno com estes terceiros, haja visto que não gerenciamos todos os passos da transação.
                    </p>

                    <p>
                        <b>3. Dados Fiscais -</b> Os dados para geração de nota fiscal devem ser completos: nome, CPF, endereço completo (com CEP), e-mail e telefone. Caso estes dados não sejam informados no <b>momento da aquisição</b>, a nota fiscal será gerada com dados incompletos (previsto) para efeito de pagamento de impostos e demais obrigações fiscais.
                    </p>

                    <p>
                        <b>4. Estornos de Pagamento -</b> O procedimento relacionado ao estorno de pagamento, por qualquer motivo, será feito através do mesmo gateway aceito pelo aluno no momento da compra. Vale ressaltar que os prazos, formas e procedimentos variam conforme o tipo de pagamento realizado (boleto, cartão etc.), conforme o gateway escolhido (Eduzz, PayPal ou PagSeguro) e, também, conforme a entidade financeira envolvida (Banco, Visa, Master Card etc.). Assim, poderá ser necessária a interação do aluno com estes terceiros, haja visto que não gerenciamos todos os passos da transação.
                    </p>

                    <p>
                        <b>5. Promoções e Variação de Valores -</b> O processo de definição de valores leva em consideração fatores como: promoções, momento econômico, concorrência, renegociação com fornecedores, custos de operação etc. Busca-se um equilíbro dinâmico a fim de manter o negócio, inclusive, com o objetivo de honrar compromissos antigos. É legalmente previsto que haja alterações de preços de um produto ou serviço, desde que haja informações claras das condições <b>no momento</b> em que um cliente está adquirindo um produto. Assim, deve-se levar em consideração somente as condições específicas <b>no momento</b> da aquisição e não quaisquer <b>condições futuras</b>, quer sejam mais favoráveis ou não ao cliente.
                    </p>
                </div>
                <div className='rule'></div>

                <div className='termo'>
                    <h3>Desistência da Aquisição</h3>
                    <div className='color-rule'></div>

                    <p>
                        <b>1. Período de Avaliação -</b> Conforme previsto, o aluno tem direito a 15 (QUINZE) dias para avaliar a nossa plataforma após a confirmação do pagamento. 
                        Somente <b>dentro deste período</b>, o cancelamento pode ser realizado sem ônus através de solicitação no próprio gateway (Eduzz, PayPal ou PagSeguro). 
                        Em caso de qualquer problema com o gateway, o cliente poderá solicitar à própria administração do site pelo e-mail <b>{numeros.email}</b> ou através de nosso telefone de suporte via whatsapp <b>{numeros.whatsapp}</b>. 
                        Após este período, haverá encargos fiscais (impostos), pagamentos de taxas (no gateway de pagamento e na operadora de cartão), além de custos assumidos pela plataforma a fim de manter os serviços disponíveis para os alunos pelo período contratado.
                    </p>

                    <p>
                        <b>2. Desistência do Plano - NÃO</b> está prevista a possibilidade de cancelamento do plano após o perído de 15 (quinze) dias. Há descontos progressivos oferecidos pela aquisição em quantidade. 
                        Se, antecipadamente, o acordado fosse accessar a plataforma por um período menor, certamente, o valor cobrado teria sido diferente. Há um lado comercial que deve ser respeitado. 
                        Não é razoável contratar uma quantidade grande de serviço, obter desconto pela quantidade e, depois, usar por decisão própria apenas uma parte do serviço e querer o mesmo desconto.
                    </p>

                    <p>
                        <b>3. Custos Diretos por Plano -</b> Há custos diretos da plataforma após a contratação de cada plano. Estes custos <b>independem do uso ou não dos recursos contratados</b>.
                    </p>

                    <p>
                        <b>4. Custos Indiretos por Plano -</b> Somando a isso, para garantir acesso a milhares de aulas a centenas de alunos, na medida em que observamos crescimento, nossa estrutura é redimensionada para garantir o desempenho futuro das aplicações.
                        Assim, são feitas novas aquisições de gravação de material,  capacidade de processamento, espaço de hospedagem, processamento de banco de dados etc.
                        Por isso, quanto menor o período de acesso, maiores são os custos cobrados, proporcionalmente.
                    </p>
                </div>
                <div className='rule'></div>

                <div className='termo'>
                    <h3>Eventos online</h3>
                    <div className='color-rule'></div>

                    <p>
                        <b>1. Acesso ao vivo - NÃO</b> está previsto acesso ao vivo gratuito aos eventos realizados pelo Provas de TI. Assim, os assinantes do Provas de TI poderão participar dos eventos ao vivo, em princípio, mediante as mesmas condições disponibilizadas para o público em geral.
                    </p>
                    
                    <p>
                        <b>2. Desconto para assinantes -</b> O desconto está previsto apenas para assinantes. Neste caso, ele será definido mediante regras que podem variar, caso a caso, dependendo de diversos fatores. O desconto será definido oportunamente e informado previamente nas regras de comercialização de cada evento.
                    </p>

                    <p>
                        <b>3. Acesso à gravação - NÃO</b> está previsto acesso à gravação de evento ao vivo. Apenas para assinantes, poderá ser disponibilizado acesso posterior nos casos em que tiver havido a gravação do conteúdo. A forma de acesso ao conteúdo gravado será a mesma utilizada dentro da assinatura do Plano Anual.
                    </p>
                </div>
                <div className='rule'></div>
            </TermosStyle>

            <Rodape />

            <IrAoTopoWidget />
        </>
    )
}

export default Termos;