import { Footer } from "./styles/rodape.style";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faWhatsapp, faTelegram, faFacebookF, faYoutube, faTwitter, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faLocationDot, faAngleRight, faEnvelope as faEnvelopeSolid } from '@fortawesome/free-solid-svg-icons';
import numeros from '../dados/numeros.json';

const anlgeIcon = <FontAwesomeIcon icon={faAngleRight} color="#27921A"/>

const Rodape = () => {
    return (
        <Footer>
            <div className="footer-info">
                <div className="provas-de-ti flexbox">
                    <p className="title">PROVAS DE TI</p>
                    <p>Somos pioneiros e ainda estamos por aqui...</p>
                    <p>Isso não mudou! ;)</p>
                    <ul>
                        <li><FontAwesomeIcon icon={faEnvelope}/><a href="mailto:faleconosco@provasdeti.com.br">{numeros.email}</a></li>
                        <li><FontAwesomeIcon icon={faWhatsapp}/><a href="https://bit.ly/3cEHx6I">{numeros.whatsapp}</a></li>
                        <li><FontAwesomeIcon icon={faLocationDot}/><a href="/">Av. Sorocaba, 500, Sorocaba - SP</a></li>
                    </ul>
                </div>
                <div className="links-uteis flexbox">
                    <p className="title title-flex">LINKS ÚTEIS</p>
                    <div>
                        <ul>
                            <li>{anlgeIcon} <a href="/mentorias">Mentorias</a></li>
                            <li>{anlgeIcon} <a href="/planosdeassinatura">Assine</a></li>
                            <li>{anlgeIcon} <a href="https://provasdeti.nutror.com/resumo/" target='_blank' rel="noreferrer">Nutror</a></li>
                        </ul>
                    </div>
                    <div>
                        <ul>
                            <li>{anlgeIcon} <a href="/faq">FAQ</a></li>
                            <li>{anlgeIcon} <a href="/termos">Termos</a></li>
                            <li>{anlgeIcon} <a href="/sobre">Sobre Nós</a></li>
                            <li>{anlgeIcon} <a href="/lwm">LWM</a></li>
                        </ul>
                    </div>
                </div>
                <div className="flexbox">
                    <p className="title">PLANOS DE ESTUDO E AULAS</p>
                    <ul>
                        <li>{anlgeIcon} <a href="/atualizacoes">Aulas Recentes</a></li>
                        <li>{anlgeIcon} <a href="/concursosdeti/abertos">Inscrições Abertas</a></li>
                        <li>{anlgeIcon} <a href="/concursosdeti/em-andamento">Concurso em Andamento</a></li>                        
                        <li>{anlgeIcon} <a href="/concursosdeti/encerrados">Concurso Encerrado</a></li>
                        <li>{anlgeIcon} <a href="/concursosdeti/estude-sem-edital">Estude sem Edital</a></li>
                        <li>{anlgeIcon} <a href="/concursosdeti-2024">Concursos de TI em 2024</a></li>
                    </ul>
                </div>
                <div className="flexbox">
                    <p className="title">ALGUNS CANAIS E REDES</p>
                    <div className="icons-div">
                        <a href="https://t.me/profwaltercunha" target='_blank' rel="noreferrer"><FontAwesomeIcon className="icon" icon={faTelegram} color="white" width="15px" height="30px"/></a>
                        <a href="https://www.facebook.com/tiparaconcursos" target='_blank' rel="noreferrer"><FontAwesomeIcon className="icon" icon={faFacebookF} color="white" width="15px" height="30px"/></a>
                        <a href="https://www.youtube.com/user/portalprovas" target='_blank' rel="noreferrer"><FontAwesomeIcon className="icon" icon={faYoutube} color="white" width="15px" height="30px"/></a>
                        <a href="https://twitter.com/timasters" target='_blank' rel="noreferrer"><FontAwesomeIcon className="icon" icon={faTwitter} color="white" width="15px" height="30px"/></a>
                        <a href="https://www.instagram.com/provasdeti" target='_blank' rel="noreferrer"><FontAwesomeIcon className="icon" icon={faInstagram} color="white" width="15px" height="30px"/></a>
                        <a href="https://groups.google.com/g/timasters-google" target='_blank' rel="noreferrer"><FontAwesomeIcon className="icon" icon={faEnvelopeSolid} color="white" width="15px" height="30px"/></a>
                    </div>
                    <div>
                        <img src="/assets/images/logo-abed-associado-transparente.png" alt="logo asasociado ABED"></img>
                    </div>
                </div>
            </div>
            <div className="footer-info-2">
                <div className="tags">
                    <a href="/">HOME</a>
                    <a href="/cursos/todos">CURSOS</a>
                    <a href="/planosdeassinatura">ASSINE JÁ</a>
                </div>
                <div>
                    <p>@LCLABS - PROVASDETI - 29.689.900/0001-70</p>
                </div>
            </div>
        </Footer>
    )
}

export default Rodape;