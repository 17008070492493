import { HeaderInfo } from "./styles/cabecalho.style";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faEnvelope as faEnvelopeSolid } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faInstagram, faTelegram, faTwitter, faWhatsapp, faYoutube } from '@fortawesome/free-brands-svg-icons';
import numeros from "../dados/numeros.json";

const CabecalhoInfo = () => {

    return (
        <HeaderInfo>
            <div className="header-info">
                <div className="contato">
                    <a className="link link-left" href="mailto:faleconosco@provasdeti.com.br" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon className="contato-icon" icon={faEnvelope} color="#27921A" fontSize="16px"/>
                        <span>{numeros.email}</span>
                    </a>
                    <a className="link" href="https://bit.ly/3cEHx6I" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon className="contato-icon" icon={faWhatsapp} color="#27921A" fontSize="16px"/>
                        <span>{numeros.whatsapp}</span>
                    </a>
                </div>

                <div className="sociais">
                    <a href="https://t.me/profwaltercunha" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon className="icon" icon={faTelegram} color="white" fontSize="14px"/>
                    </a>
                    <a href="https://www.facebook.com/tiparaconcursos" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon className="icon" icon={faFacebookF} color="white" fontSize="14px"/>
                    </a>
                    <a href="https://www.youtube.com/user/portalprovas" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon className="icon" icon={faYoutube} color="white" fontSize="14px"/>
                    </a>
                    <a href="https://twitter.com/timasters" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon className="icon" icon={faTwitter} color="white" fontSize="14px"/>
                    </a>
                    <a href="https://www.instagram.com/provasdeti" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon className="icon" icon={faInstagram} color="white" fontSize="14px"/>
                    </a>
                    <a href="https://groups.google.com/g/timasters-google" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon className="icon" icon={faEnvelopeSolid} color="white" fontSize="14px"/>
                    </a>
                </div>

                <div className="login">
                    <a className="link" href="https://provasdeti.nutror.com/resumo/" target="_blank" rel="noreferrer">Login Nutror</a>
                </div>
            </div>
        </HeaderInfo>
    )
}

export default CabecalhoInfo;