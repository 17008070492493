import styled from 'styled-components';

export const CardCursoStyle = styled.div.attrs((props: { colWidth: string }) => props)`

    width: ${props => ((props.colWidth === '1col') ? `100%` : `fit-content`)};
    margin: 30px 60px;
    transition: all 1s ease;

    .wrapper {
        width: max-content;
        position: relative;
        right: 72px;
    }
    
    .background {
        position: relative;
        display: flex;
        flex-direction: row;
        background-color: #FAFAFA;
        width: 416.25px;
        height: 306px;
        margin: auto;
        box-shadow: 5px 0 0 #EAEDF5 inset;

        :hover {
            background-color: #FFF;
            box-shadow: 0 0 15px rgb(0, 0, 0, 15%), 5px 0 0 #EAEDF5 inset;
            transition: all 0.25s ease;
        }

        ::after {
            content: '';
            position: absolute;
            width: 5px;
            height: 0;
            top: 0;
            left: 0;
            background-color: #242C42;
            transition: all 0.5s ease;
        }

        :hover::after {
            height: 100%;
        }
    }

    .info {
        margin: 30px 20px 0 30px;
        max-width: 150px;
        padding: 0;
    }

    .novo-img {
        position: absolute;
        top: 5px;
        left: 5px;
    }

    h3 {
        padding: 0;
        margin: 0;
        font-size: 18px;
        color: #49575F;
        font-weight: 700;
        text-transform: capitalize;

        :hover {
            text-decoration: underline #27921A;
            color: #27921A;
            cursor: pointer;
        }

        a {
            text-decoration: none;
            color: inherit;
        }
    }

    p {
        font-size: 13px;
        color: #49575F;
    }
    
    a {
        font-size: 20px;
        font-weight: bold;
        color: #49575F;
        text-transform: capitalize;
        margin-bottom: 5px;
        text-decoration: blink;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    }

    .info-span {
        display: inline-block;
        margin-right: 10px;
        margin-bottom: -10px;
    }

    span {
        font-size: 13px;
        color: #E2CF00;
    }

    .description {
        font-size: 14px;
        color: #6F8190;
        margin-top: 20px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
    }

    .curso-img {
        width: 360px;
        position: relative;
        top: 28px;
        left: 0px;
    }

    .image {
        width: 220px;
    }

    button {
        position: absolute;
        padding: 0 10px;
        left: 7.5%;
        bottom: -20px;
        width: fit-content;
        height: 34px;
        border: none;
        background-color: #27921A;
        color: #FFF;
        border-radius: 4px;
        font-size: 14px;
        z-index: 10;

        :hover {
            cursor: pointer;
        }

        ::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 0;
            border-radius: 4px;
            z-index: -1;
            background-color: #242C42;
            transition: all 0.5s ease;
        }

        :hover::after {
           width: 100%;
        }
    }

    .btn-span {
        font-size: 14px;
        color: #FFF;
    }

    .col1 {
        width: 100%;

        .background {
            width: 100%;
        }

        .info {
            max-width: 5000px;
            width: 300%;
        }

        button {
            left: 35%;
        }
    }

    @media (max-width: 650px) {
        .background {
            width: 300px;
            height: 270px;
        }

        .info {
            max-width: 100px;
        }

        .curso-img {
            width: 260px;
            position: relative;
            top: 40px;
            left: 0px;
        }

        .image {
            width: 150px;
        }
    }

    @media (max-width: 530px) {

        .background {
            width: 200px;
            height: 165px;
        }

        h3 {
            font-size: 15px;
        }

        .info {
            margin: 10px 10px 0 10px;
            max-width: 67px;
        }

        p, span {
            font-size: 11px;
            margin: 0;
        }

        .description {
            font-size: 12px;
            margin-top: 5px;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }

        .curso-img {
            width: 170px;
            position: relative;
            top: 20px;
            left: 0px;
        }

        .image {
            width: 100px;
        }

        button {
            height: 25px;
            bottom: -13px;
        }

        .btn-span {
            font-size: 11px;
        }

        .novo-img {
            width: 18px;
            top: 2px;
            left: 2px;
        }
    }

`;