import styled from 'styled-components';

export const CardProfessorPerfilStyle = styled.div`

    text-align: center;
    width: fit-content;

    .background {
        position: relative;
        height: 350px;
        width: 270px;
        background-color: #FAFAFA;
        transition: all 0.5s ease;

        ::after {
            content: '';
            position: absolute;
            height: 3px;
            width: 0;
            top: 0;
            left: 0;
            background-color: #242C42;
            transition: all 0.5s ease;
        }

        :hover {
            background-color: #27921A;
            cursor: pointer;

            button {
                background-color: #FFF;
                cursor: pointer;
            }

            .icon {
                color: #A4ABAF;
            }

            ::after {
                width: 100%;
            }
        }

        
    }

    img {
        width: 209px;
        display: block;
        margin: auto;
        position: relative;
        top: 30px;
        border-radius: 50%;
    }

    button {
        position: relative;
        top: 55px;
        border: none;
        padding: 8px;
        border-radius: 3px;
        background-color: #A4ABAF;
        transition: all 0.5s ease;
    }

`;