import { SobreStyle } from './styles/Sobre.style';
import { Button } from '../componentes/styles/cardPlanoAnual.style';
import CabecalhoInfo from '../componentes/cabecalhoInfo';
import CabecalhoMenu from '../componentes/cabecalhoMenu';
import TituloPagina from '../componentes/tituloPagina';
import BannerNumeros from '../componentes/bannerNumeros';
import CarrosselProfessores from '../componentes/carrosselProfessores';
import Rodape from '../componentes/rodape';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWifi, faMagnifyingGlass, faThumbsUp, faUser, faCheck } from '@fortawesome/free-solid-svg-icons';
import IrAoTopoWidget from '../componentes/irAoTopoWidget';
import SeoHelmet from '../componentes/seoHelmet';

const Sobre = () => {

    return (
        <>
            <SeoHelmet
                conector=' - '
                subtitulo='Nossa História'
                linkcanonical='sobre'
            />
            <CabecalhoInfo />
            <CabecalhoMenu />
            <TituloPagina titulo='SOBRE NÓS'
                link='Sobre Nós'
                h1ClassName='center'
                hiddenClassName='hidden'
                info=''
                getStyle={() => { }}
            />

            <SobreStyle>
                <div className='porque-provas'>
                    <img src='/assets/images/mosaico-about-us.jpg' alt='sobre nós'></img>
                    <div className='txt'>
                        <p className='titulo'>Por que <b>PROVAS DE TI</b> ?</p>
                        <p>
                            Desde a lista de e-mails TIMasters (criada em <b>Jul/2007</b> pelo Prof. Walter Cunha), lá se vão vários anos. Muitos professores da atualidade foram nossos alunos no passado. Qualquer que seja o site que você conhece, <b>nenhum está há tanto tempo quanto nós</b> gerando materiais de qualidade para Concursos de TI e ninguém tem tanto material específico sobre Tecnologia da Informação. Quando ninguém mais se interessava por criar materiais das disciplinas mais improváveis, já estávamos aqui, preparando os melhores conteúdos para você. E, mesmo quando ninguém quiser mais, nós ainda estaremos aqui. Essa é nossa missão!
                        </p>
                        <p>
                            Nesta segunda década de existência, vamos desenvolver cada vez mais uma plataforma que torne o conteúdo mais acessível a todos. Em 2019 lançamos uma nova plataforma voltada para o acesso através de assinaturas. Assim, um conteúdo que antes só estava acessível por mais de R$ 5.000,00, agora pode ser assinado por algo em torno de R$ 1,00/dia. Aproveite!
                        </p>

                        <a href='/planosdeassinatura'><Button className='btn-1'>VER PLANOS</Button></a>
                        <a href='/cursos/todos'><Button className='btn-2'>NOSSOS MATERIAIS</Button></a>
                    </div>
                </div>

                <div className='dados-provas'>
                    <p className='titulo'>AQUI NO <b>PROVAS DE TI</b> VOCÊ TEM</p>
                    <p>Uma enorme base de conteúdo por um valor acessível.</p>

                    <div className='circles-wrapper'>
                        <div className='circle-info'>
                            <div className='circle'><FontAwesomeIcon icon={faWifi} /></div>
                            <p>Conteúdos flexíveis que te preparam para trabalhar com TI.</p>
                        </div>

                        <div className='circle-info'>
                            <div className='circle'><FontAwesomeIcon icon={faMagnifyingGlass} /></div>
                            <p>Estudar para vários concursos sem pagar nada mais por isso.</p>
                        </div>

                        <div className='circle-info'>
                            <div className='circle'><FontAwesomeIcon icon={faUser} /></div>
                            <p>Interagir com outros estudantes e buscar apoio e motivação.</p>
                        </div>
                    </div>
                </div>

                <div className='novidades'>
                    <div className='info'>
                        <h3>NOVIDADES PARA ACELERAR SEU RESULTADO</h3>
                        <p>Nossa nova plataforma traz o melhor conteúdo que você já possuía no Provas de TI, mas agora com muito mais novidades. Confira:</p>

                        <div className='dropdown'>
                            <input type='checkbox' id='input1'></input>
                            <label htmlFor='input1'><FontAwesomeIcon icon={faCheck} color='#27921A' /> <p>Renovação constante dos materiais</p></label>
                            <p className='txt'>O conteúdo é constantemente renovado com novas aulas de exercícios ou substituição de aulas teóricas. E você tem acesso a tudo sem pagar mais por isso.</p>
                            <div className='rule'></div>
                        </div>

                        <div className='dropdown'>
                            <input type='checkbox' id='input2'></input>
                            <label htmlFor='input2'><FontAwesomeIcon icon={faCheck} color='#27921A' /> <p>Área de alunos renovada</p></label>
                            <p className='txt'>Através do Nutror o aluno tem acesso a todos os seus cursos, pode gerar certificados e interagir com os outros alunos comentando e avaliando as aulas.</p>
                            <div className='rule'></div>
                        </div>

                        <div className='dropdown'>
                            <input type='checkbox' id='input3'></input>
                            <label htmlFor='input3'><FontAwesomeIcon icon={faCheck} color='#27921A' /> <p>Planos de estudo por edital</p></label>
                            <p className='txt'>Os professores indicam os módulos a serem estudados para os concursos de TI mais relevantes. Isso acelera muito o seu planejamento.</p>
                        </div>
                    </div>

                    <img src='assets/images/picture-8.png' alt='novidades'></img>
                </div>
            </SobreStyle>

            <BannerNumeros background={true} info={true} />

            <CarrosselProfessores />

            <Rodape />

            <IrAoTopoWidget />
        </>
    )
}

export default Sobre;