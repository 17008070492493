import styled from 'styled-components';

export const PorQueProvasStyle = styled.div`

    background-color: #242C42;
    max-height: fit-content;
    padding: 0 20%;
    display: flex;
    flex-flow: row wrap;
    position: relative;
    z-index: 0;
    justify-content: space-between;

    .conteudo {
        margin: 50px 2% 50px 0;
        max-width: 60%;

        h2 {
            color: #FFF;
            font-size: 30px;
            font-weight: 700;
        }

        p {
            color: #D1D8EB;
            font-size: 16px;
            text-align: justify;
            line-height: 25px;
        }

        .titulo {
            color: #FFF;
            font-size: 30px;  
        }

    }

    img {
        max-height: 400px;
    }

    @media (max-width: 1800px) {
        padding: 0 10%;
    }

    @media (max-width: 1400px) {
        padding: 0 5%;
    }

    @media (max-width: 900px) {
        img {
            display: none;
        }

        .conteudo {
            max-width: 100%;
        }
    }

`;

export const Button = styled.button`

    position: relative;
    padding: 15px 12px;
    background-color: transparent;
    border-radius: 4px;
    border: 2px solid #27921A;
    margin: 30px 15px 0px 0px;
    transition: all 0.3s ease;
    

    &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: #27921A;
        z-index: -1;
        transition: all 0.5s ease;
    }

    a {
        font-size: 14px;
        color: #FFF;
        z-index: 3;
        text-decoration: none;
        transition: all 0.3s ease;
    }

    &:hover {
        cursor: pointer;

        &:after {
            width: 0;
        }

        a {
            color: #27921A;
        }
    }

`;

export const ProvasInfo = styled.div`

    max-height: fit-content;
    background-image: url(/assets/images/background-why-choose-us.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 50px 20%;
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    &:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(10, 15, 28, 0.7);
    }

    div {
        z-index: 2;
        display: flex;
        align-items: center;
        margin: 20px 50px;
    }

    .icon {
        font-size: 36px;
        color: #27921A;
        display: table-cell;
        margin: 5px;
    }

    p {
        font-size: 16px;
        color: #FFF;
        display: table-cell;
        margin: 5px;
    }

    @media (max-width: 1700px) {
        padding: 50px 15%;
    }

    @media (max-width: 1500px) {
        padding: 50px 10%;
    }

    @media (max-width: 1300px) {
        padding: 50px 5%;
    }

    @media (max-width: 1000px) {
        padding: 50px 1%;

        div {
            margin: 30px 20px;
        }
    }

`;