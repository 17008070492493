import styled from "styled-components";

export const LandingMentoriaStyle = styled.div`

    // Estilo para esconder o header
    .header-hidden {
        display: none;
    }

    // Estilo do contador regressivo
    .contador {
        font-size: 24px;
        color: #FFFFFF;
        margin: auto;
        width: 109%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        
        .circle {
            display: inline-block;
            width: 36px;
            height: 36px;
            background: black; 
            -moz-border-radius: 70px; 
            -webkit-border-radius: 70px; 
            border-radius: 50%;                        
            margin: 0 2px;
        }   
        
        .circle:after{
            position: relative;
            top: -25%;
        }

        #dian {
            &:after{
                content: 'dia';
                display: inline-block;
                font-size: 12px;
                color: #000000;
            }
        }         
        #hrsn {
            &:after{
                content: 'hrs';
                display: inline-block;
                font-size: 12px;
                color: #000000;
            }
        }         
        #minn {
            &:after{
                content: 'min';
                display: inline-block;
                font-size: 12px;
                color: #000000;
            }
        }         
        #segn {
            &:after{
                content: 'seg';
                display: inline-block;
                font-size: 12px;
                color: #000000;
            }
        }    
        
        #diav {
            &:after{
                content: 'dia';
                display: inline-block;
                font-size: 12px;
                color: #000000;
            }
        }         
        #hrsv {
            &:after{
                content: 'hrs';
                display: inline-block;
                font-size: 12px;
                color: #000000;
            }
        }         
        #minv {
            &:after{
                content: 'min';
                display: inline-block;
                font-size: 12px;
                color: #000000;
            }
        }         
        #segv {
            &:after{
                content: 'seg';
                display: inline-block;
                font-size: 12px;
                color: #000000;
            }
        }                 
        
    }
`;