import { Helmet, HelmetProvider } from 'react-helmet-async';
import numeros from '../dados/numeros.json';

interface SeoHelmetInfo {
    conector: string,
    subtitulo: string,
    linkcanonical?: string
}

const SeoHelmet = (props: SeoHelmetInfo) => {

    return (
        <HelmetProvider>
            <Helmet>‍
                <title>TI para Concursos {props.conector} {props.subtitulo}</title>‍
                <meta name="description" content="Desde 2008, somos pioneiros na preparação para Concursos de Tecnologia da Informação. Estude com os pioneiros da área." />
                <link rel='canonical' href={`${numeros.linkcanonical}/${props.linkcanonical}`} />                
            </Helmet>
        </HelmetProvider>
    )
}

export default SeoHelmet;