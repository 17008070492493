import CabecalhoInfo from '../componentes/cabecalhoInfo';
import CabecalhoMenu from '../componentes/cabecalhoMenu';
import Rodape from '../componentes/rodape';
import BannerHomePage from '../componentes/bannerHomePage';
import CursosRecentesEmDestaque from '../componentes/cursosRecentesEmDestaque';
import BannerNumeros from '../componentes/bannerNumeros';
import PorQueProvas from '../componentes/porQueProvas';
import CarrosselProfessores from '../componentes/carrosselProfessores';
import IrAoTopoWidget from '../componentes/irAoTopoWidget';
import SeoHelmet from '../componentes/seoHelmet';

const ano = new Date().getFullYear().toString();

const Home = () => {
    return (
        <>
            <SeoHelmet 
                conector=' em '
                subtitulo={ano}
                linkcanonical=''
            />
            <CabecalhoInfo />
            <CabecalhoMenu />
            <BannerHomePage />
            <CursosRecentesEmDestaque />
            <BannerNumeros background={true} info={true} />
            <PorQueProvas />
            <CarrosselProfessores />
            <Rodape />
            <IrAoTopoWidget />
        </>
    )

}

export default Home;