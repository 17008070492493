import styled from 'styled-components';

export const BuscaDinamicaStyle = styled.div`

    padding: 20px 22%;
    background-color: #EAEDF5;

    .form {
        border: solid 1px #27921A;
        border-radius: 5px;
        width: 290px;
        margin: auto;
    }

    input {
        width: 200px;
        height: 25px;
        padding: 5px 18px;
        border: none;
        font-size: 15px;
        background-color: #EAEDF5;
        border-radius: 5px;
    }

    button {
        height: 35px;
        width: 50px;
        background-color: #EAEDF5;
        border: none;
        border-radius: 5px;
        
        &:hover {
            cursor: pointer;
            color: #27921A;
        }
    }

    @media (max-width: 1144px) {
        padding: 15px 5%;
    }

    @media (max-width: 380px) {
        .form {
            width: 225px;
        }

        input {
            width: 150px;
            padding: 5px 14px;
            font-size: 13px;
        }

        button {
            width: 37.5px;
        }
    }

`;