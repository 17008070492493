import { ProfessorPerfilStyle, ProfessorCursos } from './styles/ProfessorPerfil.style';
import TituloPagina from '../componentes/tituloPagina';
import { API_URL } from '../config';
import CardProfessorPerfil from '../componentes/cardProfessorPerfil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import CarregamentoWidget from '../componentes/carregamentoWidget';

const ProfessorPerfil = () => {

    interface ProfessorInfo {
        id: number,
        nome: string,
        paragrafo_info_1: string,
        paragrafo_info_2: string,
        foto: string,
        titulo: string,
        funcao: string
    }

    interface ProfessorCursos {
        modulo: string,
        descricao: string,
        id: string,
        curso_id: string
        curso: string,
        total_horas: string,
    }
 
    const { professorId } = useParams();
    const [professorInfo, setProfessorInfo] = useState<ProfessorInfo>();
    const [professorCursos, setProfessorCursos] = useState<ProfessorCursos[]>();

    useEffect(() => {
        
        const getProfessorInfoPorId = async () => {
            const response = await axios.get(`${API_URL}/api/professores/get/professorPorId`, {
                params: {
                    id: professorId
                }
            })
            setProfessorInfo(response.data.results[0]);
        }

        const getProfessorCursosPorId = async () => {
            const response = await axios.get(`${API_URL}/api/professores/get/professorCursosPorId`, {
                params: {
                    id: professorId
                }
            })
            setProfessorCursos(response.data.results);
        }

        getProfessorInfoPorId();
        getProfessorCursosPorId();

    }, [professorId]);

    return (
        <>
        {!professorInfo && !professorCursos ? <CarregamentoWidget /> :
        <ProfessorPerfilStyle>
            {professorInfo && <div>
                <TituloPagina h1ClassName='center'
                              hiddenClassName='hidden'
                              titulo={`Página do Prof. ${professorInfo.nome}`}
                              info='Professor de TI para Concursos'
                              link='Perfil'
                              getStyle={() => {}}
                />

                <div className='professor-info'>
                    <CardProfessorPerfil image={professorInfo.foto}
                                         link={`${professorInfo.id}`}
                    />
                    <div className='detalhes'>
                        <h2>{professorInfo.nome}</h2>
                        <h3>{`${professorInfo.titulo} - ${professorInfo.funcao}`}</h3>
                        <p>{professorInfo.paragrafo_info_1}</p>
                        <p>{professorInfo.paragrafo_info_2}</p>
                    </div>
                </div>
            </div>}

            <ProfessorCursos>
                <h3>Contribuições do Professor</h3>
                <div className='color-rule'></div>

                {professorCursos && <div className='table'>
                    <table>
                        <thead>
                            <tr className='table-title'>
                                <th>ID</th>
                                <th>CURSO</th>
                                <th>MÓDULOS DO CURSO</th>
                                <th>DESCRIÇÃO</th>
                            </tr>
                        </thead>
                        <tbody>
                            {professorCursos.map((modulo, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <tr className='curso-row'>
                                            <td data-label="ID"><a href={`/cursos/${modulo.curso_id}`} target="_blank" rel='noreferrer'>CR{modulo.curso_id}</a></td>
                                            <td data-label="CURSO"><a href={`/cursos/${modulo.curso_id}`} target="_blank" rel='noreferrer'>{modulo.curso}</a></td>
                                            <td data-label="MÓDULOS DO CURSO"><a href={`/cursos/${modulo.curso_id}`} target="_blank" rel='noreferrer'>{modulo.modulo} (MD{modulo.id}) <p><FontAwesomeIcon icon={faClock} />  {modulo.total_horas}</p></a></td>
                                            <td data-label="DESCRIÇÃO"><a href={`/cursos/${modulo.curso_id}`} target="_blank" rel='noreferrer'>{modulo.descricao}</a></td>
                                        </tr>
                                        <tr className='row-space'></tr>
                                    </React.Fragment>
                                )
                            })}
                        </tbody>
                    </table>
                </div>}
            </ProfessorCursos>
        </ProfessorPerfilStyle>}
        </>
    )
}

export default ProfessorPerfil;