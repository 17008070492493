import CabecalhoInfo from '../componentes/cabecalhoInfo';
import CabecalhoMenu from '../componentes/cabecalhoMenu';
import Rodape from '../componentes/rodape';
import { Outlet } from 'react-router-dom';
import IrAoTopoWidget from '../componentes/irAoTopoWidget';
import SeoHelmet from '../componentes/seoHelmet';

const ConcursosDeTi = () => {

    return (
        <>
            <SeoHelmet 
                conector=' - '
                subtitulo='Planos de Estudo'
                linkcanonical='concursosdeti'
            />
            <CabecalhoInfo />
            <CabecalhoMenu />
            <Outlet />
            <Rodape />
            <IrAoTopoWidget />
        </>
    )

}

export default ConcursosDeTi;