import styled from 'styled-components';

export const AtualizacoesStyle = styled.div`

    .pag-titulo {
        text-align: center;
        background: url('https://www.provasdeti.com.br/assets/images/background-search.png') no-repeat;
        background-color: #27921A;
        background-position: center;
        background-size: cover;
        padding: 50px 0;

        .pesquisa-options {
            display: flex;
            flex-flow: row wrap;
            justify-content: left;
            align-items: center;
            padding-left: 18px;
            
            .radio, input, label {
                cursor: pointer;
            }

            .radio {
                margin-right: 15px;
            }

            p {
                font-size: 20px;
                font-weight: 500;
                margin-right: 10px;
                padding-bottom: 3px;
                color: #222;
            }

            label {
                font-size: 18px;
                color: #222;
            }
        }

        .radio input {
            width: auto;
        }
        
        .titulo-flex {
            margin: auto;
            width: 100%;
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;            
            align-items: center;
        }

        select, input {
            padding: 10px 20px;
            margin: 10px 20px;
            font-size: 16px;
            border-radius: 5px;
            width: 280px;
        }

        .radio-input {
            padding: 0;
            margin: 0 10px;
        }

        option {
            font-size: 15px;
        }

        select:hover {
            cursor: pointer;
        }

        .btn {
            margin: 10px 20px;
        }
    }

    .pag-titulo-flex {
        padding: 50px 20%;
    }

    h1 {
        font-size: 36px;
        font-weight: 600;
        color: #FFF;
        position: relative;
        top: 38%;
        margin: 0;
    }

    @media (max-width: 1300px) {
        .pag-titulo-flex {
            padding: 10px 5%;
        }
    }

    @media (max-width: 800px) {
        h1 {
            font-size: 25px;
        }
    }

    @media (max-width: 300px) {
        .pag-titulo {
            select, input {
                padding: 5px 5px;
                margin: 10px;
                font-size: 16px;
                width: 280px;
            }

            option {
                font-size: 10px;
            }

            .titulo-flex {
                justify-content: space-between;
            }
        }
    }

`;

export const Wrapper = styled.div`

    width: 1150px;
    padding: 50px 0;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    color: #49575F;

    @media (max-width: 1200px) {
        width: auto;
        padding: 20px 5%;
    }

`;

export const GravacoesInfo = styled.div`

    width: 850px;
    margin-right: 29px;

    h1 {
        text-transform: uppercase;
        text-align: left;
        font-size: 36px;
        font-weight: 500;
        margin: 0 0 25px 0;
        color: #000;
    }

    img {
        width: 850px;
    }

    h2 {
        font-size: 30px;
        font-weight: bold;
        margin: 40px 0 5px 0;
    }

    .color-rule {
        height: 5px;
        width: 50px;
        background-color: #27921A;
        margin-bottom: 30px;
    }

    .texto {
        text-align: justify;
    }

    h3 {
        font-size: 24px;
        margin-top: 40px;
    }

    .icon {
        width: 20px;
        margin-left: -1px;
    }

    .rule {
        width: 100%;
        height: 0.1px;
        background-color: #DDD;
        margin-top: 30px;
    }

    @media (max-width: 1200px) {
        margin: 0 0 20px 0;
    }

    @media (max-width: 800px) {
        h2 {
            font-size: 25px;
        }
    }

`;

export const Gravacoes = styled.div`

    .titulo {
        display: flex;
        justify-content: space-between;
    }

    .section-title {
        display: inline-block;
    }

    .instrucoes {
        display: flex;
        align-items: center;
    }
    
    img {
        width: 50px; 
        height: 50px;
        margin-right: 15px;
    }

    h3 {
        margin: 0;
        font-size: 18px;
        font-weight: 500;
        color: #27921A;
    }

    .conteudo-flex {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }

    .info-txt {
        margin: 3px 0 0 0;
        color: #3C763D;
        background-color: #DFF0D8;
        font-size: 12px;
        padding: 4px;
        border-radius: 4px;
    }

    .table-title {
        width: 100%;
        background-color: #90A1AE;
        color: #FFF;

        th {
            padding: 15px;
            font-size: 14px;
        }

        .a {
            width: 240px;
        }
    }

    .modulo-title {
        background-color: #E9F1F7;
        text-align: left;

        .modulo-info {
            color: #6F8190;
            font-size: 15px;
            padding: 10px;

            .flex {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            p {
                margin: 5px;

                &:hover {
                    text-decoration: underline #27921A;
                    color: #27921A;
                }
            }

            a {
                text-decoration: none;
                color: #6F8190;
                display: inline-block;

                &:hover {
                    text-decoration: inherit;
                    color: inherit;
                }
            }
        }

        .nutror-icon {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-right: 10px;

            img {
                width: 40px;
                height: 40px;
                margin: auto;

                &:hover {
                    transform: scale(120%);
                    transition: all 0.3s;
                }
            }

            h3 {
                color: #000;
                font-size: 18px;
                margin: 0;
            }

            p {
                font-size: 13px;
                margin: 0;
            }
        }
    }

    .aula-row {
        background-color: #FAFAFA;

        td {
            font-size: 14px;
            text-align: justify;
            padding: 12px;
            color: #737373;
        }

        .aula-nome {
            text-align: left;
        }

        .center {
            text-align: center;

            .controle {
                margin: 0;
                color: #86BC42;
                font-weight: 600;
                font-size: 15px;
                font-style: italic;
            }
        }

        .duracao {
            width: 90px;
        }

        a {
            text-decoration: none;
            color: #6F8190;
            
            &:hover {
                text-decoration: underline #27921A;
                color: #27921A;
            }
        }

        .center {
            color: #6F8190;
        }
    }

    .space {
        height: 15px;
    }

    .pagination-btns {
        width: auto 90%;
        list-style: none;
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        margin: 50px auto 10px auto;
        margin-left: -10%;
    }

    .pagination-btns a {
        padding: 10px;
        border: solid 1px #27921A;
        border-radius: 5px;
        margin: 8px;
        cursor: pointer;
    }

    .active-btn a {
        background-color: #27921A;
        color: #FFF;
    }

    .break a {
        border: none;
        cursor: default;
        margin: 0;
    }

    @media (max-width: 900px) {
        .titulo {
            display: block;
        }

        .instrucoes {
            margin-bottom: 30px;
        }
        
        img {
            width: 30px; 
            height: 30px;
            margin-right: 15px;
        }

        h3 {
            font-size: 14px;
        }

        .info-txt {
            font-size: 9px;
            padding: 3px;
        }

        .pagination-btns a {
            padding: 1px 3px;
            border: solid 1px #27921A;
            border-radius: 3px;
            margin: 2px;
            cursor: pointer;
            font-size: 11px;
        }

        .break a {
            border: none;
            cursor: default;
            margin: 0;
        }
    }

    @media (max-width: 750px) {

        .conteudo {
            width: auto;
        }

        table thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        table td {
            border-bottom: 1px solid #ddd;
            display: flex;
            justify-content: space-between;
            text-align: right;
        }

        table td::before {
            content: attr(data-label);
            float: left;
            text-align: left;
            margin: auto 10px auto 0;
            color: #737373;
            width: 30%;
        }

        table td:last-child {
            border-bottom: 0;
        }

        .table-content-wrapper {
            width: 70%;
        }

        .row-space {
            background-color: #FFF;
            height: 5px;
        }

        .aula-row {
            border: 1px solid #ddd;
            border-bottom: 3px solid #ddd;
            display: block;
            margin-bottom: .625em;

            .duracao {
                width: auto;
            }

            .center {
                text-align: justify;
            }
        }

        .space {
            height: 30px;
        }

        .modulo-title {
            .modulo-info {
                font-size: 13px;
            }

            .nutror-icon img {
                width: 35px;
                height: 35px;
            }
        }
    }

`;

export const CursosDestaque = styled.div`

    .titulo {
        width: 262.5px;
        height: 20px;
        padding: 15px 0;
        background-color: #242C42;
        font-weight: bold;
        font-size: 15px;
        color: #FFF;
        text-align: center;
        margin-top: 30px;
    }

    .conteudo {

        .curso {
            height: fit-content;
            width: 262.5px;
            display: flex;
            align-items: flex-start;
            margin-top: 20px;
        }

        img {
            width: 100px;
        }

        .relacoesInfo {
            padding-left: 10px;
        }

        a {
            font-size: 15px;
            color: #6F8190;
            text-decoration: none;
            width: fit-content;

            h4 {
                width: fit-content;
                margin: 0;
            }

            :hover {
                text-decoration: underline #27921A;
                color: #27921A;
            }
        }

        p {
            font-size: 14px;
            color: #6F8190;
            margin: 3px 0 0 0;
        }

        .rule {
            width: 100%;
            height: 0.1px;
            background-color: #DDD;
            margin-top: 10px;
        }
        
    }

`;