import styled from 'styled-components';

export const TituloPaginaStyle = styled.div`

    position: relative;
    background-image: url(https://www.provasdeti.com.br/assets/images/background-count-number.png);
    background-repeat: repeat;
    background-attachment: fixed;
    background-position: top;
    text-align: center;
    z-index: 9;

    ::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(10, 15, 28, 0.7);
        z-index: -1;
    }
    
    .wrapper {
        padding: 50px 20% 20px 20%;
    }

    h1 {
        font-size: 42px;
        font-weight: 900;
        color: #FFF;
        margin: 20px 0 5px 0;
        text-transform: uppercase;
    }

    h2 {
        font-size: 22px;
        font-weight: 400;
        color: #FFF;
        margin: 5px 0 15px 0;
    }

    .center {
        margin-top: 50px;
    }

    p {
        font-size: 42px;
        font-weight: 900;
        text-transform: uppercase;
        line-height: 1;
        margin: 0 0 7px 0;
        color: #FFF;
    }

    a {
        text-decoration: none;
        font-size: 14px;
        color: #CCC;
        padding: 0 7px 0 7px;

        :hover {
            text-decoration: underline #FFF;
            cursor: pointer;
            color: #FFF;
        }
    }

    .link {
        text-decoration: none;
        font-size: 14px;
        color: #FFF;
        padding: 0 7px 0 7px;
        display: inline-block;
    }

    .flex {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 20px;
    }

    .txt-info {
        font-size: 13px;
        color: #FFF;
        text-align: left;
        height: fit-content;
        width: fit-content;
        margin: 0;
    }

    .counter {
        width: 220px;
    }

    .detalhes {
        margin-top: 10px;
        width: 250px;
    }

    .btns {
        padding: 0;
        height: fit-content;
        width: fit-content;
    }

    .hidden {
        visibility: hidden;
    }

    input {
        display: none;
    }

    .btn {
        text-align: right;
        border: none;
        background-color: transparent;
        padding: 3px 6px;
        margin-left: 10px;
        border-radius: 3px;
        transition: all 0.25s ease;

        :hover {
            background-color: rgba(170, 170, 170, 0.8);
            cursor: pointer;
        }

    }

    .active {
        background-color: #49575F;
    }

    @media (max-width: 1200px) {
        .wrapper {
            padding: 50px 10% 20px 10%;
        }

        h1 {
            font-size: 38px;
        }
    }

    @media (max-width: 800px) {
        .wrapper {
            padding: 50px 5% 20px 5%;
        }

        h1 {
            font-size: 34px;
        }
    }

    @media (max-width: 660px) {
        .wrapper {
            padding: 16px 2% 8px 2%;
        }

        h1 {
            font-size: 30px;
        }

        .txt-info {
            font-size: 10px;
        }

        .btns {
            display: none;
        }

        .detalhes {
            width: 180px;
        }
    }

`;