import { CursoDetalhesStyle, Wrapper, ConcursoInfo, Relacoes, CursosRelacionados, Modulos } from './styles/CursoDetalhes.style';
import TituloPagina from '../componentes/tituloPagina';
import CardPlanoAnual from '../componentes/cardPlanoAnual';
import { API_URL } from '../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion, faBan, faDisplay, faFile, faFileZipper, faCirclePlay, faLock, faXmark } from '@fortawesome/free-solid-svg-icons';
import { faClock, faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import numeros from '../dados/numeros.json';
import CarregamentoWidget from '../componentes/carregamentoWidget';

const CursoDetalhes = () => {

    interface CursoInfo {
        id: string,
        nome: string,
        descricao: string
    }

    interface CursoRelacoes {
        id: string,
        nome: string
    }

    interface ModulosAulas {
        modulo_id: string,
        modulo_nome: string,
        linknutrormod: string,
        professor: string,
        aula_id: number,
        aula_nome: string,
        duracao: string,
        tipo: number,
        controle: number,
        keywords1: string,
        total_horas: string,
        link: string
    }

    const [cursoInfo, setCursoInfo] = useState<CursoInfo>();
    const [cursoRelacoes, setCursoRelacoes] = useState<CursoRelacoes[]>();
    const [modulosAulas, setModulosAulas] = useState<ModulosAulas[]>();
    const [clicked, setClicked] = useState(false);
    const [videoId, setVideoId] = useState<string>();
    const { cursoId } = useParams();

    useEffect(() => {

        const getCursoInfoPorId = async () => {
            const response = await axios.get(`${API_URL}/api/cursos/get/cursoInfoPorId`, {
                params: {
                    id: cursoId
                }
            })
            setCursoInfo(response.data.results[0]);
        }

        const getCursosRelacionadosPorId = async () => {
            const response = await axios.get(`${API_URL}/api/cursos/get/cursosRelacionadosPorId`, {
                params: {
                    id: cursoId
                }
            })
            setCursoRelacoes(response.data.results);
        }

        const getModulosAulasPorId = async () => {
            const response = await axios.get(`${API_URL}/api/cursos/get/modulosAulasPorId`, {
                params: {
                    id: cursoId
                }
            })
            setModulosAulas(response.data.results);
        }

        getCursoInfoPorId();
        getCursosRelacionadosPorId();
        getModulosAulasPorId();

    }, [cursoId]);

    const createMarkup = () => {
        return {__html: cursoInfo!.descricao}
    }

    const handleClick = (vidId: string) => {
        setClicked(!clicked);
        setVideoId(vidId);
    }

    const returnAulaTipo = (tipo: number, controle: number) => {
        if (tipo === 1 && controle === 3) {
            return (
                <td data-label="TIPO" className='center'><div className='table-content-wrapper'><FontAwesomeIcon icon={faDisplay} /></div></td>
            )
        } else if (tipo === 1 && controle === 4) {
            return (
                <td data-label="TIPO" className='center'><div className='table-content-wrapper'><FontAwesomeIcon icon={faDisplay} /> <p className='controle'>novo</p></div></td>
            )
        } else if (tipo === 2) {
            return (
                <td data-label="TIPO" className='center'><div className='table-content-wrapper'><FontAwesomeIcon icon={faFilePdf} /></div></td>
            )
        }
    }

    return (
        <>
        {!cursoInfo && !cursoRelacoes && !modulosAulas ? <CarregamentoWidget /> :
        <CursoDetalhesStyle>
            {cursoInfo && <TituloPagina titulo={`CR${cursoInfo.id} - ${cursoInfo.nome}`}
                                        info=''
                                        link='Cursos'
                                        h1ClassName='center'
                                        hiddenClassName='hidden'
                                        getStyle={() => {}}
            />}
            <Wrapper>
                {cursoInfo && <ConcursoInfo>
                    <h1>{`CR${cursoInfo.id} - ${cursoInfo.nome}`}</h1>
                    <img src={`/assets/images/courses/${cursoInfo.id}_banner.jpg`} alt='banner do curso'></img>
                    <h2>De que trata este curso?</h2>
                    <div className='color-rule'></div>
                    <p className='texto' dangerouslySetInnerHTML={createMarkup()} />
                    <h3>Atenção</h3>
                    <p><FontAwesomeIcon icon={faQuestion} className='icon' /> Dúvidas: e-mail p/ <b>{numeros.email}</b> ou whatsapp p/ <b>{numeros.whatsapp}</b>.</p>
                    <p><FontAwesomeIcon icon={faBan} className='icon' /> Aulas em vídeo para acesso <b>individual</b> no desktop ou no celular.</p>
                    <p><FontAwesomeIcon icon={faBan} className='icon' /> <b>Não temos cursos em PDF</b>. Trabalhamos somente com cursos em vídeo.</p>
                    <div className='rule'></div>
                    <Modulos>
                        <div className='titulo'>
                            <div className='section-title'>
                                <h2>Módulos e Aulas</h2>
                                <div className='color-rule'></div>
                            </div>
                            <div className='instrucoes'>
                                <img src='https://www.provasdeti.com.br/assets/images/nutror-icon.png' alt='ícone Nutror'></img>
                                <div className='inst-txt'>
                                    <h3>Este é o ícone do Player Nutror.</h3>
                                    <h3>Clique para abrir a área de estudo.</h3>
                                    <p className='info-txt'>Não use aba anônima. Você precisa estar logado.</p>
                                </div>
                            </div>
                        </div>

                        {modulosAulas && <div className='conteudo'>
                            <table>
                                <thead>
                                    <tr className='table-title'>
                                        <th className='a'>TÍTULO</th>
                                        <th className='b'>PALAVRAS-CHAVE</th>
                                        <th className='c'>DURAÇÃO</th>
                                        <th className='d'>TIPO</th>
                                        <th className='e'>STATUS</th>
                                    </tr>
                                    <tr className='space'></tr>
                                </thead>
                            

                                {modulosAulas.map((modulo, index) => {
                                    if (modulosAulas[index + 1] === undefined || modulosAulas[index + 1].modulo_id !== modulo.modulo_id) {
                                        return (
                                            <tbody key={modulo.modulo_id}>
                                                <tr className='modulo-title'>
                                                    <th colSpan={5}>
                                                        <div className='th-flex'>
                                                            <div className='modulo-info'>
                                                                <p>{`Curso (CR${cursoInfo.id}): ${cursoInfo.nome}`}</p>
                                                                <p>{`Módulo (MD${modulo.modulo_id}): ${modulo.modulo_nome} - Prof. ${modulo.professor}`}</p>
                                                                <p>Slides: <a href={`/assets/slides/md${modulo.modulo_id}.rar`} download>RAR <FontAwesomeIcon icon={faFile} /></a> ou <a href={`/assets/slides/md${modulo.modulo_id}.zip`} download>ZIP <FontAwesomeIcon icon={faFileZipper} /></a></p>
                                                                <p>Duração (hh:mm): {modulo.total_horas}</p>
                                                            </div>
                                                            <div className='nutror-info'>
                                                                <div className='nutror-icon'>
                                                                    <a href={modulo.linknutrormod} target="_blank" rel='noreferrer'><img src='https://www.provasdeti.com.br/assets/images/nutror-icon.png' alt='ícone Nutror'></img></a>
                                                                    <div>
                                                                        <h3>Link Direto ao Nutror</h3>
                                                                        <p>Não use aba anônima.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </th>
                                                </tr>
                                            
                                            
                                                {modulosAulas.map((aula, index) => {
                                                    if (aula.modulo_id === modulo.modulo_id) {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <tr key={aula.aula_id} className='aula-row'>
                                                                    <td data-label="TÍTULO"><div className='table-content-wrapper'>{`(AL${aula.aula_id}) ${aula.aula_nome}`}</div></td>
                                                                    <td data-label="PALAVRAS-CHAVE"><div className='table-content-wrapper'>{aula.keywords1}</div></td>
                                                                    {(aula.tipo === 1) ? <td data-label="DURAÇÃO" className='center duracao'><div className='table-content-wrapper'><FontAwesomeIcon icon={faClock} /> <span>{aula.duracao}</span></div></td> : <td data-label="DURAÇÃO" className='center'><div className='table-content-wrapper'><FontAwesomeIcon icon={faClock} /> PDF</div></td>}

                                                                    {returnAulaTipo(aula.tipo, aula.controle)}

                                                                    {(modulosAulas[index - 1] === undefined || 
                                                                    modulosAulas[index - 2] === undefined || 
                                                                    modulosAulas[index - 1].modulo_id !== aula.modulo_id || 
                                                                    modulosAulas[index - 2].modulo_id !== aula.modulo_id) ? 
                                                                    <td data-label="STATUS">
                                                                        <div className='table-content-wrapper'><p className='amostra' onClick={() => handleClick(aula.link)}><span className='amostra-icon'><FontAwesomeIcon icon={faCirclePlay} color='#27921A' /></span> <span className='amostra-btn'>GRÁTIS</span></p></div>
                                                                        <div className={(clicked && videoId === aula.link) ? 'background-cover show' : 'background-cover'}>
                                                                            <div className={(clicked && videoId === aula.link) ? 'amostra-video show' : 'amostra-video'}>
                                                                                <button onClick={() => handleClick(aula.link)} className='close'><FontAwesomeIcon icon={faXmark} /></button>
                                                                                <iframe title='video' src={`https://player.vimeo.com/video/${aula.link}?controls=1`} allowFullScreen ></iframe>
                                                                            </div>
                                                                        </div>
                                                                    </td> : 
                                                                    <td data-label="STATUS">
                                                                        <div className='table-content-wrapper'><FontAwesomeIcon icon={faLock} /> Nutror</div>
                                                                    </td>}
                                                                </tr>
                                                            </React.Fragment>
                                                        )
                                                    }
                                                    return null;
                                                })}
                                                <tr className='space'></tr>
                                            </tbody> 
                                        )
                                    }
                                    return null;
                                })}
                            </table>
                        </div>}
                    </Modulos>
                </ConcursoInfo>}
                <Relacoes>
                    <CardPlanoAnual />
                    {cursoRelacoes && <CursosRelacionados>
                        <div className='titulo'>CURSOS RELACIONADOS</div>
                        <div className='conteudo'>
                            {cursoRelacoes.map((curso) => {
                                return (
                                    <React.Fragment key={curso.id}>
                                        <div className='curso' key={curso.id}>
                                            <a href={`/cursos/${curso.id}`}><img src={`/assets/images/courses/${curso.id}_vitrine.jpg`} alt='imagem do curso relacionad'></img></a>
                                            <div className='relacoesInfo'>
                                                <a href={`/cursos/${curso.id}`}><h4>{`CR-${curso.id}`}</h4></a>
                                                <p>{curso.nome}</p>
                                            </div>
                                        </div>
                                        <div className='rule'></div>
                                    </React.Fragment>
                                )
                            })}
                        </div>
                    </CursosRelacionados>}
                </Relacoes>
            </Wrapper>
        </CursoDetalhesStyle>}
        </>
    )
}

export default CursoDetalhes;