import styled from 'styled-components';

export const IrAoTopoWidgetStyle = styled.div`
    
    width: 45px;
    height: 45px;
    background-color: #27921A;
    border-radius: 5px;
    color: white;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: fixed;
    right: 50px;
    bottom: 50px;

    @media (max-width: 700px) {
        width: 40px;
        height: 40px;
        font-size: 18px;
        right: 20px;
        bottom: 20px;
    }

`;