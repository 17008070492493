import styled from 'styled-components';

export const InstrucaoNutrorStyle = styled.div `

    display: flex;
    align-items: center;

    img {
        width: 50px; 
        height: 50px;
        margin-right: 15px;
    }

    h3 {
        margin: 0;
        font-size: 18px;
        font-weight: 500;
        color: #27921A;
    }

    .info-txt {
        margin: 3px 0 0 0;
        color: #3C763D;
        background-color: #DFF0D8;
        font-size: 12px;
        padding: 4px;
        border-radius: 4px;
    }

`;