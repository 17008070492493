import styled from 'styled-components';

export const ConcursosDeTiStyle = styled.div`

    .cards {
        display: flex;
        flex-flow: row wrap;
        padding: 50px 0;
        justify-content: center;
        width: 1144px;
        margin: auto;

        @media (max-width: 1150px) {
            padding: 50px 5%;
            width: auto;
        }
    }



`;