import { CardCursoStyle } from './styles/cardCurso.style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faStar } from '@fortawesome/free-solid-svg-icons';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';

interface CursoInfo {
    id: string,
    nome_curto: string,
    descricao: string,
    aulas_count: number,
    data: string,
    cardStyle: string,
    destaque: boolean
}

const CardCurso = (props: CursoInfo) => {

    const createMarkup = () => {
        return {__html: props.descricao};
    }

    return (
        <CardCursoStyle colWidth={props.cardStyle}>
            <div className={(props.cardStyle === '1col') ? 'wrapper col1' : 'wrapper'}>
                <div className='background'>
                    <div className='info'>
                        {props.destaque ? <><img className='novo-img' src='/assets/images/new-green.gif' alt='novo'></img></> : <></>}
                        <p><a href={`/cursos/${props.id}`}>{props.nome_curto}</a></p>
                        {props.destaque ? <><span className='info-span'><p><FontAwesomeIcon icon={faList} /> {props.aulas_count}</p></span> <span className='info-span'><p><FontAwesomeIcon icon={faCalendar} /> {props.data}</p></span></> : <p><FontAwesomeIcon icon={faList} /> {props.aulas_count} aulas</p>}
                        {props.destaque ? <></> : <span><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /></span>}
                        <div className='description' dangerouslySetInnerHTML={createMarkup()} />
                    </div>
                    <div className='image'>
                        <img className='curso-img' src={`/assets/images/courses/${props.id}_vitrine.jpg`} alt='imagem do curso'></img>
                    </div>
                    <a href={`/cursos/${props.id}`}><button>{props.destaque ? <span className='btn-span'>AMOSTRAS E DETALHES</span> : <span className='btn-span'>MAIS DETALHES</span>}</button></a>
                </div>
            </div>
        </CardCursoStyle>
    )
}

export default CardCurso;