import styled from "styled-components";

export const BannerHomePageStyle = styled.div`
  text-align: center;
`;

export const Background = styled.div`
  width: 100%;
`;

export const Carrossel = styled.div`
  width: 100%;
  heigth: 1050px;
  position: relative;

  input {
    visibility: hidden;
    position: relative;
    top: 50%;
    margin: 8px;

    &:after {
      content: "";
      position: absolute;
      background-color: transparent;
      width: 10px;
      height: 10px;
      visibility: visible;
      border-radius: 50%;
      border: 2px solid #ccc;
    }

    &:checked:after {
      background-color: #27921a;
      border: 2px solid #27921a;
    }

    &:hover {
      cursor: pointer;
    }
  }

  @media (max-width: 4000px) {
    height: 1250px;
  }

  @media (max-width: 3700px) {
    height: 1200px;
  }

  @media (max-width: 3000px) {
    height: 1100px;
  }

  @media (max-width: 2720px) {
    height: 950px;
  }

  @media (max-width: 2560px) {
    height: 900px;
  }

  @media (max-width: 2400px) {
    height: 850px;
  }

  @media (max-width: 2300px) {
    height: 800px;
  }

  @media (max-width: 2200px) {
    height: 700px;
  }

  @media (max-width: 2100px) {
    height: 650px;
  }

  @media (max-width: 1920px) {
    height: 620px;
  }

  @media (max-width: 1720px) {
    height: 550px;
  }

  @media (max-width: 1560px) {
    height: 500px;
  }

  @media (max-width: 1280px) {
    height: 450px;
  }

  @media (max-width: 1024px) {
    height: 360px;
  }

  @media (max-width: 912px) {
    height: 310px;
  }

  @media (max-width: 820px) {
    height: 270px;
  }

  @media (max-width: 780px) {
    height: 220px;
  }

  @media (max-width: 540px) {
    height: 180px;
  }

  @media (max-width: 414px) {
    height: 120px;
  }

  @media (max-width: 390px) {
    height: 110px;
  }

  @media (max-width: 375px) {
    height: 110px;
  }

  @media (max-width: 360px) {
    height: 110px;
  }

  @media (max-width: 280px) {
    height: 74px;
  }
`;

export const Slide = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: opacity 1s;

  &.slide-mentorias {
    opacity: 0;
    pointer-events: none;

    img {
      width: 100%;

      .input-group {
        width: 125px;
        height: 50px;
        position: absolute;
        bottom: 0px;
        left: 50%;
        margin-left: -50px;
        z-index: 15;

        &:checked:after {
          background-color: #27921a;
          border: 2px solid #27921a;
        }
      }
    }
  }

  &.slide-promo {
    opacity: 0;
    pointer-events: none;

    img {
      width: 100%;

      .input-group {
        width: 125px;
        height: 50px;
        position: absolute;
        bottom: 0px;
        left: 50%;
        margin-left: -50px;
        z-index: 15;

        &:checked:after {
          background-color: #27921a;
          border: 2px solid #27921a;
        }
      }
    }
  }

  &.slide-atualizacoes {
    opacity: 0;
    pointer-events: none;

    img {
      width: 100%;

      .input-group {
        width: 125px;
        height: 50px;
        position: absolute;
        bottom: 0px;
        left: 50%;
        margin-left: -50px;
        z-index: 15;

        &:checked:after {
          background-color: #27921a;
          border: 2px solid #27921a;
        }
      }
    }
  }

  &.slide-unica-assinatura {
    opacity: 0;
    pointer-events: none;

    img {
      width: 100%;

      .input-group {
        width: 125px;
        height: 50px;
        position: absolute;
        bottom: 0px;
        left: 50%;
        margin-left: -50px;
        z-index: 15;

        &:checked:after {
          background-color: #27921a;
          border: 2px solid #27921a;
        }
      }
    }
  }
`;

export const Info = styled.div`
  text-align: left;

  &.info-5 {
    position: relative;
    padding: 22% 0 0 44%;
    margin-top: -30%;
  }

  &.info-0 {
    position: relative;
    padding: 22% 0 0 44%;
    margin-top: -30%;
  }

  &.info-1 {
    position: relative;
    padding: 18% 0 0 20%;
    margin-top: -35%;
    color: #d9d9d9;
  }

  &.info-2 {
    position: relative;
    padding: 8% 0 0 2%;
    margin-top: -30%;
  }

  &.info-3 {
    position: relative;
    padding: 6% 0 0 2%;
    margin-top: -30%;
  }

  @media (max-width: 1124px) {
    &.info-0 {
      padding: 20% 0 0 42%;
    }
  }

  @media (max-width: 540px) {
    &.info-0 {
      padding: 12% 0 0 36%;
    }

    &.info-1 {
      text-align: center;
      padding: 0;
      margin-top: -40%;
    }

    &.info-2 {
      text-align: center;
      padding: 0;
    }

    &.info-3 {
      text-align: center;
      padding: 0;
    }
  }

  @media (max-width: 414px) {
    &.info-0 {
      padding: 10% 0 0 28%;
    }
  }
`;

export const Supertitle = styled.h1`
  font-size: 60px;
  font-weight: 800;
  margin: 0;

  &.title-3 {
    color: #242c42;
  }

  @media (max-width: 1720px) {
    font-size: 50px;
  }

  @media (max-width: 1280px) {
    font-size: 40px;
  }

  @media (max-width: 820px) {
    font-size: 30px;
  }

  @media (max-width: 540px) {
    font-size: 25px;
  }

  @media (max-width: 390px) {
    font-size: 20px;
  }

  @media (max-width: 360px) {
    font-size: 10px;
  }
`;

export const Title = styled.h2`
  font-size: 60px;
  font-weight: 800;
  margin: 0;

  &.title-0 {
    color: #000000;
    font-size: 64px;
  }

  &.title-3 {
    color: #242c42;
  }

  @media (max-width: 1720px) {
    font-size: 50px;
  }

  @media (max-width: 1280px) {
    font-size: 40px;
  }

  @media (max-width: 820px) {
    font-size: 30px;
  }

  @media (max-width: 540px) {
    font-size: 25px;
  }

  @media (max-width: 390px) {
    font-size: 20px;
  }

  @media (max-width: 360px) {
    font-size: 10px;
  }
`;

export const Subtitle = styled.h2`
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 0;

  &.sub-0 {
    color: #000000;
    font-size: 40px;
  }

  &.sub-3 {
    color: #242c42;
  }

  @media (max-width: 1720px) {
    font-size: 25px;
  }

  @media (max-width: 1280px) {
    font-size: 22px;
  }

  @media (max-width: 820px) {
    font-size: 20px;
  }

  @media (max-width: 780px) {
    display: none;
  }
`;

export const Description = styled.p`
  font-size: 20px;
  font-weight: 700;
  margin-top: 0;

  &.desc-0 {
    color: #000000;
    font-size: 20px;
  }

  &.desc-3 {
    color: #242c42;
  }

  @media (max-width: 1120px) {
    &.desc-1 {
      display: none;
    }
  }

  @media (max-width: 820px) {
    display: none;
  }
`;

export const Button = styled.button`
  position: relative;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  padding: 12px 16px;
  border: none;
  border-radius: 3px;
  transition: all 0.5s ease;
  z-index: 1;

  &.btn-0 {
    background-color: transparent;
    border: 2px solid #27921a;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: #27921a;
      z-index: -1;
      transition: all 0.5s ease;
    }

    &:hover:after {
      width: 0;
    }

    &:hover {
      color: #27921a;
    }
  }

  &.btn-1 {
    background-color: transparent;
    border: 2px solid #27921a;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: #27921a;
      z-index: -1;
      transition: all 0.5s ease;
    }

    &:hover:after {
      width: 0;
    }

    &:hover {
      color: #27921a;
    }
  }

  &.btn-2 {
    background-color: #000;
    border: 2px solid #000;

    &:hover {
      background-color: #fff;
      color: #000;
    }
  }

  &.btn-3 {
    background-color: transparent;
    border: 2px solid #27921a;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: #27921a;
      z-index: -1;
      transition: all 0.5s ease;
    }

    &:hover:after {
      width: 0;
    }

    &:hover {
      color: #27921a;
    }
  }

  &.btn-5 {
    background-color: transparent;
    border: 2px solid #edbd01;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: #edbd01;
      z-index: -1;
      transition: all 0.5s ease;
    }

    &:hover:after {
      width: 0;
    }

    &:hover {
      color: #edbd01;
    }
  }

  &:hover {
    cursor: pointer;
  }
`;

export const TrackerBtn = styled.div`
  position: absolute;
  bottom: 35px;
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: transparent;
  border: 2px solid #ccc;
  border-radius: 50%;
  margin: 3px;

  &:hover {
    cursor: pointer;
  }
`;
