import styled from 'styled-components';

export const PlanoDeEstudoStyle = styled.div`

    

`;

export const Wrapper = styled.div`

    width: 1150px;
    padding: 50px 0;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    color: #49575F;

    @media (max-width: 1200px) {
        width: auto;
        padding: 50px 5%;
    }

    @media (max-width: 950px) {
        justify-content: center;
        padding: 50px 3%;
    }

`;

export const ConcursoInfo = styled.div`

    width: 850px;
    margin-right: 20px;

    h1 {
        text-transform: uppercase;
        text-align: left;
        font-size: 36px;
        font-weight: 900;
        margin: 15px 0 5px 0;
    }

    span {
        font-size: 14px;
        
        &:hover {
            color: #27921A;
            text-decoration: underline #27921A;
        }
    }

    .texto {
        color: #6F8190;
        margin: 35px 0 30px 0;
        font-size: 18px;
        text-align: justify;
    }

    img {
        width: 100%;
    }

    .rule {
        width: 100%;
        height: 1px;
        background-color: #27921A;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    @media (max-width: 1200px) {
        width: 650px;
    }

    @media (max-width: 1050px) {
        width: 550px;
    }

    @media (max-width: 850px) {
        margin-right: 0;

        h1 {
            font-size: 30px;
        }
    }

    @media (max-width: 450px) {
        margin-right: 0;

        h1 {
            font-size: 26px;
        }

        .texto {
            font-size: 16px;
        }
    }

`;

export const Detalhes = styled.div`

    display: flex;
    justify-content: space-between;

    h3 {
        color: #F00;
    }

    p {
        color: #6F8190;
        font-size: 15px;
        margin: 8px 0;
    }

    @media (max-width: 700px) {
        display: block;

        div {
            margin-bottom: 40px;
        }
    }

`;

export const Relacoes = styled.div`



`;

export const ConcursosRelacionados = styled.div`

    .titulo {
        width: 262.5px;
        height: 20px;
        padding: 15px 0;
        background-color: #242C42;
        font-weight: bold;
        font-size: 15px;
        color: #FFF;
        text-align: center;
        margin-top: 30px;
    }

    .conteudo {

        .concurso {
            height: fit-content;
            width: 262.5px;
            display: flex;
            align-items: flex-start;
            margin-top: 20px;
        }

        img {
            width: 100px;
        }

        .relacoesInfo {
            padding-left: 10px;
        }

        a {
            font-size: 14px;
            color: #6F8190;
            text-decoration: none;
            width: fit-content;

            h4 {
                width: fit-content;
                margin: 0;
            }

            :hover {
                text-decoration: underline #27921A;
                color: #27921A;
            }
        }

        p {
            font-size: 13px;
            color: #6F8190;
            margin: 3px 0 0 0;
        }

        .rule {
            width: 100%;
            height: 1px;
            background-color: #DDD;
            margin-top: 25px;
        }
        
    }

    iframe {
        border: none;
        margin-top: 15px;
    }

`;

export const PlanoDeEstudoEdital = styled.div`

    .titulo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 40px;
    }
   
    .edital-materia {
        margin: 10px 0;
    }

    input {
        position: absolute;
        opacity: 0;
    }

    label {
        cursor: pointer;
    }

    .materia-txt {
        display: inline-block;
        margin: 0;
        font-size: 16px;
        color: #6F8190;
        font-weight: 500;
        cursor: pointer;

        &:hover {
            text-decoration: underline #27921A;
            color: #27921A;
        }
    }

    .dados-edital {
        max-height: 0;
        margin: 0;
        padding-left: 20px;
        overflow: hidden;
        transition: max-height 0.3s 0s, margin 0.15s 0s;
    }

    input:checked {

        ~ .dados-edital {
            max-height: 100vh;
            margin: 15px 0 0 0;
        }

        ~ label>.materia-txt {
            text-decoration: underline #27921A;
            color: #27921A;
        }

    }

    .plano {
        color: #6F8190;
        margin: 0 0 7px 0;
        font-size: 15px;
        text-align: justify;
        display: inline-block;
    }

    .plano-align {
        text-align: left;
    }

    .modulo {
        display: inline-block;
        margin: 0;
        padding: 0 7px;
        position: relative;
        overflow: visible;
    }

    .modulo-alerta {
        display: block;
        margin: 5px 0 10px 0;
    }

    .icon-link-nutror {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin: 0;
        padding: 0 2px;
        position: relative;
        top: 5px;
    }

    .modulo-txt {
        display: inline-block;
        margin: 0;
        
        a {
            text-decoration: none;
            color: #6F8190;
            font-size: 15px;

            &:hover {
                text-decoration: underline #27921A;
                color: #27921A;
            }
        }
    }

    .info-icon {
        margin-left: 2px;
        position: relative;
        top: 2px;
    }
    
    .info-slide {
        margin-left: 2px;
        position: relative;
        top: 2px;
    }

    .face-icon {
        display: inline-block;
        padding: 0 5px 0 0;
    }

    .alerta {
        color: #CCC;
    }

    .plano-wrapper {
        display: inline-block;
    }

    .more-info {
        background-color: #27921A;
        border-radius: 5px;
        padding: 6px 12px;
        position: absolute;
        transform: translate(25px, -65px);
        display: none;
    }

    .more-info-txt {
        color: #FFF;
        padding: 0;
        margin: 0;
        font-size: 12px;
        font-weight: 600;
    }
 
    .visible {
        display: block;
    }

    .icon-wrapper {
        display: inline-block;
    }

    @media (max-width: 1200px) {
        .titulo {
            display: block;
            margin-bottom: 40px;
        }

        img {
            width: 30px; 
            height: 30px;
        }

        h3 {
            font-size: 15px;
        }

        .info-txt {
            font-size: 9px;
            padding: 3px;
        }
    }

    @media (max-width: 900px) {

        .plano {
            margin-bottom: 20px;
        }

        .modulo-txt {
            a {
                font-size: 13px;
            }
        }
    }

`;

export const FraseCardInformativo = styled.div`

    .frase-wrapper {
        border-left: 5px solid #EEE;
        padding: 5px 10px;
        margin: 50px 0;
        position: relative;
    }

    .quote-icon {
        display: inline-block;
        font-size: 40px;
        padding: 5px 15px 5px 5px;
        color: #90A1AE;
        position: absolute;
        top: 20px;
    }

    .frase {
        display: inline-block;
        margin: 40px 0 10px 0;
        padding-left: 50px;
        font-size: 25px;
        font-style: italic;
        color: #6F8190;
        text-align: justify;
    }

    .autor-info {
        text-align: right;
        margin: 0;
        color: #777;
        font-weight: bold;

        small {
            font-weight: 600;
        }
    }

    .card-info {
        background-color: #FCF8E3;
        color: #8A6D3B;
        border: 1px solid #FAEBCC;
        border-radius: 4px;
        padding: 25px;
        text-align: justify;
        font-size: 18px;
        margin: 60px 0 40px 0;
    }

    .sem-edital {
        width: 70%;
        margin: 70px 0 80px 0;
    }

    @media (max-width: 700px) {
        .frase {
            font-size: 22px;
        }
    }

`;