import { useRef, useEffect, useState } from 'react';
import { BannerNumerosStyle, Background, Title, Info, Num, Number, Wrapper, Link, Button } from './styles/bannerNumeros.style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import numeros from '../dados/numeros.json';

interface BannerNumerosProps {
    background: boolean,
    info: boolean
}

const BannerNumeros = (props: BannerNumerosProps) => {

    const numberRef1 = useRef<HTMLDivElement | null>(null);
    const numberRef2 = useRef<HTMLDivElement | null>(null);
    const numberRef3 = useRef<HTMLDivElement | null>(null);
    const [number1, setNumber1] = useState<HTMLDivElement>();
    const [number2, setNumber2] = useState<HTMLDivElement>();
    const [number3, setNumber3] = useState<HTMLDivElement>();

    useEffect(() => {

        if (numberRef1.current) {
            setNumber1(numberRef1.current);
        }

        if (numberRef2.current) {
            setNumber2(numberRef2.current);
        }

        if (numberRef3.current) {
            setNumber3(numberRef3.current);
        }

    }, [numberRef1, numberRef2, numberRef3]);

    const elementInView = (number1: HTMLDivElement | undefined) => {
        const elementTop = number1!.getBoundingClientRect().top;

        return (
            elementTop <= (window.innerHeight || document.documentElement.clientHeight)
        )
    }

    let scrolled1 = false;
    let scrolled2 = false;
    let scrolled3 = false;

    const handleScrollNumberAnimation = () => {

        let interval = 3000;

        if (number1 && elementInView(number1) && scrolled1 === false) {
            scrolled1 = true;
            let startValue = 0;
            let endValue = parseInt(number1.innerText);
            let duration = Math.floor(interval / endValue);
            let counter = setInterval(function () {
                startValue++;
                number1.textContent = startValue.toString();
                if (startValue === endValue) {
                    clearInterval(counter);
                }
            }, duration);
        }

        if (number2 && elementInView(number1) && scrolled2 === false) {
            scrolled2 = true;
            let startValue = 0;
            let endValue = parseInt(number2.innerHTML);
            let duration = Math.floor(interval / endValue);
            let counter = setInterval(function () {
                startValue++;
                number2.textContent = startValue.toString();
                if (startValue === endValue) {
                    clearInterval(counter);
                }
            }, duration);
        }

        if (number3 && elementInView(number1) && scrolled3 === false) {
            scrolled3 = true;
            let startValue = 0;
            let endValue = parseInt(number3.innerHTML);
            let duration = Math.floor(interval / endValue);
            let counter = setInterval(function () {
                startValue = startValue + 100;
                number3.textContent = startValue.toString();
                if (startValue === endValue) {
                    clearInterval(counter);
                }
            }, duration);
        }
    }
    
    window.addEventListener('scroll', () => {
        handleScrollNumberAnimation();
    })

    return (
        <BannerNumerosStyle>
            <Background id='banner-numero-background' className={props.background ? '' : 'no-background'}>
                <Wrapper>
                    {props.info ? <Title>ALGUNS NÚMEROS ATUAIS</Title> : null}
                    <Info className={props.background ? '' : 'no-background-info'}>
                        <Num><FontAwesomeIcon icon={faCheck}/><Number ref={numberRef1}>{numeros.numero_cursos}</Number><Link href='/'>Número De Cursos</Link></Num>
                        <Num><FontAwesomeIcon icon={faCheck}/><Number ref={numberRef2}>{numeros.numero_modulos}</Number><Link href='/'>Número De Módulos</Link></Num>
                        <Num><FontAwesomeIcon icon={faCheck}/><Number ref={numberRef3}>{numeros.numero_alunos}</Number><Link href='/'>Número de Alunos</Link></Num>
                    </Info>
                    {props.info ? <Button><a href='/planosdeassinatura'>VER PLANOS</a></Button> : null}
                    {props.info ? <Button><a href='/sobre'>HISTÓRIA</a></Button> : null}
                </Wrapper>
            </Background>
        </BannerNumerosStyle>
    )

}

export default BannerNumeros;