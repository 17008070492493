import TituloPagina from '../componentes/tituloPagina';
import CardCurso from '../componentes/cardCurso';
import { API_URL } from '../config';
import BuscaDinamica from '../componentes/buscaDinamica';
import { ListagemCursosStyle } from './styles/ListagemCursos.style';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import CarregamentoWidget from '../componentes/carregamentoWidget';


const CursosPorCategoria = () => {

    interface Cursos {
        id: string,
        nome_curto: string,
        descricao: string,
        aulas_count: number
    }

    interface Categoria {
        nome: string,
        curso_count: number
    }

    const { categoriaId } = useParams();
    const [cursos, setCursos] = useState<Cursos[]>();
    const [categoria, setCategoria] = useState<Categoria>();
    const [cardStyle, setCardStyle] = useState<string>();
    const [termoBusca, setTermoBusca] = useState<string>('');

    const callback = (style: string) => {
        setCardStyle(style);
    }

    useEffect(() => {
        
        const getCursosPorCategoria = async () => {
            const response = await axios.get(`${API_URL}/api/cursos/get/cursosPorCategoria`, {
                params: {
                    id: categoriaId
                }
            })
            setCursos(response.data.results);
        }

        getCursosPorCategoria();

        const getCategoriaPorId = async () => {
            const response = await axios.get(`${API_URL}/api/cursos/get/categoriaPorId`, {
                params: {
                    id: categoriaId
                }
            })
            setCategoria(response.data.results[0]);
        }

        getCategoriaPorId();

    }, [categoriaId]);

    const getTermoBusca = (termoBusca: string) => {
        setTermoBusca(termoBusca);
    }

    return (
        <>
        {!cursos && !categoria ? <CarregamentoWidget /> :
        <ListagemCursosStyle>
            {categoria &&
            <TituloPagina h1ClassName=''
                          titulo={`Categoria ${categoria.nome}`}
                          link='Cursos'
                          info={`Há um total de ${categoria.curso_count} cursos de TI para concursos nesta categoria.`}
                          hiddenClassName=''
                          getStyle={callback}
            />}

            <BuscaDinamica getTermoBusca={getTermoBusca} 
                           objetoDeBusca='assunto, disciplina etc.'
            />
            
            <div className='cards'>
                {cursos && cursos.filter((value) => {
                    if (termoBusca === '' || termoBusca === undefined) {
                        return value;
                    } else if (value.nome_curto.toLowerCase().includes(termoBusca.toLowerCase()) || value.descricao.toLowerCase().includes(termoBusca.toLowerCase())) {
                        return value;
                    } else {
                        return null;
                    }
                }).map((curso) => {
                    return (
                        <React.Fragment key={curso.id}>
                            <CardCurso id={curso.id}
                                    nome_curto={curso.nome_curto}
                                    descricao={curso.descricao}
                                    aulas_count={curso.aulas_count}
                                    cardStyle={`${cardStyle}`}
                                    data={''}
                                    destaque={false}
                            />
                        </React.Fragment>
                    )
                })}
            </div>
        </ListagemCursosStyle>}
        </>
    )
}

export default CursosPorCategoria;