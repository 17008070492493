import { IrAoTopoWidgetStyle } from './styles/irAoTopoWidget.style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesUp } from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect } from 'react';

const IrAoTopoWidget = () => {

    const [isVisible, setIsVisible] = useState<boolean>(false);

    useEffect(() => {
        window.addEventListener("scroll", listenToScroll);

        return () => window.removeEventListener("scroll", listenToScroll);
    });

    const listenToScroll = () => {
        let heightToShowFrom = 700;

        const heightScrolled = document.body.scrollTop || document.documentElement.scrollTop;

        if (heightScrolled > heightToShowFrom) {
            setIsVisible(true);
        }
        else {
            setIsVisible(false);
        }
    };

    return (
        <>
        {isVisible && <IrAoTopoWidgetStyle onClick={() => {window.scrollTo({top: 0, behavior: "smooth"})}}><FontAwesomeIcon icon={faAnglesUp} /></IrAoTopoWidgetStyle>}
        </>
    )
}

export default IrAoTopoWidget;