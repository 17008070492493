import styled from 'styled-components';

export const BannerNumerosStyle = styled.div`

    text-align: center;
    margin: 0;

    .no-background {
        background-image: none;
        background-color: #121828;
    }

`;

export const Background = styled.div`

    position: relative;
    width: 100%;
    background-image: url(https://www.provasdeti.com.br/assets/images/background-count-number.png);
    background-repeat: repeat;
    background-position: center;
    color: #FFF;
    z-index: 0;

    @media (max-width: 800px) {
        background-position: 20% 0;
    }

`;

export const Wrapper = styled.div`

    position: relative;
    padding: 25px 20%;
    z-index: 0;

    .no-background-info {
        margin: 20px 0;
    }

    @media (max-width: 1200px) {
        padding: 25px 10%;
    }

    @media (max-width: 800px) {
        padding: 25px 5%;
    }

`;

export const Title = styled.p`

    font-size: 40px;
    font-weight: 700;

`;

export const Info = styled.div`

    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    margin-top: 60px;

`;

export const Num = styled.div`

    font-size: 48px;
    font-weight: 800;
    display: flex;
    flex-direction: column;
    margin: 15px 0;

`;

export const Number = styled.div`



`;

export const Link = styled.a`

    font-size: 18px;
    font-weight: 400;
    text-decoration: none;
    color: #FFF;
    margin: 8px;

    &:hover {
        text-decoration: underline #FFF;
    }

`;

export const Button = styled.button`

    position: relative;
    padding: 15px 12px;
    background-color: transparent;
    border-radius: 4px;
    border: 2px solid #27921A;
    margin: 70px 15px 20px 15px;
    transition: all 0.3s ease;
    

    &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: #27921A;
        z-index: -1;
        transition: all 0.5s ease;
    }

    a {
        font-size: 14px;
        color: #FFF;
        z-index: 3;
        text-decoration: none;
        transition: all 0.3s ease;
    }

    &:hover {
        cursor: pointer;

        &:after {
            width: 0;
        }

        a {
            color: #27921A;
        }
    }

`;