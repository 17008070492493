import styled from 'styled-components';

export const HeaderInfo = styled.header`

    width: 100%;

    .header-info {
        background-color: #242C42;
        color: #FFF;
        font-size: 12px;
        padding: 18px 20% 18px 20%;
        margin: auto;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
    }

    .link {
        text-decoration: none;
        color: inherit;

        :hover {
            text-decoration: underline #27921A;
            color: #27921A;
        }
    }

    .contato {
        a {
            padding: 0 12px 0 12px;

            span {
                padding-left: 7px;
            }

            :hover {
                text-decoration: underline #27921A;
                color: #27921A;
            }
        }
    }

    .sociais {

        a {
            padding: 0 15px 0 15px;
        }

        .icon:hover {
            color: #27921A;
        }
    }

    @media (max-width: 1850px) {
        .header-info {
            padding: 18px 15%;
        }
    }

    @media (max-width: 1550px) {
        .header-info {
            padding: 18px 10%;
        }
    }

    @media (max-width: 1360px) {
        .header-info {
            padding: 18px 5%;
        }
    }

    @media (max-width: 1030px) {
        .sociais {
            display: none;
        }
    }

    @media (max-width: 720px) {
        .header-info {
            padding: 18px 2%;
            font-size: 11px;
        }

        .contato {
            a {
                padding: 0 3px;

                span {
                    padding-left: 3px;
                }
            }

            .link-left {
                padding-left: 0;
            }

            .contato-icon {
                font-size: 14px;
            }
        }
    }
    
`;

export const HeaderMenu = styled.header`

    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;

    .header-navigation {
        background-color: #FFF;
        color: #000;
        font-size: 14px;
        padding: 0 20% 0 20%;
        margin: auto;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        box-shadow: 3px 0 7px rgb(0 0 0 / 30%);
    }

    .shrink {
        .logo {
            margin: 10px 0;

            img {
                width: 120px;
            }
            
        }

        .nav-list {
            li {
                height: 50px;
            }
        }
    }

    .logo {
        position: relative;
        margin: 25px 0 25px 0;

        img {
            width: 220px;
        }

        .anos {
            height: 100%;
            width: 100px;
            object-fit: cover;
            object-position: 0;
        }
        
        /* a {
            position: relative;
        }

        .anos-count {
            position: absolute;
            bottom: 25px;
            left: 38px;
            text-decoration: none;
            color: #FDBE00;
            background-color: #FFF;
            width: 29px;
            height: 38px;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            font-size: 35px;
            font-weight: 600;
            transform: scale(1, 1.30);
        } */
    }

    
    .navbar {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .nav-list {
        display: flex;
        flex-flow: row;
        list-style-type: none;
        margin: 0;
        padding: 0;
        height: 100%;
        align-items: center;
        position: relative;
        
        a {
            height: 100%;
            text-decoration: none;
            color: inherit;
        }

        li {
            display: flex;
            padding: 0 20px 0 20px;
            font-size: 14px;
            height: 100px;
            align-items: center;
            position: relative;

            @keyframes border {
                from {border-bottom: none;}
                to {border-bottom: solid 5px #27921a;}
            }

            :hover {
                cursor: pointer;
            }
        }

        .nav-animation {
            height: 100%;
        }

        .nav-animation::after {
            content: '';
            position: absolute;
            width: 0;
            height: 5px;
            left: 0;
            bottom: 0;
            background-color: #27921A;
            transition: all 0.35s ease;
        }

        .nav-animation:hover::after {
            width: 100%;
        }

        .home {
            height: 100%;
            font-weight: bold;
            color: #27921A;
        }

        .home::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 5px;
            left: 0;
            bottom: 0;
            background-color: #27921A;
        }

        .search {
            &:hover {
                border-bottom: none;
                color: #27921A;
            }
        }

        .dropdown-icon {
            padding-left: 8px;
        }

        .dropdown-menu {
            position: absolute;
            background-color: #FFF;
            top: 100%;
            z-index: 10;
            left: -450%;
            display: none;

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                height: 5px;
                width: 100%;
                background-color: #27921A;
            }
        }

        .dropdown-menu-search {
            position: absolute;
            background-color: #FFF;
            top: 100%;
            z-index: 10;
            left: -450%;
            display: none;
            padding: 15px 30px;

            &:hover {
                cursor: default;
            }

            .form {
                border: solid 1px #CCC;
            }

            input {
                width: 200px;
                height: 25px;
                padding: 5px 18px;
                border: none;
                font-size: 15px;
            }

            button {
                height: 35px;
                width: 50px;
                background-color: #FFF;
                border: none;

                &:hover {
                    cursor: pointer;
                    color: #27921A;
                }
            }
            
        }

        .search {
            padding: 0;
        }

        .icon-div {
            width: 60px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .visible {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            min-width: max-content;
        }

        .menu-flex-item {
            flex-shrink: 0;
            margin: 20px;
            width: max-content;
        }

        .hover {

            width: 95%;
            padding: 8px;
            margin: 0;

            :hover {
                cursor: pointer;
                background-color: #DDD;
            }
        }

        .categoria {
            text-transform: uppercase;
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 10px;
        }

        .plano {
           
            padding: 10px 0;
            margin: 0 ;
            width: 100%;

            &:hover {
                cursor: pointer;
                background-color: #DDD;
            }
        }

        .plano-wrapper {
            padding: 10px;
            width: 100%;
        }

        .plano-position {
            left: 0;
        }

        .autor-position {
            left: -150%;
        }

        .search-position {
            left: -350%;
        }
    }

    .mobile-icon {
        display: none;
    }

    @media (max-width: 1850px) {
        .header-navigation {
            padding: 0 15%;
        }
    }

    @media (max-width: 1550px) {
        .header-navigation {
            padding: 0 10%;
        }
    }

    @media (max-width: 1360px) {
        .header-navigation {
            padding: 0 5%;
        }

        .logo {
            img {
                width: 170px;
            }
            
            .anos {
                height: 82%;
                width: 100px;
                object-fit: cover;
                object-position: 0;
            }
        }

        .nav-list {
            li {
                padding: 0 15px 0 15px;
                font-size: 13px;
            }

            .menu-flex-item {
                margin: 10px;
            }

            .plano-position {
                left: 0;
            }

            .autor-position {
                left: -150%;
            }

            .search-position {
                left: -450%;
            }

            .aulas-position {
                left: -400%;
            }
        }
        
    }

    @media (max-width: 1030px) {

        .logo {

            margin: 20px 0;

            img {
                width: 150px;
            }

            .anos {
                height: 82%;
                width: 90px;
                object-fit: cover;
                object-position: 0;
            }
        }

        .nav-list {
            li {
                padding: 0 10px 0 10px;
                font-size: 11px;
                height: 50px;
            }

            .menu-flex-item {
                margin: 5px;
            }

            .plano-position {
                left: 0;
            }

            .autor-position {
                left: -150%;
            }

            .search-position {
                left: -450%;
            }

            .aulas-position {
                left: -500%;
            }
        }
    }

    @media (max-width: 880px) {

        .logo {
            img {
                width: 130px;
            }

            .anos {
                height: 82%;
                width: 80px;
                object-fit: cover;
                object-position: 0;
            }
        }

        .nav-list {
            li {
                padding: 0 5px 0 5px;
                font-size: 10px;
                height: 30px;
            }

            .icon-div {
                width: 30px;
            }

            .plano-position {
                left: 0;
            }

            .autor-position {
                left: -200%;
            }

            .search-position {
                left: -750%;
            }

            .aulas-position {
                left: -590%;
            }

            .categoria {
                font-size: 14px;
            }

            .hover {
                padding: 5px 8px;
            }

            .dropdown-menu-search {
                padding: 10px 20px;

                input {
                    width: 150px;
                    height: 18px;
                    padding: 3px 13px;
                    border: none;
                    font-size: 13px;
                }
            }
        }
    }

    @media (max-width: 720px) {
        .header-navigation {
            padding: 0 1%;
        }

        .logo {

            margin: 15px 0;

            img {
                width: 100px;
            }

            .anos {
                height: 60%;
                width: 50px;
                object-fit: cover;
                object-position: 0;
            }

            .anos-count {
                bottom: 13.5px;
                left: 18px;
                width: 18px;
                height: 16px;
                font-size: 16px;
                font-weight: 600;
                transform: scale(1, 1.30);
                align-items: flex-start;
            }
        }

        .nav-list {
            display: none;
        }

        .mobile-icon {
            display: block;
        }

        .menu-toggle {
            position: absolute;
            top: 0;
            right: 0;
            opacity: 0;
        }

        .mobile-icon-label {
            font-size: 20px;
            padding-right: 15px;

            &:hover {
                cursor: pointer;
            }
        }

        .menu-toggle:checked {
            +label+.menu-mobile {
                display: block;
            }
        }

        .menu-mobile {
            display: none;
            position: absolute;
            right: 0;
            top: 100%;
            background-color: #FFF;
            padding: 15px 0;
            max-height: 65vh;
            overflow: scroll;

            a {
                text-decoration: none;
                color: inherit;
            }

            .check-input {
                position: absolute;
                opacity: 0;
            }

            .dropdown-list {
                max-height: 0;
                overflow: hidden;
                padding-left: 35px;
            }

            .disciplina-list {
                max-height: 0;
                overflow: hidden;
                padding-left: 25px;
            }

            .list-label {
                font-size: 18px;
                display: inline-block;
                padding-right: 15px;
                
                &:hover {
                    cursor: pointer;
                }
            }

            .icon-label-down {
                display: none;
            }

            h3 {
                display: inline-block;
                padding: 0;
                margin: 15px 0 0 0;
            }

            .line-block {
                display: block;
            }

            .aulas-lbl {
                display: inline-block;
                margin: 15px 0 0 10px;
            }
 
            input:checked {

                .menu-item {
                    &:before {
                        height: 100%;
                    }
                }

                +.menu-item-label {
                    color: #27921A;
                }

                +label+.dropdown-list {
                    max-height: 100vh;
                }

                +label+a+.disciplina-list {
                    max-height: 100vh;
                }

                +label {
                    .icon-label-right {
                        display: none;
                    }

                    .icon-label-down {
                        display: block;
                    }
                }
            }
        }

        .menu-item-label {
            padding: 15px 60px 15px 25px;
            width: 100%;

            &:hover {
                cursor: pointer;
            }
        }

        .menu-item {
            position: relative;
            padding: 15px 0px 15px 0px;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 0;
                width: 5px;
                background-color: #27921A;
                transition: all 0.3s;
            }

            &:hover {
                cursor: pointer;

                &:before {
                    height: 100%;
                }
            }
        }

        .search-input {
            width: 130px;
            height: 25px;
            padding: 4px 16px;
            border: solid 1px #CCC;
            font-size: 14px;
            margin: 0 0 0 25px;
        }

    }

`;