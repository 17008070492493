import { PorQueProvasStyle, Button, ProvasInfo } from './styles/porQueProvas.style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faDownLeftAndUpRightToCenter, faPaperPlane, faMagnifyingGlassPlus, faUsers, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import numeros from '../dados/numeros.json';

const PorQueProvas = () => {
    return (
        <>
            <PorQueProvasStyle>
                <div className='conteudo'>
                    <p className='titulo'>POR QUE PROVAS DE TI?</p>
                    <p>
                        O Provas de TI possui <b>{numeros.numero_anos} anos</b> de operação. Já atendemos a mais de {numeros.numero_alunos} alunos com materiais
                        da mais alta qualidade. Quando não havia iPhone, Whatsapp ou Uber, nós já estávamos aqui, produzindo
                        os melhores materiais para concursos na área de TI. Conhecemos muito o que fazemos e vamos ajudar você.
                    </p>
                    <Button><a href='/planosdeassinatura'>NOSSOS PLANOS</a></Button>
                </div>
                <img src='/assets/images/news-detail-2.jpg' alt='Provas de TI'></img>
            </PorQueProvasStyle>

            <ProvasInfo>
                <div>
                    <FontAwesomeIcon className='icon' icon={faPenToSquare} />
                    <p>Atualização de materiais</p>
                </div>

                <div>
                    <FontAwesomeIcon className='icon' icon={faDownLeftAndUpRightToCenter} />
                    <p>Ferramentas de integração</p>
                </div>

                <div>
                    <FontAwesomeIcon className='icon' icon={faPaperPlane} />
                    <p>Área de alunos renovada</p>
                </div>

                <div>
                    <FontAwesomeIcon className='icon' icon={faMagnifyingGlassPlus} />
                    <p>Dicas para poupar tempo</p>
                </div>

                <div>
                    <FontAwesomeIcon className='icon' icon={faUsers} />
                    <p>Colaboração entre alunos</p>
                </div>

                <div>
                    <FontAwesomeIcon className='icon' icon={faLocationDot} />
                    <p>Direção e foco constantes</p>
                </div>
            </ProvasInfo>
        </>
    )
}

export default PorQueProvas;