import { CardPlanoAnualStyle, Preco, Detalhes, Title, Descricao, Lista, ItemE, ItemD, Button } from './styles/cardPlanoAnual.style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import numeros from '../dados/numeros.json';


const CardPlanoAnual = () => {

    return (
        <CardPlanoAnualStyle>
            <Preco><h2><span>{numeros.preco_anual}</span></h2></Preco>
            <Detalhes>
                <Title>Plano Anual</Title>
                <Descricao><b>TODO O CONTEÚDO DO SITE +</b></Descricao>
                <Descricao>Atualizações sem custo adicional.</Descricao>
                <Lista>
                    <ItemE><FontAwesomeIcon icon={faCheck} className='icon' /> Planos por Edital:</ItemE><ItemD>{numeros.numero_planos}+</ItemD>
                    <ItemE><FontAwesomeIcon icon={faCheck} className='icon' /> Módulos:</ItemE><ItemD>{numeros.numero_modulos}+</ItemD>
                    <ItemE><FontAwesomeIcon icon={faCheck} className='icon' /> Aulas:</ItemE><ItemD>{numeros.numero_aulas}+</ItemD>
                    <ItemE><FontAwesomeIcon icon={faCheck} className='icon' /> Horas:</ItemE><ItemD>{numeros.numero_horas}+</ItemD>
                    <ItemE><FontAwesomeIcon icon={faCheck} className='icon' /> Certificado:</ItemE><ItemD>GRÁTIS</ItemD>
                </Lista>
                <a href='/planosdeassinatura'><Button><span>NOSSOS PLANOS</span></Button></a>
            </Detalhes>
        </CardPlanoAnualStyle>
    )

}

export default CardPlanoAnual;