import styled from 'styled-components';

export const ListagemCursosStyle = styled.div `

    .cards {
        display: flex;
        flex-flow: row wrap;
        padding: 50px 0;
        justify-content: space-between;
        width: 1144px;
        margin: auto;

        @media (max-width: 1200px) {
            padding: 50px 20%;
            width: auto;
        }

        @media (max-width: 800px) {
            padding: 50px 15%;
        }

        @media (max-width: 700px) {
            padding: 50px 10%;
        }

        @media (max-width: 650px) {
            padding: 50px 20%;
        }

        @media (max-width: 530px) {
            padding: 50px 25%;
        }

        @media (max-width: 400px) {
            padding: 50px 20%;
        }

        @media (max-width: 350px) {
            padding: 50px 15%;
        }
    }

`;