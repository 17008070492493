import React, { useEffect, useState } from 'react';
import { BannerHomePageStyle, Background, Carrossel, Slide, Info, Supertitle, Subtitle, Description, Button } from './styles/bannerHomePage.style';

const BannerHomePage = () => {

    const [slide, setSlide] = useState<HTMLElement | null>(document.getElementById('slide-atualizacoes'));
    const [inputValue, setInputValue] = useState<string>('slide-atualizacoes');
    const [clicked, setClicked] = useState<boolean>(false);
    let timer: any;

    useEffect(() => {
        setSlide(document.getElementById('slide-atualizacoes'));
    }, []);


    useEffect(() => {

        if (inputValue === 'slide-atualizacoes') {

            // eslint-disable-next-line react-hooks/exhaustive-deps
            timer = setTimeout(function () {
                if (inputValue === 'slide-atualizacoes' && clicked === false) {

                    setInputValue('slide-mentorias');
                    if (slide) {
                        slide.style.opacity = '0';
                        slide.style.pointerEvents = 'none';
                    }
                    setSlide(document.getElementById('slide-mentorias'));
                } else {
                    setClicked(false);
                }
            }, 4000);

        }

    }, [slide, inputValue, clicked]);


    useEffect(() => {

        if (inputValue === 'slide-mentorias') {

            // eslint-disable-next-line react-hooks/exhaustive-deps
            timer = setTimeout(function () {
                if (inputValue === 'slide-mentorias' && clicked === false) {

                    setInputValue('slide-unica-assinatura');
                    if (slide) {
                        slide.style.opacity = '0';
                        slide.style.pointerEvents = 'none';
                    }
                    setSlide(document.getElementById('slide-unica-assinatura'));
                } else {
                    setClicked(false);
                }
            }, 4000);

        }

    }, [slide, inputValue, clicked]);


    useEffect(() => {

        if (inputValue === 'slide-unica-assinatura') {

            // eslint-disable-next-line react-hooks/exhaustive-deps
            timer = setTimeout(function () {
                if (inputValue === 'slide-unica-assinatura' && clicked === false) {

                    setInputValue('slide-atualizacoes');
                    if (slide) {
                        slide.style.opacity = '0';
                        slide.style.pointerEvents = 'none';
                    }
                    setSlide(document.getElementById('slide-atualizacoes'));
                } else {
                    setClicked(false);
                }
            }, 4000);

        }

    }, [slide, inputValue, clicked]);


    useEffect(() => {

        if (slide) {
            slide.style.opacity = '1';
            slide.style.pointerEvents = 'all';
        }

    }, [slide]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
        setClicked(true);
        clearTimeout(timer);
        if (slide) {
            slide.style.opacity = '0';
            slide.style.pointerEvents = 'none';
        }
        setSlide(document.getElementById(event.target.value));
    }

    return (
        <BannerHomePageStyle>
            <Background>
                <Carrossel>

                    <a href='/atualizacoes' style={{ textDecoration: 'none' }}>
                        <Slide id='slide-atualizacoes' className='slide-atualizacoes'>
                            <img src='assets/images/banner-hp-atualizacoes.jpg' alt='Conteúdos Novos'>
                            </img>
                            <div className='input-group'>
                                <input type='radio' value={'slide-atualizacoes'} name='radio' checked={inputValue === 'slide-atualizacoes'} onChange={handleChange}></input>
                                <input type='radio' value={'slide-mentorias'} name='radio' onChange={handleChange}></input>
                                <input type='radio' value={'slide-unica-assinatura'} name='radio' onChange={handleChange}></input>
                            </div>
                            <Info className='info-0'>
                                {/*                             <Title className='title-0'>CONTEÚDO</Title>
                            <Subtitle className='sub-0'>NOVO TODO MÊS</Subtitle>
                        <Description className='desc-0'>Acesse nossa página de atualizações</Description> */}
                                <Button className='btn-0'>Ver Atualizações</Button>
                            </Info>
                        </Slide>
                    </a>

                    <a href='/mentorias' style={{ textDecoration: 'none' }}>
                        <Slide id='slide-mentorias' className='slide-mentorias'>
                            <img src='assets/images/promos/mentorias-prof-walter-cunha.jpg' alt='Mentorias Prof. Walter Cunha'>
                            </img>
                            <div className='input-group'>
                                <input type='radio' value={'slide-atualizacoes'} name='radio' onChange={handleChange}></input>
                                <input type='radio' value={'slide-mentorias'} name='radio' checked={inputValue === 'slide-mentorias'} onChange={handleChange}></input>
                                <input type='radio' value={'slide-unica-assinatura'} name='radio' onChange={handleChange}></input>
                            </div>
                            <Info className='info-5'>
                                {/*                             <Title className='title-0'>CONTEÚDO</Title>
                            <Subtitle className='sub-0'>NOVO TODO MÊS</Subtitle>
                        <Description className='desc-0'>Acesse nossa página de atualizações</Description> */}
                                <Button className='btn-5'>Turmas em Andamento!</Button>
                            </Info>
                        </Slide>
                    </a>

                    <a href='/cursos/todos' style={{ textDecoration: 'none' }}>
                        <Slide id='slide-unica-assinatura' className='slide-unica-assinatura'>
                            <img src='assets/images/banner-hp-unica-assinatura.jpg' alt='Concursos de TI'>
                            </img>
                            <div className='input-group'>
                                <input type='radio' value={'slide-atualizacoes'} name='radio' onChange={handleChange}></input>
                                <input type='radio' value={'slide-mentorias'} name='radio' onChange={handleChange}></input>
                                <input type='radio' value={'slide-unica-assinatura'} name='radio' checked={inputValue === 'slide-unica-assinatura'} onChange={handleChange}></input>
                            </div>
                            <Info className='info-3'>
                                <Supertitle className='title-3'>TI PARA CONCURSOS PÚBLICOS</Supertitle>
                                <Subtitle className='sub-3'>CURSOS DE TECNOLOGIA DA INFORMAÇÃO</Subtitle>
                                <Description className='desc-3'>Tudo para você passar</Description>
                                <Button className='btn-3'>Todos os Cursos</Button>
                            </Info>
                        </Slide>
                    </a>


                </Carrossel>
            </Background>
        </BannerHomePageStyle>
    )

}

export default BannerHomePage;