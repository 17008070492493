import styled from 'styled-components';

export const CardProfessorCarrosselStyle = styled.div`

    text-align: center;
    margin: 50px auto;
    width: fit-content;

    a {
        text-decoration: none;
        color: none;
    }

`;


export const Background = styled.div`

    position: relative;
    height: 335px;
    width: 255px;
    background-color: #FAFAFA;
    transition: all 0.5s ease;
    

    &:hover {
        background-color: #27921A;
        cursor: pointer;

        img {
            width: 100px;
        }

        p {
            color: #FFF;
        }

        a {
            color: #FFF;
            font-weight: 700;
        }

        button {
            background-color: #FFF;
        }

        .iconProfessor {
            color: #A4ABAF;
        }
    }

`;

export const Image = styled.img`

    width: 150px;
    display: block;
    margin: auto;
    position: relative;
    top: 30px;
    border-radius: 50%;
    transition: width 0.5s ease;

`;

export const Nome = styled.p`

    position: relative;
    top: 40px;
    text-decoration: none;
    font-size: 18px;
    color: #49575F;
    text-transform: uppercase;
    font-weight: 500;
    margin: 5px 0 0 0;
    transition: all 0.5s ease;

    &:hover {
        text-decoration: underline;
    }

`;

export const Descricao = styled.p`

    font-size: 13px;
    position: relative;
    top: 50px;
    width: 80%;
    margin: auto;
    color: transparent;
    text-align: justify;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    transition: all 0.5s ease;

`;

export const Button = styled.button`

    position: absolute;
    left: 112px;
    bottom: 40px;
    border: none;
    padding: 8px;
    border-radius: 3px;
    background-color: #A4ABAF;
    transition: all 0.5s ease;

    &:hover {
        cursor: pointer;
    }

`;