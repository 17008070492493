import { BuscaDinamicaStyle } from './styles/buscaDinamica.style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

interface BuscaDinamicaProps {
    getTermoBusca: (termoBusca: string) => void,
    objetoDeBusca: string
}

const BuscaDinamica = (props: BuscaDinamicaProps) => {
    return (
        <BuscaDinamicaStyle>
            <div className="form">
                <input type='text' placeholder={`Digite ${props.objetoDeBusca}`} onChange={(event) => {props.getTermoBusca(event.target.value)}} />
                <button onClick={() => {}}><FontAwesomeIcon icon={faMagnifyingGlass}/></button>
            </div>
        </BuscaDinamicaStyle>
    )
}

export default BuscaDinamica;