import styled from 'styled-components';

export const PlanosDeAssinaturaStyle = styled.div`

    .porque-provas {
        margin: 50px 35% 50px 20%;

        p {
            text-align: justify;
            font-style: italic;
            font-size: 17px;
            line-height: 26px;
            color: #6F8190;
            margin-bottom: 30px;
        }

        .titulo {
            font-size: 30px;
            color: #49575F;
        }

        @media (max-width: 1500px) {
            margin: 50px 25% 50px 10%;
        }

        @media (max-width: 1000px) {
            margin: 50px 15% 50px 5%;
        }

        @media (max-width: 700px) {
            margin: 50px 3% 50px 3%;

            p {
                font-size: 15px;
            }

            .titulo {
                font-size: 26px;
            }
        }
    }

    .videos {
        margin: 50px 20%;
        text-align: center;
        color: #49575F;

        p {
            font-size: 17px;
        }

        p.ajuda {
            font-size: 30px;
            font-weight: 900;
            margin: 0 0 20px 0;
        }

        h2 {
            font-size: 30px;
            font-weight: 900;
            margin: 0 0 20px 0;
        }

        span {
            &:before {
                content: '';
                display: inline-block;
                margin: 0 15px 8px 0;
                height: 2px;
                width: 20px;
                background-color: #27921A; 
            }

            &:after {
                content: '';
                display: inline-block;
                margin: 0 0 8px 15px;
                height: 2px;
                width: 20px;
                background-color: #27921A;
                
            }
        }

        .video-players {

            display: flex;
            flex-flow: row wrap;
            justify-content: space-around;
            margin-top: 25px;

            iframe {
                margin: 40px 0;
            }
        }

        @media (max-width: 1700px) {
            margin: 50px 10%;
        }

        @media (max-width: 1300px) {
            margin: 50px 5%;
        }

        @media (max-width: 1100px) {
            .video-players {
                iframe {
                    margin: 20px 0;
                }
            }
        }

        @media (max-width: 600px) {
            .video-players {
                iframe {
                    margin: 15px 0;
                    width: 380px;
                    height: 214px;
                }
            }
        }

        @media (max-width: 500px) {
            .video-players {
                iframe {
                    margin: 10px 0;
                    width: 280px;
                    height: 158px;
                }
            }
        }
    }

`;

export const FraseCardInformativo = styled.div`

    width: 70%;

    .frase-wrapper {
        border-left: 5px solid #EEE;
        padding: 5px 10px;
        margin: 20px 0;
        position: relative;
        text-align: justify;
    }

    .quote-icon {
        display: inline-block;
        font-size: 40px;
        padding: 5px 15px 5px 5px;
        color: #90A1AE;
        position: absolute;
        top: 20px;
    }

    .frase {
        display: inline-block;
        margin: 40px 0 10px 0;
        padding-top: 30px;
        padding-left: 50px;
        font-size: 25px;
        font-style: italic;
        color: #6F8190;
    }

    .txt {
        font-size: 16px;
        font-style: italic;
        color: #627484;
        width: 90%;
        margin: 0 0 0 50px;
    }

    .autor-info {
        text-align: right;
        margin: 0;
        color: #777;
        font-weight: bold;
    }

    @media (max-width: 1000px) {
        width: 100%;

        .frase {
            text-align: left;
            font-size: 23px;
        }

        .txt {
            font-size: 14px;
        }
    }

    @media (max-width: 500px) {
        width: 100%;

        .frase {
            text-align: left;
            font-size: 20px;
        }

        .txt {
            font-size: 13px;
            margin-left: 30px;
        }
    }

`;