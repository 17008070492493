import TituloPagina from '../componentes/tituloPagina';
import BuscaDinamica from '../componentes/buscaDinamica';
import CardCurso from '../componentes/cardCurso';
import { API_URL } from '../config';
import { ListagemCursosStyle } from './styles/ListagemCursos.style';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import CarregamentoWidget from '../componentes/carregamentoWidget';
import numeros from '../dados/numeros.json';


const CursosTodos = () => {

    interface Cursos {
        id: string,
        nome_curto: string,
        descricao: string,
        aula_count: number
    }

    interface Aulas {
        total_aulas_count: number,
    }

    const [cursos, setCursos] = useState<Cursos[]>();
    const [aulas, setAulas] = useState<Aulas>();
    const [cardStyle, setCardStyle] = useState<string>();
    const [termoBusca, setTermoBusca] = useState<string>('');

    const callback = (style: string) => {
        setCardStyle(style);
    }

    useEffect(() => {
        
        const getTodosOsCursos = async () => {
            const response = await axios.get(`${API_URL}/api/cursos/get/todosOsCursos`)
            setCursos(response.data.results);
        }

        getTodosOsCursos();

        const getAulas = async () => {
            const response = await axios.get(`${API_URL}/api/cursos/get/totalAulas`)
            setAulas(response.data.results[0]);
        }

        getAulas();

    }, []);

    const getTermoBusca = (termoBusca: string) => {
        setTermoBusca(termoBusca);
    }

    return (
        <>
        {!cursos && !aulas ? <CarregamentoWidget /> :
        <ListagemCursosStyle>
            {aulas &&
            <TituloPagina h1ClassName=''
                          titulo={'TODOS OS CURSOS PARA CONCURSOS DE TI'}
                          link='Cursos'
                          info={`São ${numeros.numero_aulas}+ aulas para concursos de TI! E aumentando...`}
                          hiddenClassName=''
                          getStyle={callback}
            />}

            <BuscaDinamica getTermoBusca={getTermoBusca} 
                           objetoDeBusca='assunto, disciplina etc.'
            />
            
            <div className='cards'>
                {cursos && cursos.filter((value) => {
                    if (termoBusca === '' || termoBusca === undefined) {
                        return value;
                    } else if (value.nome_curto.toLowerCase().includes(termoBusca.toLowerCase()) || value.descricao.toLowerCase().includes(termoBusca.toLowerCase())) {
                        return value;
                    } else {
                        return null;
                    }
                }).map((curso) => {
                    return (
                        <React.Fragment key={curso.id}>
                            <CardCurso id={curso.id}
                                    nome_curto={curso.nome_curto}
                                    descricao={curso.descricao}
                                    aulas_count={curso.aula_count}
                                    cardStyle={`${cardStyle}`}
                                    data={''}
                                    destaque={false}
                            />
                        </React.Fragment>
                    )
                })}
            </div>
        </ListagemCursosStyle>}
        </>
    )
}

export default CursosTodos;