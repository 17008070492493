import styled from 'styled-components';

export const TermosStyle = styled.div`

    text-align: center;
    padding: 50px 20%;

    h2 {
        font-size: 30px;
        font-weight: 900;
        margin: 0 0 20px 0;
        color: #49575F;
    }

    span {
        &:before {
            content: '';
            display: inline-block;
            margin: 0 15px 8px 0;
            height: 2px;
            width: 20px;
            background-color: #27921A; 
        }

        &:after {
            content: '';
            display: inline-block;
            margin: 0 0 8px 15px;
            height: 2px;
            width: 20px;
            background-color: #27921A;
            
        }
    }

    .termo {
        width: 80%;
        text-align: justify;
        margin: auto;

        h3 {
            font-size: 30px;
            color: #49575F;
            margin: 50px 0 5px 0;
        }

        .color-rule {
            height: 5px;
            width: 50px;
            background-color: #27921A;
            margin-bottom: 30px;
        }

        p {
            font-size: 14px;
            line-height: 23px;
            color: #333;
        }
    }

    .rule {
        width: 100%;
        height: 0.1px;
        background-color: #DDD;
        margin-top: 40px;
    }

    @media (max-width: 1200px) {
        .termo {
            width: 100%;
        }
    }

    @media (max-width: 900px) {
        padding: 50px 5%;
        
        .termo {
            h3 {
                text-align: left;
            }
        }
    }

`;