import styled from 'styled-components';

export const CardPlanoAnualStyle = styled.div`

    text-align: center;
    width: fit-content;
    height: fit-content;

`;

export const Preco = styled.div`

    width: 262.5px;
    height: 80px;
    background-color: #242C42;
    color: white;

    h2 {
        margin: auto;
        padding-left: 35px;
        font-size: 48px;
        width: fit-content;
        height: 100%;
        
        span {
            text-decoration: line-through #F00 8px;
        }
    }

    h2::before {
        content: 'R$';
        position: relative;
        right: 3px;
        bottom: 25px;
        font-size: 12px;
    }

    h2::after {
        content: '/ANO';
        position: relative;
        top: 15px;
        right: 40px;
        font-size: 12px;
    }

    @media (max-width: 600px) {
        h2 {
            position: relative;
            top: 5px;
        }
    }

`;

export const Detalhes = styled.div`

    width: 202.5px;
    height: 305px;
    padding: 30px;
    background-color: #FAFAFA;
    color: #6F8190;
    text-align: center;

`;

export const Title = styled.h2`

    font-size: 26px;
    font-weight: bold;
    margin: 0 0 8px 0;
    padding: 0;

`;

export const Descricao = styled.p`

    font-size: 12px;
    margin: 0;
    padding: 0;

`;

export const Lista = styled.div`

    display: flex;
    flex-wrap: wrap;
    width: 202.5px;
    justify-content: space-around;
    margin-top: 10px;

    .icon {
        margin-right: 3px;
    }

`;

export const ItemE = styled.p`

    font-size: 14px;
    width: 152.5px;
    text-align: left;
    margin: 10px 0 5px 0;

`;

export const ItemD = styled.p`

    font-size: 14px;
    width: 50px;
    text-align: right;
    margin: 10px 0 5px 0;

`;

export const Button = styled.button`

    position: relative;
    margin-top: 20px;
    color: #FFF;
    padding: 10px 16px;
    background-color: #242C42;
    border: none;
    border-radius: 4px;
    transition: all 0.3s ease;
    z-index: 1;

    &.span {
        position: relative;
        font-size: 14px;
        z-index: 2;
    }

    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        height: 100%;
        background-color: #FFF;
        color: #000;
        transition: all 0.5s ease;
        z-index: -1;
    }

    &:hover {
        cursor: pointer;
        color: #000;

        &:after {
            width: 100%;
            border: 1px solid #000;
            border-radius: 4px;
        }
    }

`;