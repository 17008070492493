import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

    html {
        overflow-x: hidden;
    }

    body {
        overflow-x: hidden;
    }

`;

export const ProfessorPerfilStyle = styled.div`

    .professor-info {
        margin: 50px auto;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
    }

    .detalhes {
        width: 844px;
        margin-left: 30px;
        color: #6F8190;

        h2 {
            margin: 0;
            color: #49575F;
            font-size: 30px;
            font-weight: 700;
            text-transform: uppercase;
        }

        h3 {
            margin: 5px 0 5px 0;
            font-weight: 700;
        }

        p {
            margin-top: 35px;
            text-align: justify;
            font-size: 14px;
        }
    }

    @media (max-width: 1200px) {
        .professor-info {
            width: auto;
            margin: 50px 5%;
        }

        .detalhes {
            margin: 30px 0 0 0;
        }
    }

`;

export const ProfessorCursos = styled.div`

    margin: 10px auto 70px auto;
    width: 1144px;

    h3 {
        font-size: 30px;
        font-weight: bold;
        color: #49575f;
        margin: 0 0 5px 0;
    }

    .color-rule {
        height: 5px;
        width: 50px;
        background-color: #27921A;
        margin-bottom: 30px;
    }

    .table {
        overflow-x: auto;
    }

    .table-title {
        background-color: #90A1AE;
        color: #FFF;
        
        th {
            padding: 15px;
        }
    }

    .curso-row {
        background-color: #FAFAFA;
        color: #6F8190;

        td {
            padding: 10px;
            font-size: 14px;
        }

        a {
            text-decoration: none;
            color: inherit;

            &:hover {
                text-decoration: underline #27921A;
                color: #27921A;
            }
        }

        p {
            margin: 3px 0 0 0;
        }
    }

    @media (max-width: 1200px) {
        width: auto;
        margin: 10px 5% 70px 5%;
    }

    @media (max-width: 750px) {

        h3 {
            font-size: 27px;
        }

        .table {
            width: auto;
        }

        table {
            width: auto;
        }

        table thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        table td {
            border-bottom: 1px solid #ddd;
            display: flex;
            justify-content: space-between;
            text-align: right;
            
            a {
                width: 70%;
                text-align: left;
            }
        }

        table td::before {
            content: attr(data-label);
            float: left;
            text-align: left;
            margin: auto 10px auto 0;
            width: 30%;
            color: #737373;
        }

        table td:last-child {
            border-bottom: 0;
        }

        .curso-row {
            border: 1px solid #ddd;
            border-bottom: 3px solid #ddd;
            display: block;
            margin-bottom: .625em;
        }
 
        .row-space {
            background-color: #FFF;
            height: 10px;
        }
    }

`;