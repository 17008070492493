import { AtualizacoesStyle, Wrapper, GravacoesInfo, Gravacoes, CursosDestaque } from './styles/Atualizacoes.style';
import CabecalhoInfo from '../componentes/cabecalhoInfo';
import CabecalhoMenu from '../componentes/cabecalhoMenu';
import Rodape from '../componentes/rodape';
import CarregamentoWidget from '../componentes/carregamentoWidget';
import InstrucaoNutror from '../componentes/instrucaoNutror';
import { API_URL } from '../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faCalendarDays, faVideo } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import IrAoTopoWidget from '../componentes/irAoTopoWidget';
import ReactPaginate from 'react-paginate';
import SeoHelmet from '../componentes/seoHelmet';

const Atualizacoes = () => {

    interface Gravacoes {
        curso_id: string,
        curso_nome: string,
        linknutrormod: string,
        aula_id: number,
        aula_nome: string,
        modulo_nome: string,
        modulo_id: number,
        professor_nome: string,
        professor_id: number,
        aula_duracao: string,
        aula_data: string,
        aula_tipo: number
    }

    interface Destaque {
        id: string,
        nome: string
    }

    const [gravacoes, setGravacoes] = useState<Gravacoes[]>([]);
    const [destaques, setDestaques] = useState<Destaque[]>();
    const [paginaNum, setPaginaNum] = useState<number>(0);

    const [carregando, setCarregando] = useState<boolean>(false);

    const resultadosPorPagina: number = 110;
    const resultadosVisitadas: number = paginaNum * resultadosPorPagina;

    let paginasCount = Math.ceil(gravacoes.length / resultadosPorPagina);

    const displayResultados = gravacoes.slice(resultadosVisitadas, (resultadosVisitadas + resultadosPorPagina))
    .map((modulo, index) => {
        if (gravacoes.slice(resultadosVisitadas, (resultadosVisitadas + resultadosPorPagina))[index + 1] === undefined || (gravacoes.slice(resultadosVisitadas, (resultadosVisitadas + resultadosPorPagina))[index + 1].modulo_id !== modulo.modulo_id)) {
            return (
                <tbody key={`${modulo.modulo_id} ${modulo.curso_id} ${modulo.aula_id}`}>
                    <tr className='modulo-title'>
                        <th colSpan={4} className='modulo-info'>
                            <div className='flex'>
                                <div>
                                    <p><a href={`/cursos/${modulo.curso_id}`} target="_blank" rel='noreferrer'>{`Curso (CR${modulo.curso_id}): ${modulo.curso_nome}`}</a></p>
                                    <p><a href={`/cursos/${modulo.curso_id}`} target="_blank" rel='noreferrer'>{`Módulo (MD${modulo.modulo_id}): ${modulo.modulo_nome} - Prof. ${modulo.professor_nome}`}</a></p>
                                </div>
                                <div className='nutror-icon'>
                                    <a href={modulo.linknutrormod} target="_blank" rel='noreferrer'><img src='/assets/images/nutror-icon.png' alt='ícone Nutror'></img></a>
                                </div>
                            </div>
                        </th>
                    </tr>
                
                    {gravacoes.slice(resultadosVisitadas, (resultadosVisitadas + resultadosPorPagina)).map((aula, index) => {
                        if (aula.modulo_id === modulo.modulo_id && aula.curso_id === modulo.curso_id && aula.aula_data === modulo.aula_data) {
                            return (
                                <React.Fragment key={aula.aula_id}>
                                    <tr className='aula-row'>
                                        <td data-label="PROFESSOR"><a href={`/professores/${aula.professor_id}`} target="_blank" rel='noreferrer'>Prof. {aula.professor_nome}</a></td>
                                        <td data-label="AULA"><div><FontAwesomeIcon icon={faVideo} color='#27921A' /> &nbsp; {`(AL${aula.aula_id}) ${aula.aula_nome}`}</div></td>
                                        <td data-label="DURAÇÃO" className='center duracao'><div><FontAwesomeIcon icon={faClock} /> <span>{aula.aula_duracao}</span></div></td>
                                        <td data-label="DATA" className='center duracao'><div><FontAwesomeIcon icon={faCalendarDays} /> <span>{aula.aula_data}</span></div></td>
                                    </tr>
                                </React.Fragment>
                            )
                        }
                        return null;
                    })}
                    <tr className='space'></tr>
                </tbody>
            )
        }
        return null;
    });

    const changePage = ({ selected }:any) => {
        setPaginaNum(selected);
        window.scrollTo({top: 0, behavior: 'smooth'});
    };

    useEffect(() => {

        const getGravacoesMaisRecentes = async () => {
            setCarregando(true);
            const response = await axios.get(`${API_URL}/api/cursos/get/gravacoesMaisRecentes`);
            setGravacoes(response.data.results);
            setCarregando(false);
        }

        const getCursosEmDestaque = async () => {
            const response = await axios.get(`${API_URL}/api/cursos/get/cursosEmDestaque`);
            setDestaques(response.data.results);
        }

        getGravacoesMaisRecentes();
        getCursosEmDestaque();

    }, []);

    return (
        <>
            <SeoHelmet 
                conector=' - '
                subtitulo='Aulas Recentes'
                linkcanonical='atualizacoes'
            />
            <CabecalhoInfo />
            <CabecalhoMenu />

            {(!destaques && !gravacoes[0]) || carregando ? <CarregamentoWidget /> :
            <AtualizacoesStyle>
                <div className='pag-titulo'><h1>CURSOS DE TI PARA CONCURSOS MAIS RECENTES</h1></div>
                <Wrapper>
                    <GravacoesInfo>
                        <Gravacoes>
                            <div className='titulo'>
                                <div className='section-title'>
                                    <h2>Atualizações mais Recentes</h2>
                                    <div className='color-rule'></div>
                                </div>
                                <InstrucaoNutror expanda={false} />
                            </div>

                            {gravacoes && <div className='conteudo'>
                                <table>
                                    <thead>
                                        <tr className='table-title'>
                                            <th className='a'>PROFESSOR</th>
                                            <th className='b'>AULA</th>
                                            <th className='c'>DURAÇÃO</th>
                                            <th className='d'>DATA</th>
                                        </tr>
                                        <tr className='space'></tr>
                                    </thead>
                                
                                    
                                    {displayResultados}
                                    
                                </table>
                                
                            </div>}

                            <ReactPaginate 
                                previousLabel={"Anterior"}
                                nextLabel={"Próximo"}
                                pageCount={paginasCount}
                                onPageChange={changePage}
                                containerClassName={"pagination-btns"}
                                disabledClassName={"disbaled-btn"}
                                activeClassName={"active-btn"}
                                breakClassName={"break"}
                            />
                            
                        </Gravacoes>
                    </GravacoesInfo>
                    {destaques && <CursosDestaque>
                        <div className='titulo'>CURSOS EM DESTAQUE</div>
                        <div className='conteudo'>
                            {destaques.map((curso) => {
                                return (
                                    <React.Fragment key={curso.id}>
                                        <div className='curso'>
                                            <a href={`/cursos/${curso.id}`}><img src={`/assets/images/courses/${curso.id}_vitrine.jpg`} alt='imagem do curso relacionad'></img></a>
                                            <div className='relacoesInfo'>
                                                <a href={`/cursos/${curso.id}`}><h4>{`CR-${curso.id}`}</h4></a>
                                                <p>{curso.nome}</p>
                                            </div>
                                        </div>
                                        <div className='rule'></div>
                                    </React.Fragment>
                                )
                            })}
                        </div>
                    </CursosDestaque>}
                </Wrapper>
            </AtualizacoesStyle>}
            <Rodape />
            <IrAoTopoWidget />
        </>
    )
}

export default Atualizacoes;