import { CardConcursoStyle } from './styles/cardConcurso.style';

interface ConcursoInfo {
    id: number;
    titulo: string;
    imagem: string;
    descricao: string;
}

const CardConcurso = (props: ConcursoInfo) => {

    return (
        <CardConcursoStyle>
            <div className='background'>
                <img className='card-concurso-img' src={`/assets/images/concursos/${props.imagem}`} alt='imagem do concurso'></img>
                <div className='info'>
                    <a className='link' href={`/concursosdeti/${props.id}`}>{props.titulo}</a>
                    <p>{props.descricao}</p>
                </div>
                <a href={`/concursosdeti/${props.id}`}><button>PLANO DE ESTUDO</button></a>
            </div>
        </CardConcursoStyle>
    )
}

export default CardConcurso;