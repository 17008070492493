import { CarregamentoWidgetStyle } from './styles/carregamentoWidget.style';

const CarregamentoWidget = () => {
    return (
        <CarregamentoWidgetStyle>
            <div className="loading">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </CarregamentoWidgetStyle>
    )
}

export default CarregamentoWidget;