import { TituloPaginaStyle } from './styles/tituloPagina.style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faThLarge, faThList } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

interface TituloPaginaInfo {
    h1ClassName: string,
    titulo: string,
    info: string,
    link: string,
    hiddenClassName: string,
    getStyle: (style: string) => void
}

const TituloPagina = (props: TituloPaginaInfo) => {

    const [style, setStyle] = useState<string>('2col');

    const handleRadioChange = (event: any) => {
        setStyle(event.target.value);
        props.getStyle(event.target.value);
    }

    return (
        <TituloPaginaStyle>
            <div className='wrapper'>
                <h1 className={props.h1ClassName}>{props.titulo}</h1>
                <h2> {props.info}</h2>
                <a href='/'>Home</a><FontAwesomeIcon icon={faAngleRight} color="#CCC" fontSize="13px" /><p className='link'>{props.link}</p>
                <div className='flex'>
                    <div className={(props.hiddenClassName !== '') ? `counter ${props.hiddenClassName} txt-info` : 'counter txt-info'}>
                        {/*
                            <div className='detalhes'>Clique em "Detalhes" e veja os <b>módulos</b> e <b>aulas</b> de cada curso.</div>
                        */}
                    </div>
                    <div className={`btns ${props.hiddenClassName}`}>
                        <input id='2col' type='radio' value='2col' checked={style === '2col'} onChange={handleRadioChange} /> <label htmlFor='2col' className={(style === '2col') ? 'btn active' : 'btn'}><FontAwesomeIcon icon={faThLarge} className='icon-btn' color="#FFF" fontSize="16px" /></label>
                        <input id='1col' type='radio' value='1col' checked={style === '1col'} onChange={handleRadioChange} /> <label htmlFor='1col' className={(style === '1col') ? 'btn active' : 'btn'}><FontAwesomeIcon icon={faThList} className='icon-btn' color="#FFF" fontSize="16px" /></label>
                    </div>
                </div>
            </div>
        </TituloPaginaStyle>
    )
}

export default TituloPagina;