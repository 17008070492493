import TituloPagina from '../componentes/tituloPagina';
import CardConcurso from '../componentes/cardConcurso';
import { API_URL } from '../config';
import BuscaDinamica from '../componentes/buscaDinamica';
import { ConcursosDeTiStyle } from './styles/ConcursosDeTi.style';
import axios from 'axios';
import { useEffect, useState } from 'react';
import CarregamentoWidget from '../componentes/carregamentoWidget';

const EstudeSemEdital = () => {

    interface Concursos {
        id: number,
        sigla: string,
        cargo: string,
        orgao: string,
        especialidade: string,
        textochamada: string,
        imagem: string
    }

    const [concursosEmAndamento, setConcursosEmAndamento]= useState<Concursos[]>();
    const [termoBusca, setTermoBusca] = useState<string>('');

    const getConcursosEmAndamento = () => {
        axios.get(`${API_URL}/api/concursos/get/estudeSemEdital`)
        .then(response => setConcursosEmAndamento(response.data.results));
    }

    const getTermoBusca = (termoBusca: string) => {
        setTermoBusca(termoBusca);
    }

    useEffect(() => {
        getConcursosEmAndamento();
    }, []);

    return (
        <>
        {!concursosEmAndamento ? <CarregamentoWidget /> :
        <ConcursosDeTiStyle>
            <TituloPagina titulo={'ESTUDE SEM EDITAL'}
                          info={'As trilhas abaixo focam nas principais disciplinas cobradas em Concursos de TI.'}
                          link={'Concursos De TI'}
                          h1ClassName={'center'}
                          hiddenClassName={'hidden'}
                          getStyle={() => {}}
            />

            <BuscaDinamica getTermoBusca={getTermoBusca} 
                           objetoDeBusca='disciplina, assunto etc.'
            />

            <div className='cards'>
                {concursosEmAndamento.filter((value) => {
                    if (termoBusca === '' || termoBusca === undefined) {
                        return value;
                    } else if (value.sigla.toLowerCase().includes(termoBusca.toLowerCase()) || value.cargo.toLowerCase().includes(termoBusca.toLowerCase()) || value.especialidade.toLowerCase().includes(termoBusca.toLowerCase()) || value.orgao.toLowerCase().includes(termoBusca.toLowerCase())) {
                        return value;
                    } else {
                        return null;
                    }
                }).map((concurso) => {
                    return (
                        <div key={concurso.id}>
                            <CardConcurso id={concurso.id}
                                          titulo={`${concurso.sigla} (${concurso.cargo} - ${concurso.especialidade})`} 
                                          descricao={concurso.textochamada}
                                          imagem={`${concurso.id}.jpg`}
                            />
                        </div>
                    )
                })}
            </div>
        </ConcursosDeTiStyle>}
        </>
    )

}

export default EstudeSemEdital;