import { useEffect, useState } from 'react';
import { CarrosselProfessoresStyle, BackgroundCarrossel, Wrapper, Titles, SubTitle, Title, TitleIcon, Carrossel, PrevNextButton, Track, Card, AnimationController } from './styles/carrosselProfessores.style';
import { API_URL } from '../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPersonChalkboard, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import CardProfessorCarrossel from './cardProfessorCarrossel';
import axios from 'axios';
import CarregamentoWidget from './carregamentoWidget';

const CarrosselProfessores = () => {

    interface ProfessorInfo {
        id: number,
        nome: string,
        descricao: string,
        foto: string
    }

    const [track, setTrack] = useState<HTMLElement | null>();
    const [controller, setController] = useState<HTMLElement | null>();
    const [card, setCard] = useState<HTMLElement | null>();

    const [professorInfo, setProfessorInfo] = useState<ProfessorInfo[]>();

    useEffect(() => {

        const getProfessoresCardInfo = async () => {
            const response = await axios.get(`${API_URL}/api/professores/get/professoresCardInfo`)
            setProfessorInfo(response.data.results);
        }

        getProfessoresCardInfo();


    }, [track]);

    useEffect(() => {

        const setElements = () => {
            setTrack(document.getElementById('track'));
            setController(document.getElementById('controller'));
            setCard(document.getElementById('card'));
        }

        setElements();

    });

    
    let count = 0;
    let direction: number;

    const prevTrack = () => {

        if (track && controller && card) {

            console.log(track, controller, card)

            direction = 1;
            count--;

            track.style.justifyContent = 'flex-end';

            if (track.lastElementChild)

            track.prepend(track.lastElementChild);
            track.style.transition = 'none';
            track.style.transform = `translateX(-${card.offsetWidth + 27}px)`;

            setTimeout(function() {
                if (track) {
                    track.style.transition = 'transform 1s ease';
                    track.style.transform = `translateX(0)`;
                }
            });

            controller.style.transform = `translateX(${count * 1}px)`;

        }

    }

    const nextTrack = () => {

        if (track && controller && card) {

            direction = -1;
            count++;

            track.style.justifyContent = 'flex-start';

            track.style.transform = `translateX(-${card.offsetWidth + 27}px)`;

            controller.style.transform = `translateX(${count * -1}px)`;

        }

    }

    if (controller && track) {

        controller.addEventListener('transitionend', function(e) {

            if (track.firstElementChild) {

                if (direction === -1) {
                    track.appendChild(track.firstElementChild);
                }


                track.style.transition = 'none';
                track.style.transform = 'translate(0)';

                setTimeout(function() {
                    if (track)
                    track.style.transition = 'transform 1s ease';
                });

            }

        })

    }

    return (
        <>
        {!professorInfo ? <CarregamentoWidget /> :
        <CarrosselProfessoresStyle>
            <BackgroundCarrossel>
                <Wrapper>
                    <Titles>
                        <Title>PROFESSORES PARA CONCUSOS DE TI</Title>
                        <SubTitle>APRENDA COM OS MELHORES</SubTitle>
                        <TitleIcon><FontAwesomeIcon icon={faPersonChalkboard} className="TitleIcon" /></TitleIcon>
                    </Titles>
                    <Carrossel>
                        <Track id='track'>
                            {professorInfo.map((professor, i) => {
                                return (
                                    <Card id='card' key={professor.id}>
                                        <CardProfessorCarrossel nome={professor.nome}
                                                                id={professor.id}
                                                                descricao={professor.descricao}
                                                                imagem={professor.foto}
                                        />
                                    </Card>
                                )
                            })}
                        </Track>
                    </Carrossel>
                    <PrevNextButton className='prev' onClick={prevTrack}><FontAwesomeIcon icon={faAngleLeft} fontSize='24px'/></PrevNextButton>
                    <PrevNextButton className='next' onClick={nextTrack}><FontAwesomeIcon icon={faAngleRight} fontSize='24px'/></PrevNextButton>
                </Wrapper>
                <AnimationController id='controller'></AnimationController>
            </BackgroundCarrossel>
        </CarrosselProfessoresStyle>}
        </>
    )
}

export default CarrosselProfessores;