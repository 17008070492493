import TituloPagina from '../componentes/tituloPagina';
import CardPlanoAnual from '../componentes/cardPlanoAnual';
import { API_URL } from '../config';
import { PlanoDeEstudoStyle, Wrapper, ConcursoInfo, Detalhes, Relacoes, ConcursosRelacionados, PlanoDeEstudoEdital, FraseCardInformativo } from './styles/PlanoDeEstudo.style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandPointDown, faAnglesDown, faFaceFrown, faQuoteLeft, faDownload } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import CarregamentoWidget from '../componentes/carregamentoWidget';
import InstrucaoNutror from '../componentes/instrucaoNutror';

const PlanoDeEstudo = () => {

    interface ConcursoInfo {
        id: string,
        sigla: string,
        orgao: string,
        site: string,
        banca: string
        cargo: string,
        especialidade: string,
        vencimento: string,
        linkedital: string,
        textochamada: string,
        imagem: string,
        escolaridade: number,
        dataedital: string,
        datainicioinscricao: string,
        datafiminscricao: string,
        dataprovaobjetiva: string
    }

    interface ConcursoRelacoes {
        id: string,
        sigla: string,
        dataedital: string,
        cargo: string,
        especialidade: string,
        imagem: string
    }

    interface EditalInfo {
        id: number,
        disciplina: string,
        assunto: string
    }

    interface PlanoDeEstudo {
        edital_ordem: number,
        disciplina_id: number,
        disciplina: string,
        trilha: number,
        ordem: number,
        modulo_id: number,
        modulo_nome: string,
        linknutrormod: string,
        curso_info_id: number,
        curso_nome: string,
        nome_trilha: string
    }

    interface Frase {
        texto: string,
        autor: string,
        onde: string
    }

    const [concursoInfo, setConcursoInfo] = useState<ConcursoInfo>();
    const [concursoRelacoes, setConcursoRelacoes] = useState<ConcursoRelacoes[]>();
    const [editalInfo, setEditalInfo] = useState<EditalInfo[]>();
    const [planoDeEstudo, setPlanoDeEstudo] = useState<PlanoDeEstudo[]>();
    const [frase, setFrase] = useState<Frase>();
    const [isHovering, setIsHovering] = useState(false);
    const [elementId, setElementId] = useState<string>();
    const { id } = useParams();
    let situacaoTxt;
    let nivelTxt;

    const handleMouseOver = (elementId: string) => {
        setIsHovering(true);
        setElementId(elementId);
    }

    const handleMouseOut = () => {
        setIsHovering(false);
    }

    useEffect(() => {

        const getConcursoInfoById = async () => {
            const response = await axios.get(`${API_URL}/api/concursos/get/concursoById`, {
                params: {
                    id: id
                }
            })
            setConcursoInfo(response.data.results[0]);
        }

        const getConcursoRelacoes = async () => {
            const response = await axios.get(`${API_URL}/api/concursos/get/concursoRelacoes`, {
                params: {
                    id: id
                }
            })
            setConcursoRelacoes(response.data.results);
        }

        const getEditalInfo = async () => {
            const response = await axios.get(`${API_URL}/api/planoDeEstudo/get/editalInfo`, {
                params: {
                    id: id
                }
            })
            setEditalInfo(response.data.results);
        }

        const getPlanoDeEstudo = async () => {
            const response = await axios.get(`${API_URL}/api/planoDeEstudo/get/planoDeEstudo`, {
                params: {
                    id: id
                }
            })
            setPlanoDeEstudo(response.data.results);
        }

        const getFrase = async () => {
            const response = await axios.get(`${API_URL}/api/planoDeEstudo/get/fraseAleatoria`)
            setFrase(response.data.results[0]);
        }

        getConcursoInfoById();
        getConcursoRelacoes();
        getEditalInfo();
        getPlanoDeEstudo();
        getFrase();

    }, [id]);


    if (concursoInfo) {
        switch (concursoInfo.escolaridade) {
            case 1:
                nivelTxt = 'Fundamental';
                break;
            case 2:
                nivelTxt = 'Médio';
                break;
            case 3:
                nivelTxt = 'Superior';
                break;
        }

        let today = new Date();

        if (new Date(concursoInfo.dataedital) <= today && new Date(concursoInfo.datafiminscricao) >= today) {
            situacaoTxt = 'Edital Publicado.';
        } else if (new Date(concursoInfo.datafiminscricao) < today && new Date(concursoInfo.dataprovaobjetiva) >= today) {
            situacaoTxt = 'Inscrições encerradas.';
        } else if (new Date(concursoInfo.dataprovaobjetiva) < today && concursoInfo.dataprovaobjetiva !== '0000-00-00') {
            situacaoTxt = 'Finalizado.';
        } else if (concursoInfo.dataedital === '0000-00-00') {
            situacaoTxt = '';
        }
    }

    return (
        <>
            {!concursoInfo && !concursoRelacoes && !editalInfo && !planoDeEstudo && !frase ? <CarregamentoWidget /> :
                <PlanoDeEstudoStyle>
                    <TituloPagina titulo={'PLANO DE ESTUDO'}
                        info={''}
                        link={'Planos de Estudo'}
                        h1ClassName={'center'}
                        hiddenClassName={'hidden'}
                        getStyle={() => { }}
                    />
                    <Wrapper>
                        {concursoInfo && <ConcursoInfo>
                            <img src={`/assets/images/concursos/${concursoInfo.id}.jpg`} alt='imagem do concurso'></img>
                            <h1>{`${concursoInfo.orgao}`}</h1>
                            <h1>{`${concursoInfo.cargo} (${concursoInfo.especialidade})`}</h1>
                            <span>{`Banca: ${concursoInfo.banca} | Órgão: ${concursoInfo.sigla}`}</span>
                            <p className='texto'>{concursoInfo.textochamada}</p>
                            {(concursoInfo.dataedital !== '0000-00-00') ? <> <Detalhes>
                                <div>
                                    <h3>{situacaoTxt}</h3>
                                    <p>Cargo: <b>{concursoInfo.cargo}</b></p>
                                    <p>Especialidade: <b>{concursoInfo.especialidade}</b></p>
                                    <p>Salário: <b>R$ {concursoInfo.vencimento}</b></p>
                                    <p>Nível: <b>{nivelTxt}</b></p>
                                </div>
                                <div>
                                    <h3>{situacaoTxt}</h3>
                                    <p>{`Inscrições: de ${new Date(concursoInfo.datainicioinscricao).toLocaleDateString()} a ${new Date(concursoInfo.datafiminscricao).toLocaleDateString()}`}</p>
                                    <p>Prova Objetiva: {new Date(concursoInfo.dataprovaobjetiva).toLocaleDateString()} </p>
                                    <p>Edital de Abertura: <a href={`/assets/editais/${concursoInfo.linkedital}.pdf`} target='_blank' rel='noreferrer'>ver edital</a></p>
                                    <p>Site do Órgão: <a href={concursoInfo.site} target='_blank' rel='noreferrer'>ver site do órgão</a></p>
                                </div>
                            </Detalhes>
                                <div className='rule'></div>
                                <PlanoDeEstudoEdital>
                                    <div className='titulo'>
                                        <h2>Plano de Estudo do Edital <FontAwesomeIcon icon={faHandPointDown} /></h2>
                                        <InstrucaoNutror expanda={true} />
                                    </div>
                                    {editalInfo && <div className='edital-info'>
                                        {editalInfo.map((materia, i) => {
                                            let trilhaArr: any = new Set([]);
                                            return (
                                                <div className='edital-materia' key={i}>
                                                    <input type='checkbox' id={`${materia.id}`}></input>
                                                    <label htmlFor={`${materia.id}`} ><FontAwesomeIcon icon={faAnglesDown} fontSize='12px' color='#27921A' />&nbsp; <h4 className='materia-txt'>{materia.disciplina}</h4></label>
                                                    <div className='dados-edital'>
                                                        <p className='plano'>{materia.assunto}</p>
                                                        {planoDeEstudo && <>
                                                            {planoDeEstudo.map((plano, index) => {

                                                                if (plano.disciplina_id === materia.id) {

                                                                    trilhaArr.add(plano.trilha);

                                                                    const array: number[] = Array.from(trilhaArr);

                                                                    let nextIndex = index + 1;

                                                                    if (!plano.nome_trilha) {
                                                                        if (planoDeEstudo[nextIndex] === undefined || planoDeEstudo[nextIndex].disciplina_id !== materia.id) {
                                                                            return (
                                                                                <div key={index}>
                                                                                    {array.map((trilha, i) => {
                                                                                        return (
                                                                                            <div key={i}>
                                                                                                <div className='plano plano-align'>{`Trilha ${(i+1).toString().padStart(2, '0')}:`}
                                                                                                    {planoDeEstudo.map((plano2, i) => {
                                                                                                        if (trilha === plano2.trilha && plano2.disciplina_id === plano.disciplina_id) {
                                                                                                            return (
                                                                                                                <div className='plano-wrapper' key={i}>
                                                                                                                    <div className='modulo'>
                                                                                                                        <a href={plano2.linknutrormod} target="_blank" rel='noreferrer'><img className='icon-link-nutror' src='https://www.provasdeti.com.br/assets/images/nutror-icon.png' alt='ícone Nutror'></img></a>
                                                                                                                        <p className='modulo-txt'>
                                                                                                                            <div className='icon-wrapper' 
                                                                                                                                onMouseOver={() => handleMouseOver(`${plano2.modulo_id}${plano2.curso_info_id}${plano2.trilha}${plano2.ordem}`)}  
                                                                                                                                onMouseOut={handleMouseOut}>                                                                                                                            
                                                                                                                                <a href={`/cursos/${plano2.curso_info_id}`} target="_blank" rel='noreferrer'>{`MD${plano2.modulo_id} (CR${plano2.curso_info_id})`}</a>                                                                                                                      
                                                                                                                            </div>
                                                                                                                            <a href={`/assets/slides/md${plano2.modulo_id}.zip`} download>
                                                                                                                                <FontAwesomeIcon className='info-slide' icon={faDownload} color='#CCC' />
                                                                                                                            </a>                                                                                                                      
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                    <div id={`${plano2.modulo_id}${plano2.curso_info_id}${plano2.trilha}${plano2.ordem}`}
                                                                                                                        className={(isHovering && (elementId === `${plano2.modulo_id}${plano2.curso_info_id}${plano2.trilha}${plano2.ordem}`)) ? 'more-info visible' : 'more-info'}>
                                                                                                                        <p className='more-info-txt'>{`CR${plano2.curso_info_id}: ${plano2.curso_nome}`}</p>
                                                                                                                        <p className='more-info-txt'>{`MD${plano2.modulo_id}: ${plano2.modulo_nome}`}</p>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            )
                                                                                                        }
                                                                                                        return null;
                                                                                                    })}
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            )
                                                                        }
                                                                    }
                                                                    else if (plano.nome_trilha && (planoDeEstudo[nextIndex] === undefined || planoDeEstudo[nextIndex].disciplina_id !== materia.id)) {
                                                                        return (
                                                                            <div className='modulo-alerta' key={index}>
                                                                                <FontAwesomeIcon icon={faFaceFrown} className='face-icon' color='#CCC' />
                                                                                <p className='plano alerta'>{plano.nome_trilha}</p>
                                                                            </div>
                                                                        )
                                                                    }
                                                                }
                                                                return null;
                                                            })}
                                                            <div className='rule'></div>
                                                        </>}
                                                        <div className='rule'></div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>}
                                </PlanoDeEstudoEdital>
                                <FraseCardInformativo>
                                    {frase && <div className='frase-wrapper'>
                                        <FontAwesomeIcon icon={faQuoteLeft} className={'quote-icon'} />
                                        <h2 className='frase'>{frase.texto}</h2>
                                        <p className='autor-info'>{frase.autor} <small>({frase.onde})</small></p>
                                    </div>}

                                    <div className='card-info'>
                                        Os planos de estudo são uma <b>aproximação</b> entre o edital e a plataforma para <b>acelerar</b> a
                                        sua busca pelos conteúdos que <b>já estão gravados</b>. O mapeamento <b>apenas</b> indica os módulos
                                        que dão a melhor cobertura possível conforme a análise dos professores (e a partir dos módulos que já 
                                        temos na plataforma). Em caso de dúvida, consulte nosso FAQ, assista aos vídeos sobre como usar a 
                                        plataforma ou entre em contato.
                                    </div>
                                    <div className='card-info'>
                                        A verificação de <b>alterações no edital</b> (conteúdo, datas etc.) deve ser feita pelo aluno. 
                                        É sua obrigação ler e acompanhar o edital, pois não prestamos este serviço. 
                                        Independentemente deste plano, o aluno pode decidir o que estudar (ou não) usando a busca do site. 
                                        O Plano de Estudo é apenas um auxílio e <b>NÃO substitui a leitura do edital</b> que deve ser feita pelo aluno. 
                                        Caso queira sugerir mudanças, apontar erros etc. com base no edital, entre em contato.
                                    </div>
                                </FraseCardInformativo></> :
                                <FraseCardInformativo>
                                    <div className='card-info sem-edital'>
                                        Estamos aguardando o edital. Comece a estudar com base no edital anterior.
                                        Veja <b>AO LADO</b> os mapeamentos para os últimos concursos relacionados.
                                    </div>

                                    <div className='rule'></div>
                                </FraseCardInformativo>}
                        </ConcursoInfo>}
                        <Relacoes>
                            <CardPlanoAnual />
                            <ConcursosRelacionados>
                                {concursoRelacoes && <>
                                    <div className='titulo'>CONCURSOS RELACIONADOS</div>
                                    <div className='conteudo'>
                                        {concursoRelacoes.map((concurso) => {
                                            return (
                                                <React.Fragment key={concurso.id}>
                                                    <div className='concurso' key={concurso.id}>
                                                        <a href={`/concursosdeti/${concurso.id}`}><img src={`/assets/images/concursos/${concurso.id}.jpg`} alt='imagem do concurso relacionado'></img></a>
                                                        <div className='relacoesInfo'>
                                                            {concurso.dataedital !== '0000-00-00' && <a className='sigla' href={`/concursosdeti/${concurso.id}`}><h4>{`${concurso.sigla} - ${new Date(concurso.dataedital).getFullYear()}`}</h4></a>}
                                                            {concurso.dataedital === '0000-00-00' && <a className='sigla' href={`/concursosdeti/${concurso.id}`}><h4>{`${concurso.sigla} (previsto)`}</h4></a>}
                                                            <p>{`${concurso.cargo} - ${concurso.especialidade}`}</p>
                                                        </div>
                                                    </div>
                                                    <div className='rule'></div>
                                                </React.Fragment>
                                            )
                                        })}
                                    </div> </>}
                                <div className='titulo'>COMO ESTUDAR</div>
                                <iframe title='video' width='262.5px' height='135px' src='https://player.vimeo.com/video/457284680?controls=1' allowFullScreen ></iframe>
                            </ConcursosRelacionados>
                        </Relacoes>
                    </Wrapper>
                </PlanoDeEstudoStyle>}
        </>
    )
}

export default PlanoDeEstudo;