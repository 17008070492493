import { FaixaBlack } from './styles/planosPrecos.style';

const FaixaBlackFriday = () => {
return(
    <FaixaBlack>
        <div> Promo Double Black está no ar!</div>
        <p>Você paga um plano Bi ou Tri e dobra a sua vigência.</p>
    </FaixaBlack>
)
}

export default FaixaBlackFriday;