import { CursosRecentesEmDestaqueStyle, Carrossel, Track, Card, AnimationController } from "./styles/cursosRecentesEmDestaque.style";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { API_URL } from '../config';
import { faStar, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import CardCurso from './cardCurso';
import { useState, useEffect } from "react";
import axios from "axios";
import CarregamentoWidget from "./carregamentoWidget";

const CursosRecentesEmDestaque = () => {

    interface CursosDestaque {
        count: number,
        curso_info_id: string,
        nome: string,
        data: string,
        descricao: string,
        categoria_info_id: number,
        destaque: number,
    }

    const [cursosCategoria1, setCursosCategoria1] = useState<CursosDestaque[]>();
    const [cursosCategoria2, setCursosCategoria2] = useState<CursosDestaque[]>();
    const [cursosCategoria3, setCursosCategoria3] = useState<CursosDestaque[]>();
    const [cursosCategoria4, setCursosCategoria4] = useState<CursosDestaque[]>();

    const [controller1, setController1] = useState<HTMLElement | null>();
    const [controller2, setController2] = useState<HTMLElement | null>();
    const [controller3, setController3] = useState<HTMLElement | null>();
    const [controller4, setController4] = useState<HTMLElement | null>();

    const [track1, setTrack1] = useState<HTMLElement | null>();
    const [track2, setTrack2] = useState<HTMLElement | null>();
    const [track3, setTrack3] = useState<HTMLElement | null>();
    const [track4, setTrack4] = useState<HTMLElement | null>();

    const [card, setCard] = useState<HTMLElement | null>();

    useEffect(() => {
        
        const getCursosCategoria1 = async () => {
            const response = await axios.get(`${API_URL}/api/cursos/get/cursosRecentesEmDestaquePorCategoria`, {
                params: {
                    id: 1
                }
            })
            setCursosCategoria1(response.data.results);
        }

        const getCursosCategoria2 = async () => {
            const response = await axios.get(`${API_URL}/api/cursos/get/cursosRecentesEmDestaquePorCategoria`, {
                params: {
                    id: 2
                }
            })
            setCursosCategoria2(response.data.results);
        }

        const getCursosCategoria3 = async () => {
            const response = await axios.get(`${API_URL}/api/cursos/get/cursosRecentesEmDestaquePorCategoria`, {
                params: {
                    id: 3
                }
            })
            setCursosCategoria3(response.data.results);
        }

        const getCursosCategoria4 = async () => {
            const response = await axios.get(`${API_URL}/api/cursos/get/cursosRecentesEmDestaquePorCategoria`, {
                params: {
                    id: 4
                }
            })
            setCursosCategoria4(response.data.results);
        }

        getCursosCategoria1();
        getCursosCategoria2();
        getCursosCategoria3();
        getCursosCategoria4();

    }, []);

    useEffect(() => {

        const setElements = () => {
            setTrack1(document.getElementById('track1'));
            setTrack2(document.getElementById('track2'));
            setTrack3(document.getElementById('track3'));
            setTrack4(document.getElementById('track4'));
            setController1(document.getElementById('controller1'));
            setController2(document.getElementById('controller2'));
            setController3(document.getElementById('controller3'));
            setController4(document.getElementById('controller4'));
            setCard(document.getElementById('card-curso'));
        }

        setElements();

    });

    let count = 0;
    let direction: number;

    const prevTrack = (track: HTMLElement | null | undefined, controller: HTMLElement | null | undefined) => {

        if (track && controller && card) {

            direction = 1;
            count--;

            track.style.justifyContent = 'flex-end';

            if (track.lastElementChild)

            track.prepend(track.lastElementChild);
            track.style.transition = 'none';
            track.style.transform = `translateX(-${(card.offsetWidth) + 36}px)`;

            setTimeout(function() {
                if (track) {
                    track.style.transition = 'transform 1s ease';
                    track.style.transform = `translateX(0)`;
                }
            });

            controller.style.transform = `translateX(${count * 1}px)`;

        }

    }

    const nextTrack = (track: HTMLElement | null | undefined, controller: HTMLElement | null | undefined) => {

        if (track && controller && card) {

            direction = -1;
            count++;

            track.style.justifyContent = 'flex-start';

            track.style.transform = `translateX(-${(card.offsetWidth) + 36}px)`;

            controller.style.transform = `translateX(${count * -1}px)`;

        }

    }

    
    if (controller1 && track1) {
        controller1.addEventListener('transitionend', function(e) {
            
            if (track1.firstElementChild) {
                if (direction === -1) {
                    track1.appendChild(track1.firstElementChild);
                }
                track1.style.transition = 'none';
                track1.style.transform = 'translate(0)';
                setTimeout(function() {
                    if (track1)
                    track1.style.transition = 'transform 1s ease';
                });
            }
        })
    }

    if (controller2 && track2) {
        controller2.addEventListener('transitionend', function(e) {
            
            if (track2.firstElementChild) {
                if (direction === -1) {
                    track2.appendChild(track2.firstElementChild);
                }
                track2.style.transition = 'none';
                track2.style.transform = 'translate(0)';
                setTimeout(function() {
                    if (track2)
                    track2.style.transition = 'transform 1s ease';
                });
            }
        })
    }

    if (controller3 && track3) {
        controller3.addEventListener('transitionend', function(e) {
            
            if (track3.firstElementChild) {
                if (direction === -1) {
                    track3.appendChild(track3.firstElementChild);
                }
                track3.style.transition = 'none';
                track3.style.transform = 'translate(0)';
                setTimeout(function() {
                    if (track3)
                    track3.style.transition = 'transform 1s ease';
                });
            }
        })
    }

    if (controller4 && track4) {
        controller4.addEventListener('transitionend', function(e) {
            
            if (track4.firstElementChild) {
                if (direction === -1) {
                    track4.appendChild(track4.firstElementChild);
                }
                track4.style.transition = 'none';
                track4.style.transform = 'translate(0)';
                setTimeout(function() {
                    if (track4)
                    track4.style.transition = 'transform 1s ease';
                });
            }
        })
    }

    return (
        <>
        {!cursosCategoria1 && !cursosCategoria2 && !cursosCategoria3 && !cursosCategoria4 ? <CarregamentoWidget /> :
        <CursosRecentesEmDestaqueStyle>
            <div className="titulo">
                <h2>CURSOS DE TI PARA CONCURSOS</h2>
                <p>POR ALGUNS DOS MELHORES PROFESSORES DO BRASIL</p>
                <span><FontAwesomeIcon className="icon" icon={faStar} /></span>
            </div>

            <div className="scale-wrapper">
            <div className="wrapper">
                <Carrossel>
                    {cursosCategoria2 && <Track id="track1">
                        {cursosCategoria2.map((curso) => {
                            return (
                                <Card key={curso.curso_info_id} id="card-curso">
                                    <CardCurso id={curso.curso_info_id}
                                            nome_curto={curso.nome}
                                            descricao={curso.descricao}
                                            aulas_count={curso.count}
                                            cardStyle=''
                                            data={curso.data}
                                            destaque={true}
                                    />
                                </Card>
                            )
                        })}
                    </Track>}
                </Carrossel>
                <button className="btn prev" onClick={() => prevTrack(track1, controller1)}><FontAwesomeIcon icon={faAngleLeft} /></button>
                <button className="btn next" onClick={() => nextTrack(track1, controller1)}><FontAwesomeIcon icon={faAngleRight} /></button>
            </div>

            <div className="wrapper">
                <Carrossel>
                    {cursosCategoria3 && <Track id="track2">
                        {cursosCategoria3.map((curso) => {
                            return (
                                <Card key={curso.curso_info_id}>
                                    <CardCurso id={curso.curso_info_id}
                                            nome_curto={curso.nome}
                                            descricao={curso.descricao}
                                            aulas_count={curso.count}
                                            cardStyle=''
                                            data={curso.data}
                                            destaque={true}
                                    />
                                </Card>
                            )
                        })}
                    </Track>}
                </Carrossel>
                <button className="btn prev" onClick={() => prevTrack(track2, controller2)}><FontAwesomeIcon icon={faAngleLeft} /></button>
                <button className="btn next" onClick={() => nextTrack(track2, controller2)}><FontAwesomeIcon icon={faAngleRight} /></button>
            </div>

            <div className="wrapper">
                <Carrossel>
                    {cursosCategoria4 && <Track id="track3">
                        {cursosCategoria4.map((curso) => {
                            return (
                                <Card key={curso.curso_info_id}>
                                    <CardCurso id={curso.curso_info_id}
                                            nome_curto={curso.nome}
                                            descricao={curso.descricao}
                                            aulas_count={curso.count}
                                            cardStyle=''
                                            data={curso.data}
                                            destaque={true}
                                    />
                                </Card>
                            )
                        })}
                    </Track>}
                </Carrossel>
                <button className="btn prev" onClick={() => prevTrack(track3, controller3)}><FontAwesomeIcon icon={faAngleLeft} /></button>
                <button className="btn next" onClick={() => nextTrack(track3, controller3)}><FontAwesomeIcon icon={faAngleRight} /></button>
            </div>

            <div className="wrapper">
                <Carrossel>
                    {cursosCategoria1 && <Track id="track4">
                        {cursosCategoria1.map((curso) => {
                            return (
                                <Card key={curso.curso_info_id}>
                                    <CardCurso id={curso.curso_info_id}
                                            nome_curto={curso.nome}
                                            descricao={curso.descricao}
                                            aulas_count={curso.count}
                                            cardStyle=''
                                            data={curso.data}
                                            destaque={true}
                                    />
                                </Card>
                            )
                        })}
                    </Track>}
                </Carrossel>
                <button className="btn prev" onClick={() => prevTrack(track4, controller4)}><FontAwesomeIcon icon={faAngleLeft} /></button>
                <button className="btn next" onClick={() => nextTrack(track4, controller4)}><FontAwesomeIcon icon={faAngleRight} /></button>
            </div>
            </div>

            <AnimationController id="controller1"></AnimationController>
            <AnimationController id="controller2"></AnimationController>
            <AnimationController id="controller3"></AnimationController>
            <AnimationController id="controller4"></AnimationController>

        </CursosRecentesEmDestaqueStyle>}
        </>
    )
}

export default CursosRecentesEmDestaque;