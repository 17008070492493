import { AtualizacoesStyle, Wrapper, GravacoesInfo, Gravacoes, CursosDestaque } from './styles/Atualizacoes.style';
import { Button } from '../componentes/styles/cardPlanoAnual.style';
import CabecalhoInfo from '../componentes/cabecalhoInfo';
import CabecalhoMenu from '../componentes/cabecalhoMenu';
import Rodape from '../componentes/rodape';
import CarregamentoWidget from '../componentes/carregamentoWidget';
import IrAoTopoWidget from '../componentes/irAoTopoWidget';
import InstrucaoNutror from '../componentes/instrucaoNutror';
import { API_URL } from '../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo, faFilePdf, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { useState, useEffect } from 'react';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import SeoHelmet from '../componentes/seoHelmet';
import CardConcurso from '../componentes/cardConcurso';
import './styles/main.scss';

const Busca = () => {

    interface CursoBuscaResultado {
        curso_id: string,
        curso_nome: string,
        aula_keywords: string,
        aula_id: number,
        aula_nome: string,
        aula_tipo: number,
        aula_controle: number,
        modulo_nome: string,
        modulo_id: string,
        linknutrormod: string,
        professor_nome: string,
        aula_duracao: string
    }

    interface ConcursoBuscaResultado {
        id: number;
        sigla: string;
        cargo: string;
        orgao: string;
        especialidade: string;
        textochamada: string;
        imagem: string;
    }

    interface Destaque {
        id: string,
        nome: string
    }

    interface Categorias {
        id: number,
        nome_curto: string,
        nome: string
    }

    interface Diciplinas {
        id: string,
        nome: string,
        categoria_info_id: number
    }

    interface Professores {
        id: number,
        nome: string,
        coluna: number
    }

    const [cursoBuscaResultado, setCursoBuscaResultado] = useState<CursoBuscaResultado[]>([]);
    const [concursoBuscaResultado, setConcursoBuscaResultado] = useState<ConcursoBuscaResultado[]>([]);
    const [destaques, setDestaques] = useState<Destaque[]>();
    const [categoriaSelected, setCategoriaSelected] = useState<string>('>= 1');
    const [disciplinaSelected, setDisciplinaSelected] = useState<string>('>= 1');
    const [professorSelected, setProfessorSelected] = useState<string>('0');
    const [termoBuscaAvancada, setTermoBuscaAvancada] = useState<string>('');
    const [termoBuscaText, setTermoBuscaText] = useState<string | undefined>();
    const [categorias, setCategorias] = useState<Categorias[]>();
    const [disciplinas, setDisciplinas] = useState<Diciplinas[]>();
    const [professores, setProfessores] = useState<Professores[]>();
    const [validTermo, setValidTermo] = useState<string | null>(null);
    const [tipoPesquisa, setTipoPesquisa] = useState<string>('cursos');
    const [primeiroLoad, setPrimeiroLoad] = useState<boolean>(true);

    const [carregando, setCarregando] = useState<boolean>(false);

    const termoCaracterValidation = (termo: string) => {
        return /^[a-zA-Z0-9ãçáéóíúâêàÃÇÁÉÓÍÚÂÊÀ+ ]*$/.test(termo);
    }

    const termoMaxLengthValidation = (termo: string) => {
        return (termo.length < 128);
    }

    const handleTermoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!termoCaracterValidation(event.target.value)) {
            setValidTermo('Sem caracteres especiais.');
        }
        else if (!termoMaxLengthValidation(event.target.value)) {
            setValidTermo('Termo de busca muito longo.');
        }
        else {
            setValidTermo(null);
        }

        setTermoBuscaAvancada(event.target.value);
    }

    useEffect(() => {

        const getCursosEmDestaque = async () => {
            const response = await axios.get(`${API_URL}/api/cursos/get/cursosEmDestaque`);
            setDestaques(response.data.results);
        }

        const getCategorias = async () => {
            const response = await axios.get(`${API_URL}/api/cursos/get/categorias`)
            setCategorias(response.data.results);
        }

        const getDisciplinas = async () => {
            const response = await axios.get(`${API_URL}/api/cursos/get/disciplinas`)
            setDisciplinas(response.data.results);
        }

        const getProfessores = async () => {
            const response = await axios.get(`${API_URL}/api/professores/get/professoresMenu`)
            setProfessores(response.data.results);
        }

        getCursosEmDestaque();
        getCategorias();
        getDisciplinas();
        getProfessores();

    }, []);

    const handleBuscaAvancada = async () => {
        if (!validTermo && tipoPesquisa === 'cursos') {
            setCarregando(true);
            const response = await axios.get(`${API_URL}/api/busca/get/cursoBuscaPorTermoECategoriaEDisciplinaEProfessor`, {
                params: {
                    termo: termoBuscaAvancada,
                    categoria: categoriaSelected,
                    disciplina: disciplinaSelected,
                    professor: professorSelected
                }
            })
            setConcursoBuscaResultado([]);
            setCursoBuscaResultado(response.data.results);
            setTermoBuscaText(termoBuscaAvancada);
            setPaginaNum(0);
            setPrimeiroLoad(false);
            setCarregando(false);
        }
        else if (!validTermo && tipoPesquisa === 'concursos') {
            setCarregando(true);
            const response = await axios.get(`${API_URL}/api/busca/get/concursoBuscaPorTermo`, {
                params: {
                    termo: termoBuscaAvancada,
                }
            })
            setCursoBuscaResultado([]);
            setConcursoBuscaResultado(response.data.result);
            setTermoBuscaText(termoBuscaAvancada);
            setPaginaNum(0);
            setPrimeiroLoad(false);
            setCarregando(false);
        }
    }

    const postTermoBusca = async () => {
        if (!validTermo && termoBuscaAvancada.length >= 1) {
            await axios.post(`${API_URL}/api/busca/post/termoBusca`, {
                termo_busca: termoBuscaAvancada, 
                professor_busca: professorSelected
            })
        }
    };

    const [paginaNum, setPaginaNum] = useState<number>(0);

    const resultadosPorPagina: number = 100;
    const resultadosVisitadas: number = paginaNum * resultadosPorPagina;

    let paginasCount = Math.ceil(cursoBuscaResultado.length / resultadosPorPagina);

    const displayResultados = cursoBuscaResultado.slice(resultadosVisitadas, (resultadosVisitadas + resultadosPorPagina))
    .map((modulo, index) => {
        if (cursoBuscaResultado.slice(resultadosVisitadas, (resultadosVisitadas + resultadosPorPagina))[index + 1] === undefined || (cursoBuscaResultado.slice(resultadosVisitadas, (resultadosVisitadas + resultadosPorPagina))[index + 1].modulo_id !== modulo.modulo_id)) {
            return (
                <>
                    <tr className='modulo-title'>
                        <th colSpan={4} className='modulo-info'>
                            <div className='flex'>
                                <div>
                                    <p><a href={`/cursos/${modulo.curso_id}`} target="_blank" rel='noreferrer'>{`Curso (CR${modulo.curso_id}): ${modulo.curso_nome}`}</a></p>
                                    <p><a href={`/cursos/${modulo.curso_id}`} target="_blank" rel='noreferrer'>{`Módulo (MD${modulo.modulo_id}): ${modulo.modulo_nome} - Prof. ${modulo.professor_nome}`}</a></p>
                                </div>
                                <div className='nutror-icon'>
                                    <a href={modulo.linknutrormod} target="_blank" rel='noreferrer'><img src='/assets/images/nutror-icon.png' alt='ícone Nutror'></img></a>
                                </div>
                            </div>
                        </th>
                    </tr>
                
                    {cursoBuscaResultado.slice(resultadosVisitadas, (resultadosVisitadas + resultadosPorPagina)).map((aula) => {
                        if (aula.modulo_id === modulo.modulo_id && aula.curso_id === modulo.curso_id) {
                            
                            return (
                                <tr key={aula.aula_id} className='aula-row'>
                                    <>{(aula.aula_tipo === 1) ? <td data-label="NOME DA AULA" className='aula-nome'><div className='table-content-wrapper'><FontAwesomeIcon icon={faVideo} color='#27921A' /> {`(AL${aula.aula_nome}) ${aula.aula_nome}`}</div></td> : <td data-label="NOME DA AULA" className='aula-nome'><div className='table-content-wrapper'><FontAwesomeIcon icon={faFilePdf} color='#27921A' /> {`(AL${aula.aula_id}) ${aula.aula_nome}`}</div></td>}</>
                                    <td data-label="TERMOS-CHAVE"><div className='table-content-wrapper'>{aula.aula_keywords}</div></td>
                                    <>{(aula.aula_tipo === 1) ? <td data-label="DURAÇÃO" className='center duracao'><div className='table-content-wrapper'><FontAwesomeIcon icon={faClock} /> <span>{aula.aula_duracao}</span></div></td> : <td data-label="DURAÇÃO" className='center duracao'><div className='table-content-wrapper'><FontAwesomeIcon icon={faClock} /> <span>PDF</span></div></td>}</>
                                </tr>
                            )
                        }
                        return null;
                    })}
                    <tr className='space'></tr>
                </>
            )
        }
        return null;
    });

    const changePage = ({ selected }:any) => {
        setPaginaNum(selected);
        window.scrollTo({top: 0, behavior: 'smooth'});
    };

    return (
        <>
            <SeoHelmet 
                conector=' - '
                subtitulo='Busque por Assunto'
                linkcanonical='busca'
            />
            <CabecalhoInfo />
            <CabecalhoMenu />

            {(!cursoBuscaResultado[0] && !destaques && !categorias && !disciplinas && !professores) || carregando ? <CarregamentoWidget /> :

            <AtualizacoesStyle>
                <div className='pag-titulo pag-titulo-flex'>

                    <div className='pesquisa-options'>

{/*                         <p>Pesquisar por:</p> */}

                        <label htmlFor='cursos' className='radio'>
                            <input className='radio-input' type='radio' id='cursos' name='tipo-pesquisa' value={tipoPesquisa} checked={tipoPesquisa === 'cursos'} onChange={() => {setTipoPesquisa('cursos'); setTermoBuscaAvancada('')}}></input>
                            <span>Cursos e Módulos</span>
                        </label>

                        <label htmlFor='concursos' className='radio'>
                            <input className='radio-input' type='radio' id='concursos' name='tipo-pesquisa' value={tipoPesquisa} checked={tipoPesquisa === 'concursos'} onChange={() => {setTipoPesquisa('concursos'); setTermoBuscaAvancada('')}}></input>
                            <span>Planos de Estudo</span>
                        </label>

                    </div>

                    <div className='titulo-flex'>

                        {(tipoPesquisa === 'cursos') && <>

                            <input type='text' placeholder='Digite assunto, tópico etc.' onChange={handleTermoChange}></input>

{/*                             <select value={categoriaSelected} onChange={(event) => setCategoriaSelected(event.target.value)}>
                                <option value='>= 1'>Todas as Categorias</option>
                                {categorias && categorias.map((categoria) => {
                                    return (
                                        <option value={`= ${categoria.id}`}>{categoria.nome_curto}</option>
                                    )
                                })}
                            </select> */}

{/*                             <select value={disciplinaSelected} onChange={(event) => setDisciplinaSelected(event.target.value)}>
                                <option value='>= 1'>Todas as Disciplinas</option>
                                {disciplinas && disciplinas.map((disciplina) => {
                                    if (categoriaSelected === '>= 1') {
                                        return (
                                            <option value={`= ${disciplina.id}`}>{disciplina.nome}</option>
                                        )
                                    }
                                    else if (categoriaSelected.includes(disciplina.categoria_info_id.toString())) {
                                        return (
                                            <option value={`= ${disciplina.id}`}>{disciplina.nome}</option>
                                        )
                                    }
                                    else return null;
                                })}
                            </select> */}

{/*                             <select value={professorSelected} onChange={(event) => setProfessorSelected(event.target.value)}>
                                <option value='0'>Todos os Professores</option>
                                {professores && professores.map((professor) => {
                                    return (
                                        <option value={professor.id}>{professor.nome}</option>
                                    )
                                })}
                            </select> */}

                        </>}

                        {(tipoPesquisa === 'concursos') && <>
                        
                            <input type='text' placeholder='Digite órgão, cargo etc.' onChange={handleTermoChange}></input>
                        
                        </>}

                    </div>

                    {validTermo && <p>{validTermo}</p>}

                    <Button className='btn' onClick={() => {postTermoBusca(); handleBuscaAvancada()}}>PROCURAR <FontAwesomeIcon icon={faMagnifyingGlass} /> </Button>

                </div>

                <Wrapper>
                    <GravacoesInfo>
                        <Gravacoes>

                            {primeiroLoad ?
                                <div className='titulo'>
                                    <h2>Digite os termos de busca e clique em 'PROCURAR'.</h2>
                                </div>
                                :
                                <div className='titulo'>
                                    <div className='section-title'>
                                        {(termoBuscaText !== undefined) ? <h2>{<FontAwesomeIcon icon={faMagnifyingGlass} />} {`- '${termoBuscaText} ...'`}</h2> : <h2>{<FontAwesomeIcon icon={faMagnifyingGlass} />} {`- ' ...'`}</h2>}
                                        <div className='color-rule'></div>
                                    </div>
                                    {(cursoBuscaResultado && cursoBuscaResultado[0]) && <InstrucaoNutror expanda={false} />}
                                </div>
                            }

                            {((cursoBuscaResultado && cursoBuscaResultado[0]) || (concursoBuscaResultado && concursoBuscaResultado[0])) &&
                            
                            <>

                                {(cursoBuscaResultado && cursoBuscaResultado[0]) && <>
                                
                                    <div>
                                        <table>
                                            <thead>
                                                <tr className='table-title'>
                                                    <th className='a'>NOME DA AULA</th>
                                                    <th className='b'>TERMOS-CHAVE</th>
                                                    <th className='c'>DURAÇÃO</th>
                                                </tr>
                                                <tr className='space'></tr>
                                            </thead>
                                        
                                            <tbody>
                                                {displayResultados}
                                            </tbody>
                                        </table>
                                    </div>
                                    
                                    <ReactPaginate 
                                        previousLabel={"Anterior"}
                                        nextLabel={"Próximo"}
                                        pageCount={paginasCount}
                                        onPageChange={changePage}
                                        containerClassName={"pagination-btns"}
                                        disabledClassName={"disbaled-btn"}
                                        activeClassName={"active-btn"}
                                        breakClassName={"break"}
                                    />

                                </>}

                                {(concursoBuscaResultado && concursoBuscaResultado[0]) && <div className='conteudo-flex'>
                                    {concursoBuscaResultado.map((concurso) => {
                                        return <CardConcurso id={concurso.id}
                                                            titulo={`${concurso.sigla} (${concurso.cargo} - ${concurso.especialidade})`} 
                                                            descricao={concurso.textochamada}
                                                            imagem={`${concurso.id}.jpg`}
                                        />
                                    })}
                                </div>}

                            </>}

                            {(!primeiroLoad && !((cursoBuscaResultado && cursoBuscaResultado[0]) || (concursoBuscaResultado && concursoBuscaResultado[0]))) && <h2>Nenhum Resultado Encontrado.</h2>}

                        </Gravacoes>
                    </GravacoesInfo>
                    {destaques && <CursosDestaque>
                        <div className='titulo'>CURSOS EM DESTAQUE</div>
                        <div className='conteudo'>
                            {destaques.map((curso) => {
                                return (
                                    <>
                                        <div className='curso' key={curso.id}>
                                            <a href={`/cursos/${curso.id}`}><img src={`/assets/images/courses/${curso.id}_vitrine.jpg`} alt='imagem do curso relacionad'></img></a>
                                            <div className='relacoesInfo'>
                                                <a href={`/cursos/${curso.id}`}><h4>{`CR-${curso.id}`}</h4></a>
                                                <p>{curso.nome}</p>
                                            </div>
                                        </div>
                                        <div className='rule'></div>
                                    </>
                                )
                            })}
                        </div>
                    </CursosDestaque>}
                </Wrapper>
            </AtualizacoesStyle>}

            <Rodape />

            <IrAoTopoWidget />
        </>
    )
}

export default Busca;