import { HeaderMenu } from './styles/cabecalho.style';
import { API_URL } from '../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faMagnifyingGlass, faAngleRight, faBell, faPlus, faGraduationCap, faBars } from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "axios";

const CabecalhoMenu = () => {

    interface Categorias {
        id: number,
        nome_curto: string,
        nome: string
    }

    interface Diciplinas {
        id: string,
        nome: string,
        categoria_info_id: number
    }

    interface Professores {
        id: number,
        nome: string,
        coluna: number
    }

    const [categorias, setCategorias] = useState<Categorias[]>();
    const [disciplinas, setDisciplinas] = useState<Diciplinas[]>();
    const [professores, setProfessores] = useState<Professores[]>();
    const [isHovering, setIsHovering] = useState<boolean>(false);
    const [navMenuId, setNavMenuId] = useState<string>();

    const navigate = useNavigate();

    const getCategorias = async () => {
        const response = await axios.get(`${API_URL}/api/cursos/get/categorias`)
        setCategorias(response.data.results);
    }

    const getDisciplinas = async () => {
        const response = await axios.get(`${API_URL}/api/cursos/get/disciplinas`)
        setDisciplinas(response.data.results);
    }

    const getProfessores = async () => {
        const response = await axios.get(`${API_URL}/api/professores/get/professoresMenu`)
        setProfessores(response.data.results);
    }

    const handleMouseOver = (navId: string) => {
        setIsHovering(true);
        setNavMenuId(navId);
    }

    const handleMouseOut = () => {
        setIsHovering(false);
    }

    useEffect(() => {
        getCategorias();
        getDisciplinas();
        getProfessores();
    }, []);


    return (
        <HeaderMenu>
            <div className="header-navigation" id="fix-header">
                <div className="logo">
                    <a href="/">
                        <img src="/assets/images/logo-color-1.png" alt="logo"></img>
                    </a>
                    <a href="/">
                        <img className="anos" src="/assets/images/logo-color-anos.png" alt="logo anos"></img>
                        <div className="anos-count"></div>
                    </a>
                </div>

                <div className="navbar">
                    <ul className="nav-list">
                        <a href="/"><li className="nav-animation">HOME</li></a>

                        <a href="/mentorias"><li className="nav-animation">MENTORIAS</li></a>

                        <li onMouseOver={() => handleMouseOver('aulas')} onMouseOut={handleMouseOut} className="nav-animation">
                            AULAS 
                            <FontAwesomeIcon className="dropdown-icon" icon={faAngleDown} width="8px"/>
                            <div className={(isHovering && navMenuId === 'aulas') ? 'dropdown-menu visible aulas-position' : 'dropdown-menu'} >
                                <div className="menu-flex-item">
                                    <p className="categoria hover">POR CATEGORIA <FontAwesomeIcon icon={faAngleRight} /></p>
                                    <a href="/atualizacoes"><p className="hover"><FontAwesomeIcon icon={faBell} fontSize='12px' color='#27921A' /> Aulas + Recentes</p></a>
                                    <a href="/cursos/todos"><p className="hover"><FontAwesomeIcon icon={faPlus} fontSize='12px' color='#27921A' /> Todos os Cursos</p></a>
                                    <a href="/cursos/categoria/5"><p className="hover"><FontAwesomeIcon icon={faGraduationCap} fontSize='12px' color='#27921A' /> Aulões ao Vivo</p></a>
                                </div>
                                
                                {categorias && categorias.map((categoria) => {
                                    if (categoria.id !== 5) {
                                        return (
                                            <div key={categoria.id} className="menu-flex-item">
                                                <a href={`/cursos/categoria/${categoria.id}`}><p className="categoria hover">{categoria.nome_curto}</p></a>
                                                {disciplinas && disciplinas.map((disciplina) => {
                                                    if (disciplina.categoria_info_id === categoria.id) {
                                                        return (
                                                            <a key={disciplina.id} href={`/cursos/disciplina/${disciplina.id}`}><p className="hover">{disciplina.nome}</p></a>
                                                        )
                                                    }
                                                    return null;
                                                })}
                                            </div>
                                        )
                                    }
                                    return null;
                                })}
                            </div>
                        </li>
                        
                        <li onMouseOver={() => handleMouseOver('planos de estudo')} onMouseOut={handleMouseOut} className="nav-animation">
                            PLANOS DE ESTUDO 
                            <FontAwesomeIcon className="dropdown-icon" icon={faAngleDown} width="8px"/>
                            <div className={(isHovering && navMenuId === 'planos de estudo') ? "dropdown-menu visible plano-position" : "dropdown-menu"}>
                                <div className="plano-wrapper">
                                    <a href="/concursosdeti/abertos"><p className="plano">Inscrições Abertas</p></a>
                                    <a href="/concursosdeti/em-andamento"><p className="plano">Concursos Em Andamento</p></a>
                                    <a href="/concursosdeti/encerrados"><p className="plano">Concursos Encerrados</p></a>                                    
                                    <a href="/concursosdeti/previstos"><p className="plano">Concursos Previstos</p></a>                                    
                                </div>
                            </div>
                        </li>
                        
                        <li onMouseOver={() => handleMouseOver('por autor')} onMouseOut={handleMouseOut} className="nav-animation">
                            POR AUTOR 
                            <FontAwesomeIcon className="dropdown-icon" icon={faAngleDown} width="8px"/>
                            <div className={(isHovering && navMenuId === 'por autor') ? "dropdown-menu visible autor-position" : "dropdown-menu"}>
                                <div className="menu-flex-item">
                                    {professores && professores.map((professor) => {
                                        if (professor.coluna === 1) {
                                            return (
                                                <a key={professor.id} href={`/professores/${professor.id}`}><p className="hover">{professor.nome}</p></a>
                                            )
                                        }
                                        return null;
                                    })}
                                </div>

                                <div className="menu-flex-item">
                                    {professores && professores.map((professor) => {
                                        if (professor.coluna === 2) {
                                            return (
                                                <a key={professor.id} href={`/professores/${professor.id}`}><p className="hover">{professor.nome}</p></a>
                                            )
                                        }
                                        return null;
                                    })}
                                </div>

                                <div className="menu-flex-item">
                                    {professores && professores.map((professor) => {
                                        if (professor.coluna === 3) {
                                            return (
                                                <a key={professor.id} href={`/professores/${professor.id}`}><p className="hover">{professor.nome}</p></a>
                                            )
                                        }
                                        return null;
                                    })}
                                </div>
                            </div>
                        </li>

                        <a href="/planosdeassinatura"><li className="nav-animation">ASSINE</li></a>

                        <li className="search">
                            <div className="icon-div" onClick={() => navigate('/busca')}><FontAwesomeIcon className="dropdown-icon" icon={faMagnifyingGlass}/></div>
                        </li>
                    </ul>

                    <div className="mobile-icon">
                        <input className="menu-toggle check-input" type='checkbox' id='mobile-icon-check'></input>
                        <label className="mobile-icon-label" htmlFor="mobile-icon-check"><FontAwesomeIcon icon={faBars} /></label>

                        <div className="menu-mobile">
                            <div className="menu-item">
                                <a href="/"><label className="menu-item-label">HOME</label></a>
                            </div>

                            <div className="menu-item">
                                <input type='checkbox' id="aulas" className="aulas-check check-input"></input>
                                <label className="menu-item-label" htmlFor="aulas">AULAS</label>

                                <div className="dropdown-list">

                                    <a className="line-block" href="/atualizacoes"><FontAwesomeIcon className="aulas-icon" icon={faBell} fontSize='12px' color='#27921A' /><p className="aulas-lbl">Aulas + Recentes</p></a>
                                    <a className="line-block" href="/cursos/todos"><FontAwesomeIcon className="aulas-icon" icon={faPlus} fontSize='12px' color='#27921A' /><p className="aulas-lbl">Todos os Cursos</p></a>
                                    <a className="line-block" href="/cursos/categoria/5"><FontAwesomeIcon className="aulas-icon" icon={faGraduationCap} fontSize='12px' color='#27921A' /><p className="aulas-lbl">Aulões ao Vivo</p></a>

                                    {categorias && categorias.map((categoria) => {
                                        if (categoria.id !== 5) {
                                            return (
                                                <div key={categoria.id}>
                                                    <input className="check-input" type='checkbox' id={`${categoria.id}`}></input>
                                                    <label className="list-label" htmlFor={`${categoria.id}`}><FontAwesomeIcon className="icon-label-right" icon={faAngleRight} /> <FontAwesomeIcon className="icon-label-down" icon={faAngleDown} /></label>
                                                    <a href={`/cursos/categoria/${categoria.id}`}><p>{categoria.nome_curto}</p></a>
                                                    <div className="disciplina-list">
                                                        {disciplinas && disciplinas.map((disciplina) => {
                                                            if (disciplina.categoria_info_id === categoria.id) {
                                                                return (
                                                                    <a key={disciplina.id} href={`/cursos/disciplina/${disciplina.id}`}><p>{disciplina.nome}</p></a>
                                                                )
                                                            }
                                                            return null;
                                                        })}
                                                    </div>
                                                </div>
                                            )
                                        }
                                        return null;
                                    })}
                                </div>
                            </div>

                            <div className="menu-item">
                                <input type='checkbox' id="planos" className="planos-check check-input"></input>
                                <label className="menu-item-label" htmlFor="planos">PLANOS DE ESTUDO</label>

                                <div className="dropdown-list">
                                    <a href="/concursosdeti/abertos"><p>Inscrições Abertas</p></a>
                                    <a href="/concursosdeti/em-andamento"><p>Concursos Em Andamento</p></a>
                                    <a href="/concursosdeti/encerrados"><p>Concursos Encerrados</p></a>
                                    <a href="/concursosdeti/previstox"><p>Concursos Previstos</p></a>
                                </div>
                            </div>

                            <div className="menu-item">
                                <input type='checkbox' id="autor" className="autor-check check-input"></input>
                                <label className="menu-item-label" htmlFor="autor">POR AUTOR</label>

                                <div className="dropdown-list">
                                    {professores && professores.map((professor) => {
                                        return (
                                            <a key={professor.id} href={`/professores/${professor.id}`}><p>{professor.nome}</p></a>
                                        )
                                    })}
                                </div>
                            </div>

                            <div className="menu-item">
                                <a href="/planosdeassinatura"><label className="menu-item-label">ASSINE</label></a>
                            </div>

                             <div className="menu-item">
                                <a href='/busca'><label className="menu-item-label"><FontAwesomeIcon className="dropdown-icon" icon={faMagnifyingGlass}/></label></a>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </HeaderMenu>
    )
}

export default CabecalhoMenu;