import styled from 'styled-components';

export const Footer = styled.footer`
    
    background-color: #242C42;

    .footer-info {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        padding: 50px 20% 70px 20%;
        margin: auto;
    }

    .flexbox {
        margin: 10px 20px;
    }

    p.title {
        color: #27921A;
        margin: 20px 0 40px 0;
        padding: 0;
        font-size: 16px;
        font-weight: bold;
    }

    h4 {
        color: #27921A;
        margin: 20px 0 40px 0;
        padding: 0;
        font-size: 16px;
        font-weight: bold;
    }

    p {
        color: #fff;
        margin: 0;
        padding: 0;
        font-size: 13px;
    }

    .links-uteis {
        display: flex;
        height: fit-content;
        flex-flow: row wrap;
        justify-content: space-between;
    }

    .title-flex {
        flex: 100%;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    li {
        color: #fff;
        font-size: 13px;
        margin-bottom: 20px;
    }

    .icon {
        background-color: #3B4757;
        padding: 7px;
        margin: 15px 15px 0 0;
        border-radius: 3px;

        :hover {
            background-color: #27921A;
            cursor: pointer;
        }
    }

    a {
        text-decoration: none;
        color: #fff;
        padding-left: 5px;

        :hover {
            text-decoration: underline #27921A;
            color: #27921A;
        }
    }

    .provas-de-ti {
        li {
            padding-top: 15px;
            border-top: solid 1px #2F374C;
        }

        ul {
            margin-top: 25px;
        }

        a {
            padding-left: 10px;
        }
    }

    .icons-div {
        border-top: solid 1px #2F374C;
        margin-bottom: 25px;

        a {
            margin: 0;
            padding: 0;
            max-width: fit-content;
            max-height: fit-content;
            display: contents;
        }
    }

    .footer-info-2 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: auto;
        padding: 30px 20% 30px 20%;
        border-top: solid 1px #2F374C;

        .tags {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            width: fit-content;
        }

        a {
            color: #4F5B6B;
            font-size: 10px;
            padding: 0 20px 0 0;

            :hover {
                color: #27921A;
            }
        }

        p {
            color: #4F5B6B;
            font-size: 12px;
        }
    }

    @media (max-width: 1800px) {
        .footer-info {
            padding: 50px 10% 70px 10%;
        }

        .footer-info-2 {
            padding: 30px 10% 30px 10%;
        }
    }

    @media (max-width: 1350px) {
        .footer-info {
            padding: 50px 5% 70px 5%;
        }

        .footer-info-2 {
            padding: 30px 5% 30px 5%;
        }
    }

    @media (max-width: 1200px) {
        .footer-info {
            padding: 50px 1% 70px 1%;
        }

        .footer-info-2 {
            padding: 30px 2% 30px 2%;

            a {
                font-size: 8px;
            }

            p {
                font-size: 10px;
            }
        }
    }

`;