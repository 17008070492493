import styled from 'styled-components';

export const DepoimentosCarrosselStyle = styled.div`

    
    background-color: #121828;
    height: 600px;
    
    .wrapper {
        position: relative;
        text-align: center;
    }

    .div {
        text-align: center;
        display: inline-block;
    }

    input {
        position: relative;
        visibility: hidden;
        top: 520px;

        &:after {
            content: '';
            position: absolute;
            background-color: #869791;
            opacity: 0.5;
            width: 12px;
            height: 12px;
            visibility: visible;
            border-radius: 50%;
        }

        &:checked:after {
            background-color: #27921A;
            opacity: 1;
        }

        &:hover {
            cursor: pointer;
            background-color: #869791;
            opacity: 0.5;
        }
    }

    .btn {
        position: absolute;
        top: 280px;
        padding: 10px;

        &:hover {
            cursor: pointer;
        }
    }

    .prev {
        left: 15%;
    }

    .next {
        right: 15%;
    }

    @media (max-width: 1700px) {
        .prev {
            left: 5%;
        }

        .next {
            right: 5%;
        }
    }

    @media (max-width: 1300px) {
        .prev {
            left: 1%;
        }

        .next {
            right: 1%;
        }
    }

    @media (max-width: 1000px) {
        height: 700px;

        input {
            top: 620px;
        }

        .icon-btn {
            font-size: 30px;
        }
    }

    @media (max-width: 550px) {
        height: 800px;

        input {
            top: 720px;
        }
    }

    @media (max-width: 450px) {
        height: 900px;

        input {
            top: 820px;
            margin: 2px;
        }
    }

    @media (max-width: 350px) {
        height: 950px;

        input {
            top: 870px;
        }
    }

`;

export const Slide = styled.div`

    position: absolute;
    background-color: #121828;
    text-align: center;
    height: 450px;
    top: 50px;
    left: 20%;
    bottom: 0;
    right: 20%;
    transition: opacity 1s;
    opacity: 0;
    pointer-events: none;
    

    img {
        width: 142px;
        height: 142px;
        border: 4px solid #27921A;
    }

    h4 {
        font-size: 18px;
        font-style: italic;
        color: #27921A;
        font-weight: 500;
    }

    .aluno {
        font-size: 18px;
        font-style: italic;
        color: #27921A;
        font-weight: 500;
    }

    .info {
        color: #A9A9A9;
        font-size: 14px;
    }

    .txt {
        font-size: 20px;
        color: #FFF;
        line-height: 30px;
    }

    @media (max-width: 1700px) {
        left: 12%;
        right: 12%;
    }

    @media (max-width: 1150px) {
        .txt {
            font-size: 18px;
        }
    }

    @media (max-width: 700px) {
        .txt {
            font-size: 16px;
            text-align: justify;
        }
    }

`;