import styled from 'styled-components';

export const CardMentoriaStyle = styled.div`

    width: fit-content;
    margin: 50px 10.3px;
    text-align: justify;

    .background {
        position: relative;
        width: 360px;
        height: 410px;
        background-color: #FAFAFA;
        box-shadow: 0 -5px 0 #EAEDF5 inset;

        :hover {
            background-color: #FFF;
            box-shadow: 0 -5px 0 #EAEDF5 inset, 0 0 15px rgb(0, 0, 0, 15%);
            transition: all 0.25s ease;
        }

        ::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            height: 5px;
            width: 0%;
            background-color: #242C42;
            transition: all 0.5s ease;
        }

        :hover::after {
            width: 100%;
        }
    }

    .card-concurso-img {
        width: 300px;
        height: auto;
        margin-left: 30px;
        position: relative;
        bottom: 30px;
    }

    .info {
        max-width: 300px;
        margin: auto;
    }

    .link {
        text-align: left;
        text-decoration: none;
        font-size: 20px;
        color: #49575f;
        font-weight: 700;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        :hover {
            text-decoration: underline #27921A;
            color: #27921A;
        }
    }

    p {
        text-align: left;
        font-size: 14px;
        line-height: 23px;
        margin-top: 25px;
        color: #6F8190;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    button {
        position: relative;
        top: 10px;
        left: 103px;
        width: 154px;
        height: 32px;
        border: none;
        background-color: #27921A;
        color: #FFF;
        border-radius: 4px;
        z-index: 10;

        :hover {
            cursor: pointer;
        }

        ::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 0;
            border-radius: 4px;
            z-index: -1;
            background-color: #242C42;
            transition: all 0.5s ease;
        }

        :hover::after {
            width: 100%;
        }
    }

    @media (max-width: 400px) {
        .background {
            width: 320px;
        }

        .card-concurso-img {
            width: 266px;
        }

        .info {
            max-width: 266px;
        }

        button {
            left: 83px;
        }
    }

    @media (max-width: 350px) {
        .background {
            width: 290px;
        }

        .card-concurso-img {
            width: 241px;
        }

        .info {
            max-width: 241px;
        }

        button {
            left: 68px;
        }
    }

`;