import styled from 'styled-components';

export const CarrosselProfessoresStyle = styled.div`

    text-align: center;
    

`;

export const BackgroundCarrossel = styled.div`

    position: relative;
    color: white;
    width: 100%;
    background-image: url(https://www.provasdeti.com.br/assets/images/background-best-staff.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
    
    

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: rgba(10, 15, 28, 0.7);
        
    }

`;

export const Wrapper = styled.div`

    width: 1144px;
    margin: auto;
    padding: 50px 0 20px 0;

    @media (max-width: 1440px) {
        width: 848px;
    }

    @media (max-width: 1150px) {
        width: 565px;
    }

    @media (max-width: 850px) {
        width: 284px;
    }

    @media (max-width: 600px) {
        padding: 50px 0 50px 0;
    }

`;

export const Titles = styled.div`

    position: relative;

`;

export const Title = styled.h2`

    font-size: 30px;
    font-weight: 900;
    text-transform: uppercase;
    z-index: 10000;

`;

export const SubTitle = styled.h3`

    font-size: 18px;
    font-weight: 400;   
    text-transform: uppercase;
    z-index: 10;
    
`;

export const TitleIcon = styled.span`

    .TitleIcon {
        font-size: 22px;
        color: #27921A;
    }

    &:before {
        content: '';
        display: inline-block;
        margin: 0 15px 8px 0;
        height: 2px;
        width: 20px;
        background-color: #27921A;
        
    }

    &:after {
        content: '';
        display: inline-block;
        margin: 0 0 8px 15px;
        height: 2px;
        width: 20px;
        background-color: #27921A;
        
    }

`;

export const Carrossel = styled.div`

    position: relative;
    max-width: 1128px;
    margin: auto;
    overflow: hidden ;

`;

export const Card = styled.div`

    margin: 0 13.5px;

`;

export const PrevNextButton = styled.button`

    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: transparent;
    color: rgba(200, 200, 200, 0.5);
    border: solid 2px rgba(200, 200, 200, 0.5);
    position: absolute;
    transition: all 0.3s ease;

    &:hover {
        color: #FFF;
        border: solid 2px #FFF;
        cursor: pointer;
    }

    &.prev {
        left: 70px;
        top: 380px;
    }

    &.next {
        right: 70px;
        top: 380px;
    }

    @media (max-width: 600px) {
        width: 45px;
        height: 45px;

        &.prev {
            left: 130px;
            top: 660px;
        }

        &.next {
            right: 130px;
            top: 660px;
        }
    }

`;

export const Track = styled.div`

    display: inline-flexbox;
    overflow: hidden;
    transition: transform 1s ease;


`;

export const AnimationController = styled.div`

    background-color: transparent;
    transition: transform 1s ease;

`;