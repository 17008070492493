import styled from 'styled-components';

export const CursosRecentesEmDestaqueStyle = styled.div`

    padding: 50px 0;
    width: 1144px;
    margin: auto;

    .titulo {

        text-align: center;
        color: #49575F;

        p {
            font-size: 16px;
            margin: 0;
        }

        h2 {
            font-size: 30px;
            margin: 25px 0 20px 0;
        }

        span {
            color: #27921A;

            &:before {
                content: '';
                display: inline-block;
                margin: 0 15px 8px 0;
                height: 2px;
                width: 20px;
                background-color: #27921A; 
            }

            &:after {
                content: '';
                display: inline-block;
                margin: 0 0 8px 15px;
                height: 2px;
                width: 20px;
                background-color: #27921A;
                
            }
        }

        .icon {
            margin-bottom: 3px;
            font-size: 16px;
        }
    }

    .active {
        transform: scale(2);
        cursor: grabbing;
        background-color: yellow;
    }

    .wrapper {
        max-width: 100%;
        position: relative;
    }

    .btn {
        background-color: transparent;
        border: none;
        font-size: 20px;
        color: #27921A;
        padding: 10px;
        position: absolute;
        top: 45%;

        &:hover {
            cursor: pointer;
        }
    }

    .prev {
        left: -5%;
    }

    .next {
        right: -5%;
    }

    @media (max-width: 1300px) {
        width: 572px;
    }

    @media (max-width: 650px) {
       width: 425px;

       .prev {
            left: -10%;
        }

        .next {
            right: -10%;
        }
    }

    @media (max-width: 530px) {
       width: 270px;

       .prev {
            left: -12%;
        }

        .next {
            right: -10%;
        }
    }

`;

export const Carrossel = styled.div`

    position: relative;
    max-width: 100%;
    margin: 30px 0;
    overflow: hidden;

    .active {
        transform: scale(2);
        cursor: grabbing;
        background-color: yellow;
    }

    @media (max-width: 650px) {
       margin: 15px 0;
    }

    @media (max-width: 530px) {
       margin: 0;
    }

`;

export const Card = styled.div`

    margin: 0 18px;
    min-width: max-content;

`;

export const Track = styled.div`

    display: inline-flexbox;
    overflow: hidden;
    transition: transform 1s ease;

    .active {
        transform: scale(2);
        cursor: grabbing;
        background-color: yellow;
    }

`;

export const AnimationController = styled.div`

    background-color: transparent;
    transition: transform 1s ease;

`;