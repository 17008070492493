import { InstrucaoNutrorStyle } from './styles/instrucaoNutror.style';

interface InstrucaoNutrorProps {
    expanda: boolean
}

const InstrucaoNutror = (props: InstrucaoNutrorProps) => {
    return (
        <InstrucaoNutrorStyle>
            <img src='/assets/images/nutror-icon.png' alt='ícone Nutror'></img>
            <div className='inst-txt'>
                <h3>Ícone Nutror (Área do Aluno).</h3>
                {props.expanda ? <p className='info-txt'>Expanda o conteúdo ABAIXO. Clique no ícone verde.</p> : <p className='info-txt'>Clique no ícone verde.</p>}
            </div>
        </InstrucaoNutrorStyle>
    )
}

export default InstrucaoNutror;