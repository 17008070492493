import CabecalhoInfo from '../componentes/cabecalhoInfo';
import CabecalhoMenu from '../componentes/cabecalhoMenu';
import Rodape from '../componentes/rodape';
import { Outlet } from 'react-router-dom';
import { GlobalStyle } from './styles/ProfessorPerfil.style';
import IrAoTopoWidget from '../componentes/irAoTopoWidget';
import SeoHelmet from '../componentes/seoHelmet';

const Professores = () => {
    return (
        <>
            <SeoHelmet 
                conector=' - '
                subtitulo='Professor de TI para Concursos'
                linkcanonical='professores'
            />
            <GlobalStyle />
            <CabecalhoInfo />
            <CabecalhoMenu />
            <Outlet />
            <Rodape />
            <IrAoTopoWidget />
        </>
    )
}

export default Professores;